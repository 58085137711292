/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { CgClipboard } from 'react-icons/cg';
import { IoTrashOutline } from 'react-icons/io5';

import CustomDropdown from '../../../../Components/Inputs/CustomDropdown/CustomDropdown';
import TextArea from '../../../../Components/Inputs/TextArea/TextArea';
import { useWindowSize } from '../../../../hooks';
import { getCasePatientRx } from '../../../../services/cases/getCasePatientRx';
import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { addOneReportTreatment } from '../../../../services/oneReport/addOneReportTreatment';
import { deleteOneReportTreatment } from '../../../../services/oneReport/deleteOneReportTreatment';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import { ConvertedTreatment, ITreatments, TreatmentPayload } from '../../../../services/oneReport/types/ITreatments';
import { updateOneReportTreatment } from '../../../../services/oneReport/updateOneReportTreatment';
import { consultationSelectStyles } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import {
  oneReportTreatmentsCategory,
  oneReportTreatmentsToothArea,
  oneReportTreatmentsToothNumber,
  oneReportTreatmentsUrgency,
} from '../../../../Utils/selectOptions';
import { OralHealthReportSubmit, Treatment } from '../../assets/types';
import styles from '../../styles.module.scss';

type PossibleTreatmentNeedsSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  routeCaseId: string;
};

export const convertTreatmentType = (iTreatments: ITreatments[]): Treatment[] => {
  return iTreatments.map((treatment) => ({
    category: {
      label:
        treatment.category !== null ? treatment.category?.charAt(0).toUpperCase() + treatment.category?.slice(1) : '',
      value: treatment.category !== null ? treatment.category : '',
    },
    tooth: {
      label:
        treatment.tooth_number !== null
          ? treatment.tooth_number?.toString().charAt(0).toUpperCase() + treatment.tooth_number?.toString().slice(1)
          : '',
      value: treatment.tooth_number !== null ? treatment.tooth_number?.toString() : '',
    },
    toothArea: {
      label:
        treatment.tooth_area !== null
          ? treatment.tooth_area?.toString().charAt(0).toUpperCase() + treatment.tooth_area?.toString().slice(1)
          : '',
      value: treatment.tooth_area !== null ? treatment.tooth_area?.toString() : '',
    },
    urgency: {
      label: treatment.urgency !== null ? treatment.urgency?.charAt(0).toUpperCase() + treatment.urgency?.slice(1) : '',
      value: treatment.urgency !== null ? treatment.urgency : '',
    },
    description: treatment.description
      ? treatment.description?.charAt(0).toUpperCase() + treatment.description?.slice(1)
      : '',
    id: treatment.id,
  }));
};

const PossibleTreatmentNeedsSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
  routeCaseId,
}: PossibleTreatmentNeedsSectionProps): JSX.Element => {
  const screenSize = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [treatments, setTreatments] = useState<Treatment[]>([]);

  const checkAtLeastOneFieldIsFilled = (treatment: Treatment): boolean => {
    if (treatment) {
      const { category, tooth, toothArea, description, urgency } = treatment;
      if (tooth.value || toothArea.value || category.value || description.trim() !== '' || urgency.value) {
        return true;
      }
    }
    return false;
  };

  const getConvertedTreatments = (res: IOneReport): ConvertedTreatment[] => {
    return convertTreatmentType(res?.treatments ?? []).map((currentTreatment: Treatment) => ({
      ...currentTreatment,
      category: {
        ...currentTreatment.category,
        value: currentTreatment.category.value || '',
      },
      tooth: {
        ...currentTreatment.tooth,
        value: currentTreatment.tooth.value || '',
      },
      toothArea: {
        ...currentTreatment.toothArea,
        value: currentTreatment.toothArea.value || '',
      },
      urgency: {
        ...currentTreatment.urgency,
        value: currentTreatment.urgency.value || '',
      },
      description: currentTreatment.description || '',
    }));
  };

  const addEmptyTreatmentRow = async (): Promise<void> => {
    try {
      const { treatments: res } = await addOneReportTreatment(thisCase?.one_report.id || '', {
        description: '',
      });
      const sortedRes = res.sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity));
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertTreatmentType(sortedRes)],
      });
      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          treatments: [...sortedRes],
        },
      });
    } catch (error) {
      console.error('Error adding new resource:', error);
    }
  };

  const addPrescribe = async (): Promise<void> => {
    try {
      const caseId = parseInt(routeCaseId, 10);
      window.open('', 'formpopup', 'width=1500,height=1000,resizeable,scrollbars');
      const icorePayload = await getCasePatientRx(caseId);
      const loginform = document.getElementById('icoreForm');
      const loginInput = document.getElementById('icoreFormData');
      if (loginform && loginInput) {
        (loginform as HTMLFormElement).target = 'formpopup';
        (loginInput as HTMLInputElement).value = JSON.stringify(icorePayload);
        (loginform as HTMLFormElement).submit();
      }
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const addTreatment = async (nonEmptyPayload: TreatmentPayload): Promise<void> => {
    try {
      const res = await addOneReportTreatment(thisCase?.one_report.id || '', nonEmptyPayload);
      const convertedTreatments: ConvertedTreatment[] = getConvertedTreatments(res);
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertedTreatments],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const updateTreatment = async (id: string, nonEmptyPayload: TreatmentPayload): Promise<void> => {
    try {
      const res = await updateOneReportTreatment(thisCase?.one_report.id || '', {
        ...nonEmptyPayload,
        id,
      });
      const convertedTreatments: ConvertedTreatment[] = getConvertedTreatments(res);
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertedTreatments],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const deleteTreatment = async (index: number, id?: string): Promise<void> => {
    try {
      if (id) {
        const res = await deleteOneReportTreatment(thisCase?.one_report.id || '', id);
        const sortedRes = res.treatments.sort(
          (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
        );
        if (res.treatments.length === 0) {
          setValue(`treatments`, []);
          trigger('treatments');
        }
        setConsultationForm({
          ...consultationForm,
          treatments: [...convertTreatmentType(sortedRes)],
        });
      } else {
        setConsultationForm({
          ...consultationForm,
          treatments: consultationForm.treatments.filter((treatment, i) => i !== index),
        });
      }
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handleTreatmentOnBlur = async (treatment: Treatment): Promise<void> => {
    const { id } = treatment;
    const nonEmptyPayload = {
      ...(treatment.category.value && { category: treatment.category.value }),
      ...(treatment.tooth.value && { tooth_number: treatment.tooth.value }),
      ...(treatment.toothArea.value && { tooth_area: treatment.toothArea.value }),
      ...(treatment.description ? { description: treatment.description } : { description: '' }),
      ...(treatment.urgency.value && { urgency: treatment.urgency.value }),
    };

    if (id !== undefined && checkAtLeastOneFieldIsFilled(treatment)) {
      await updateTreatment(id, nonEmptyPayload);
    } else if (id === undefined && checkAtLeastOneFieldIsFilled(treatment)) {
      await addTreatment(nonEmptyPayload);
    }
  };

  useEffect(() => {
    const sortedTreatments = consultationForm.treatments.sort(
      (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
    );
    const filteredTreatments = sortedTreatments.filter((treatment) => {
      if (
        treatment.tooth.value ||
        treatment.toothArea.value ||
        treatment.category.value ||
        treatment.description.trim() !== ''
      ) {
        return true;
      }
      return false;
    });
    if (checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit') {
      setTreatments(filteredTreatments);
    } else {
      setTreatments(consultationForm.treatments);
    }
  }, [consultationForm.treatments]);

  return (
    <>
      {treatments.map((treatment, index) => (
        <div className={styles.descriptionInputs} style={{ marginTop: index !== 0 ? '2rem' : '' }} key={index}>
          <div className={styles.selectInput}>
            <div className={styles.title} style={{ display: index !== 0 && screenSize.width >= 992 ? 'none' : '' }}>
              Category
            </div>
            <div className={styles.input}>
              <CustomDropdown
                options={oneReportTreatmentsCategory}
                value={treatment?.category !== null ? treatment.category : { label: '', value: '' }}
                placeholder=""
                menuPortalTarget={document.body}
                menuPlacement="auto"
                onChange={(value: any) => {
                  setConsultationForm({
                    ...consultationForm,
                    treatments: [
                      ...consultationForm.treatments.slice(0, index),
                      {
                        ...consultationForm.treatments[index],
                        category: value,
                      },
                      ...consultationForm.treatments.slice(index + 1),
                    ],
                  });
                  handleTreatmentOnBlur({
                    ...treatment,
                    category: value,
                  });
                  setValue(`treatments.${index}.category`, value!);
                  if (errors.treatments) trigger('treatments');
                }}
                isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              />
            </div>
          </div>
          <div className={styles.selectInput}>
            <div className={styles.title} style={{ display: index !== 0 && screenSize.width >= 992 ? 'none' : '' }}>
              Tooth #
            </div>
            <div className={styles.input}>
              <CustomDropdown
                {...register(`treatments.${index}.tooth`)}
                styles={consultationSelectStyles}
                placeholder=""
                menuPortalTarget={document.body}
                menuPlacement="auto"
                options={oneReportTreatmentsToothNumber}
                closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                value={treatment?.tooth ?? { label: '', value: '' }}
                onChange={(value: any) => {
                  setConsultationForm({
                    ...consultationForm,
                    treatments: [
                      ...consultationForm.treatments.slice(0, index),
                      {
                        ...consultationForm.treatments[index],
                        tooth: value,
                      },
                      ...consultationForm.treatments.slice(index + 1),
                    ],
                  });
                  handleTreatmentOnBlur({
                    ...treatment,
                    tooth: value,
                  });
                  setValue(`treatments.${index}.tooth`, value!);
                  if (errors.treatments) trigger('treatments');
                }}
                isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              />
            </div>
          </div>
          <div className={styles.selectInput}>
            <div className={styles.title} style={{ display: index !== 0 && screenSize.width >= 992 ? 'none' : '' }}>
              Area
            </div>
            <div className={styles.input}>
              <CustomDropdown
                {...register(`treatments.${index}.toothArea`)}
                styles={consultationSelectStyles}
                placeholder=""
                menuPortalTarget={document.body}
                menuPlacement="auto"
                options={oneReportTreatmentsToothArea}
                closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                value={treatment?.toothArea ?? { label: '', value: '' }}
                onChange={(value: any) => {
                  setConsultationForm({
                    ...consultationForm,
                    treatments: [
                      ...consultationForm.treatments.slice(0, index),
                      {
                        ...consultationForm.treatments[index],
                        toothArea: value,
                      },
                      ...consultationForm.treatments.slice(index + 1),
                    ],
                  });
                  handleTreatmentOnBlur({
                    ...treatment,
                    toothArea: value,
                  });
                  setValue(`treatments.${index}.toothArea`, value!);
                  if (errors.treatments) trigger('treatments');
                }}
                isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              />
            </div>
          </div>
          <div className={styles.selectInput}>
            <div className={styles.title} style={{ display: index !== 0 && screenSize.width >= 992 ? 'none' : '' }}>
              Urgency
            </div>
            <div className={styles.input}>
              <CustomDropdown
                {...register(`treatments.${index}.urgency`)}
                styles={consultationSelectStyles}
                placeholder=""
                menuPortalTarget={document.body}
                menuPlacement="auto"
                options={oneReportTreatmentsUrgency}
                closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                value={treatment?.urgency ?? { label: '', value: '' }}
                onChange={(value: any) => {
                  setConsultationForm({
                    ...consultationForm,
                    treatments: [
                      ...consultationForm.treatments.slice(0, index),
                      {
                        ...consultationForm.treatments[index],
                        urgency: value,
                      },
                      ...consultationForm.treatments.slice(index + 1),
                    ],
                  });
                  handleTreatmentOnBlur({
                    ...treatment,
                    urgency: value,
                  });
                  setValue(`treatments.${index}.urgency`, value!);
                  if (errors.treatments) trigger('treatments');
                }}
                isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
              />
            </div>
          </div>
          <div className={styles.descriptionInput}>
            <div className={styles.title} style={{ display: index !== 0 && screenSize.width >= 992 ? 'none' : '' }}>
              Description
            </div>
            <TextArea
              {...register(`treatments.${index}.description`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
                minHeight: '30px',
                height: 'auto',
              }}
              inputClass={styles.input}
              placeholder=""
              isUnderlined={false}
              defaultValue={treatment?.description ?? ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setConsultationForm({
                  ...consultationForm,
                  treatments: [
                    ...consultationForm.treatments.slice(0, index),
                    {
                      ...consultationForm.treatments[index],
                      description: e.target.value,
                    },
                    ...consultationForm.treatments.slice(index + 1),
                  ],
                });
                setValue(`treatments.${index}.description`, e.target.value);
                if (errors.treatments) trigger('treatments');
              }}
              onBlur={(e: any) =>
                handleTreatmentOnBlur({
                  ...treatment,
                  description: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
          {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
            <div
              className={styles.deleteDescription}
              onClick={() => deleteTreatment(index, treatment.id)}
              style={{ marginTop: index !== 0 && screenSize.width >= 992 ? '0' : '' }}
            >
              <IoTrashOutline className="w-100 h-100" />
            </div>
          )}
        </div>
      ))}
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
        <div className={styles.addTreatment}>
          <div className={styles.addTreatmentContent} onClick={addEmptyTreatmentRow}>
            <span>Add Another Treatment</span>
            <span className={styles.addTreatmentIcon}>
              <AiFillPlusCircle />
            </span>
          </div>
        </div>
      )}
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'E-Prescribe') === 'edit' && (
        <>
          <hr className={styles.ohrHr} />
          <div className={styles.addPrescribe}>
            <div className={styles.addPrescribeContent} onClick={addPrescribe}>
              <span className={styles.addPrescribeIcon}>
                <CgClipboard />
              </span>
              <span>Add E-Prescribe</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PossibleTreatmentNeedsSection);
