/* eslint-disable no-useless-catch */
import { config } from '@brands/config/config';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import axios from 'axios';
import { UseFormGetValues } from 'react-hook-form';

import { displayErrorDetails } from './displayError';

export interface ValidationModalInterface {
  isOpen: boolean;
  title: string;
  whatYouEntered: string;
  recommended: boolean;
  recommendedAddress: string;
  onClose: (() => void) | undefined;
  onConfirmChange: (() => void) | undefined;
  onKeepCurrentAddress: (() => void) | undefined;
}

export function removeDuplicateWords(input: string): string {
  const words = input.split(' ');
  const uniqueWords = words.filter((word, index, array) => array.indexOf(word) === index);
  return uniqueWords.join(' ');
}

export async function validateAddressApiCall(addressPayload: any): Promise<any> {
  try {
    const response = await axios.post(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${config.api.googleAPI}`,
      addressPayload,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function handleValidationResult(
  validationResult: any,
  enteredAddress: string,
  callSaveProfile: (profileData: UnifiedProfileSubmit) => Promise<void>,
  setShowValidationAddressModal: (value: React.SetStateAction<ValidationModalInterface>) => void,
  onCloseConfirmationModal: () => void,
  updateCurrentAddress: (standardizedAddress: any, postalAddress: any, addressComponents: any) => void,
  profileData: UnifiedProfileSubmit,
): void {
  const unconfirmedKnownArray = [];
  const unconfirmedUnKnownArray = [];

  for (const component of validationResult.address.addressComponents || []) {
    if (component.spellCorrected || component.replaced || component.unexpected) {
      let arrayKey;

      if (component.spellCorrected) {
        arrayKey = 'Spell Correct';
      } else if (component.replaced) {
        arrayKey = 'Replace';
      } else if (component.unexpected) {
        arrayKey = 'Unexpected';
      }
      unconfirmedKnownArray.push({ [arrayKey as string]: component.componentType });
    } else if (component.confirmationLevel !== 'CONFIRMED') {
      unconfirmedUnKnownArray.push(component.componentType);
    }
  }

  if (
    (validationResult.verdict.validationGranularity === 'PREMISE' ||
      validationResult.verdict.validationGranularity === 'SUB_PREMISE') &&
    !validationResult.address.addressComponents?.some(
      (component: any) =>
        component.confirmationLevel !== 'CONFIRMED' ||
        component?.spellCorrected ||
        component?.replaced ||
        component?.unexpected,
    )
  ) {
    callSaveProfile(profileData);
  } else if (
    (validationResult.verdict.validationGranularity === 'PREMISE' ||
      validationResult.verdict.validationGranularity === 'SUB_PREMISE') &&
    validationResult.address.addressComponents?.some(
      (component: any) =>
        component.confirmationLevel !== 'CONFIRMED' ||
        component?.spellCorrected ||
        component?.replaced ||
        component?.unexpected,
    ) &&
    validationResult.address.unconfirmedComponentTypes === undefined
  ) {
    setShowValidationAddressModal({
      isOpen: true,
      title: 'Confirm your delivery address',
      whatYouEntered: enteredAddress,
      recommended: true,
      recommendedAddress: validationResult.address.formattedAddress,
      onClose: onCloseConfirmationModal,
      onConfirmChange: async () => {
        updateCurrentAddress(
          validationResult.uspsData.standardizedAddress,
          validationResult.address.postalAddress,
          validationResult.address.addressComponents,
        );
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
      onKeepCurrentAddress: async () => {
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
    });
  } else if (
    (validationResult.verdict.validationGranularity === 'PREMISE' ||
      validationResult.verdict.validationGranularity === 'SUB_PREMISE') &&
    validationResult.verdict?.hasReplacedComponents
  ) {
    setShowValidationAddressModal({
      isOpen: true,
      title: 'Confirm your delivery address',
      whatYouEntered: enteredAddress,
      recommended: true,
      recommendedAddress: validationResult.address.formattedAddress,
      onClose: onCloseConfirmationModal,
      onConfirmChange: async () => {
        updateCurrentAddress(
          validationResult.uspsData.standardizedAddress,
          validationResult.address.postalAddress,
          validationResult.address.addressComponents,
        );
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
      onKeepCurrentAddress: async () => {
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
    });
  } else if (
    (validationResult.verdict.validationGranularity === 'PREMISE' ||
      validationResult.verdict.validationGranularity === 'SUB_PREMISE') &&
    validationResult.verdict?.hasUnconfirmedComponents
  ) {
    setShowValidationAddressModal({
      isOpen: true,
      title: "We couldn't verify your address",
      whatYouEntered: enteredAddress,
      recommended: unconfirmedKnownArray.length > 0 ?? true,
      recommendedAddress: validationResult.address.formattedAddress,
      onClose: onCloseConfirmationModal,
      onConfirmChange: async () => {
        onCloseConfirmationModal();
        updateCurrentAddress(
          validationResult.uspsData.standardizedAddress,
          validationResult.address.postalAddress,
          validationResult.address.addressComponents,
        );
        await callSaveProfile(profileData);
      },
      onKeepCurrentAddress: async () => {
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
    });
  } else if (
    validationResult.verdict.validationGranularity !== 'PREMISE' ||
    validationResult.verdict.validationGranularity !== 'SUB_PREMISE'
  ) {
    setShowValidationAddressModal({
      isOpen: true,
      title: "We couldn't verify your address",
      whatYouEntered: enteredAddress,
      recommended: false,
      recommendedAddress: '',
      onClose: onCloseConfirmationModal,
      onConfirmChange: undefined,
      onKeepCurrentAddress: async () => {
        onCloseConfirmationModal();
        await callSaveProfile(profileData);
      },
    });
  }
}

export const validateAddress = async (
  profileData: UnifiedProfileSubmit,
  getValues: UseFormGetValues<UnifiedProfileSubmit>,
  validationResponseId: string,
  callSaveProfile: (profileData: UnifiedProfileSubmit) => Promise<void>,
  setValidationResponseId: (value: React.SetStateAction<string>) => void,
  setShowValidationAddressModal: (value: React.SetStateAction<ValidationModalInterface>) => void,
  onCloseConfirmationModal: () => void,
  updateCurrentAddress: (standardizedAddress: any, postalAddress: any, addressComponents: any) => void,
): Promise<void> => {
  const address2 = getValues('address2');
  const enteredAddress = `${getValues('address1')}${address2 ? `, ${address2}` : ''}, ${getValues('city')}, ${getValues(
    'state',
  )}, ${getValues('zip_code')}`;
  const payloadAddress = `${getValues('address1')}}, ${getValues('city')}, ${getValues('state')}, ${getValues(
    'zip_code',
  )}`;

  try {
    const addressPayload = {
      address: {
        postalCode: removeDuplicateWords(getValues('zip_code')!),
        administrativeArea: removeDuplicateWords(getValues('state')!),
        regionCode: 'US',
        languageCode: 'en',
        locality: removeDuplicateWords(getValues('city')),
        addressLines: [payloadAddress],
      },
      ...(validationResponseId !== '' && { previousResponseId: validationResponseId }),
      enableUspsCass: true,
    };

    const validationResult = await validateAddressApiCall(addressPayload);

    setValidationResponseId(validationResult.responseId);

    await handleValidationResult(
      validationResult.result,
      enteredAddress,
      callSaveProfile,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
      profileData,
    );
  } catch (error: unknown) {
    displayErrorDetails(error);
  }
};
