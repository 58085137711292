/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { DEFAULT_PATIENT_LOCATION_STATE } from '@brands/Utils/localStorage';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SelectedAppointment } from '../../Dashboard/PatientForms/PatientForm/utils/types';
import { useAppSelector } from '../../hooks/useReduxHook';
import { listAvailableProvidersByDate } from '../../services/identity/listAvailableProvidersByDate';
import { Loading } from '../LoadingSpinner/Loading';
import styles from './styles.module.scss';

interface CalendarProps {
  selectedDate?: Date | null;
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedDates?: Date[] | null;
  setSelectedDates?: React.Dispatch<React.SetStateAction<Date[] | null>>;
  isProvider?: boolean;
  setSelectedTimeSlot?: React.Dispatch<React.SetStateAction<SelectedAppointment>> | undefined;
  isCareAdvisorView?: boolean;
  providerID?: number | undefined;
  languageFilter?: string[] | undefined;
  insuranseFilter?: number | undefined;
  caseId?: number | undefined;
}

const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  setSelectedDate,
  selectedDates,
  setSelectedDates,
  isProvider,
  setSelectedTimeSlot,
  isCareAdvisorView,
  providerID,
  languageFilter,
  insuranseFilter,
  caseId,
}) => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [currentMonth, setCurrentMonth] = useState<Date>(selectedDates?.length === 1 ? selectedDates[0] : new Date());
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const [datesWithSlots, setDatesWithSlots] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatient);
  const handlePrevMonth = (): void => {
    setCurrentMonth((prevMonth) => {
      const prevMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1);
      return prevMonthDate;
    });
    if (setSelectedTimeSlot)
      setSelectedTimeSlot({
        selectedTimeSlot: '',
        selectedProviderId: undefined,
      });
  };

  const handleNextMonth = (): void => {
    setCurrentMonth((prevMonth) => {
      const nextMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1);
      return nextMonthDate;
    });
    if (setSelectedTimeSlot)
      setSelectedTimeSlot({
        selectedTimeSlot: '',
        selectedProviderId: undefined,
      });
  };

  const handleDateClick = (date: Date): void => {
    if (setSelectedDate) {
      setSelectedDate(date);
      if (setSelectedTimeSlot)
        setSelectedTimeSlot({
          selectedTimeSlot: '',
          selectedProviderId: undefined,
        });
    }
  };

  const handleMultipleDateClick = (date: Date): void => {
    if (setSelectedDates) {
      if (selectedDates && selectedDates.length > 0) {
        const newSelectedDates = [...selectedDates];
        const dateIndex = newSelectedDates.findIndex((x) => x.toDateString() === date.toDateString());
        if (dateIndex !== -1) {
          newSelectedDates.splice(dateIndex, 1);
        } else {
          newSelectedDates.push(date);
        }
        setSelectedDates(newSelectedDates);
      } else {
        setSelectedDates([date]);
      }
    }
  };

  const generateCalendarDays = (): Date[] => {
    const days: Date[] = [];
    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const lastDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);

    const startDay = new Date(firstDayOfMonth);
    startDay.setDate(startDay.getDate() - startDay.getDay());

    const endDay = new Date(lastDayOfMonth);
    endDay.setDate(endDay.getDate() + (6 - endDay.getDay()));

    const currentDay = new Date(startDay);

    while (currentDay <= endDay) {
      days.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }
    return days;
  };

  const calendarDays = generateCalendarDays();

  const RescheduleCase = state?.RescheduleCase ? state.RescheduleCase : searchParams.get('reschedule') || null;
  const patient_current_state = state?.patient_current_state
    ? state?.patient_current_state
    : searchParams.get('case_state') || null;

  const fetchAvailableSlotsForDate = async (firstCalendarDay: Date): Promise<string[]> => {
    const response = await listAvailableProvidersByDate({
      start: moment(firstCalendarDay).format('YYYY-MM-DD'),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      period: calendarDays.length,
      case_id: caseId,
      ...(!isCareAdvisorView && { synchronous: true }),
      ...(!isCareAdvisorView && {
        license_states: [
          RescheduleCase && patient_current_state
            ? patient_current_state
            : userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentState[selectedPatient.id] || ''
            : currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
        ],
      }),
      ...(isCareAdvisorView && { provider_id: providerID }),
      ...(!isCareAdvisorView &&
        languageFilter && {
          language: languageFilter,
        }),
      ...(!isCareAdvisorView &&
        insuranseFilter && {
          carrier_id: insuranseFilter,
        }),
      // synchronous: caseType === 'video_call_scheduled' || caseType === 'video_call_instant',
      // asynchronous: caseType === 'one_report' || caseType === 'second_opinion',
    });
    // Assuming you want to merge the `slot_times` from all providers into a single array
    const daysWithSlots = response.nodes;

    return daysWithSlots;
  };

  const memoizedFetchAvailableSlotsForDate = useMemo(
    () => fetchAvailableSlotsForDate,
    [currentState[userInfo.id || selectedPatient.id], languageFilter, insuranseFilter, calendarDays.length],
  );

  const convertDateFormat = (inputDateString: Date): string => {
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const outputDateString = `${year}-${month}-${day}`;
    return outputDateString;
  };

  const fetchAvailableProviders = (firstCalendarDay: Date): void => {
    memoizedFetchAvailableSlotsForDate(firstCalendarDay).then((response) => {
      setDatesWithSlots(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!isProvider) {
      setLoading(true);
      fetchAvailableProviders(calendarDays[0]);
    }
  }, [currentMonth, languageFilter, insuranseFilter]);

  return (
    <div
      className={`${styles.calendar} ${
        isCareAdvisorView ? styles.calendarProviderProfileCard : styles.normalProviderProfileCard
      }`}
      style={{ width: isProvider ? 'unset' : '', height: isProvider ? '100%' : '' }}
    >
      <div className={styles.calendarHeader} style={{ fontSize: isProvider ? '16px' : '' }}>
        <button type="button" className={styles.arrow} onClick={handlePrevMonth}>
          &lt;
        </button>
        <div className={styles.month}>
          {currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}
        </div>
        <button type="button" className={styles.arrow} onClick={handleNextMonth}>
          &gt;
        </button>
      </div>
      {/* Days of the week header */}
      <div className={`${styles.daysHeader} ${!isProvider ? styles.patientDaysHeader : ''}`}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((dayName) => (
          <div key={dayName} className={styles.dayNameHeader}>
            {dayName}
          </div>
        ))}
      </div>
      {!loading ? (
        <div
          className={styles.calendarGrid}
          style={{ width: isProvider ? 'unset' : '', height: isProvider ? 'unset' : '' }}
        >
          {calendarDays.map((day, index) => {
            const isPastDay = day.getTime() < new Date().setHours(0, 0, 0, 0);
            const isDayDisabled = !isProvider ? !datesWithSlots.some((date) => date === convertDateFormat(day)) : false;
            const isSelectedDay = !isProvider
              ? selectedDate &&
                typeof selectedDate === 'object' &&
                day.toDateString() === (selectedDate as Date).toDateString()
              : selectedDates &&
                selectedDates.length > 0 &&
                selectedDates.findIndex((x) => x.toDateString() === day.toDateString()) !== -1;

            return (
              <div
                key={index}
                className={`${styles.day} ${isPastDay || isDayDisabled ? styles.pastDay : ''} ${
                  isSelectedDay ? styles.selected : ''
                }`}
                style={{
                  width: isProvider ? '36px' : '',
                  height: isProvider ? '36px' : '',
                  margin: isProvider ? '10px' : '',
                }}
                onClick={() =>
                  !isPastDay && !isDayDisabled ? (isProvider ? handleMultipleDateClick(day) : handleDateClick(day)) : ''
                }
              >
                <div
                  className={`${styles.dayNumber}`}
                  style={{ fontWeight: isProvider ? '400' : '', fontSize: isProvider ? '12px' : '' }}
                >
                  {day.getDate()}
                </div>
                {day.toDateString() === new Date().toDateString() && (
                  <div className={`${styles.dot} ${styles['dot-current-day']}`} />
                )}
                {/* {day.getDate() === 5 && <div className={`${styles.dot} ${styles['dot-special-day']}`} />}
                {day.getDate() === 10 && <div className={`${styles.dot} ${styles['dot-special-day']}`} />} */}
              </div>
            );
          })}
        </div>
      ) : (
        <Loading dotted />
      )}
    </div>
  );
};

Calendar.defaultProps = {
  selectedDate: null,
  setSelectedDate: () => {},
  selectedDates: null,
  setSelectedDates: () => {},
  isProvider: false,
  setSelectedTimeSlot: undefined,
  isCareAdvisorView: false,
  providerID: undefined,
  languageFilter: undefined,
  insuranseFilter: undefined,
  caseId: undefined,
};

export default Calendar;
