/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import QuestionAndDropdown from '@brands/Components/QuestionAndAnswers/QuestionAndDropdown/QuestionAndDropdown';
import QuestionAndMultiselectSideQuestion from '@brands/Components/QuestionAndAnswers/QuestionAndMultiselectSideQuestion/QuestionAndMultiselectSideQuestion';
import QuestionAndPhoneInput from '@brands/Components/QuestionAndAnswers/QuestionAndPhoneInput/QuestionAndPhoneInput';
import QuestionAndTextField from '@brands/Components/QuestionAndAnswers/QuestionAndTextField/QuestionAndTextField';
import { OptionCdts } from '@brands/Dashboard/ConsultationPage/assets/types';
import { getUserById } from '@brands/services/identity/getUserById';
import { UserProfile, UserRoleName } from '@brands/services/identity/types/UserProfile';
import { setUserTag } from '@brands/services/tags/setUserTag';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { FormValues } from '@brands/store/slices/formAnswersSlice';
import { addFormItem } from '@brands/store/slices/formQuestionsSlice';
import { setExternalForms } from '@brands/store/slices/patientFormSlice';
import { checkExternalFormAnswers } from '@brands/Utils/checkExternalForms';
import { stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import QuestionAndMultiselect from '../../../Components/QuestionAndAnswers/QuestionAndMultiselectForMed/QuestionAndMultiselect';
import QuestionAndRadioButton from '../../../Components/QuestionAndAnswers/QuestionAndRadioButtonForMed/QuestionAndRadioButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { createFormAnswer } from '../../../services/forms/createFormAnswer';
import { getForm } from '../../../services/forms/getForm';
import { FormAnswerValue, getFormAnswer } from '../../../services/forms/getFormAnswer';
import { listForms } from '../../../services/forms/listForms';
import { FormQuestion } from '../../../services/forms/types/FormQuestion';
import { updateFormAnswer } from '../../../services/forms/updateFormAnswer';
import { listAllTags } from '../../../services/tags/listAllTags';
import { AddTagPayload, setTagsToMe } from '../../../services/tags/setTagsToMe';
import { displayErrorDetails } from '../../../Utils/displayError';
import styles from '../styles.module.scss';
import { MedicalHistoryProps, MedicalHistorySubmit } from './utils/types';
import { validationSchema } from './utils/validationSchema';

interface Option {
  id: number;
  description: string;
  patient_owned: boolean;
  name: string;
}

const heightOptions = [
  { value: '1ft', label: '1ft' },
  { value: '2ft', label: '2ft' },
  { value: '3ft', label: '3ft' },
  { value: '4ft', label: '4ft' },
  { value: '5ft', label: '5ft' },
  { value: '6ft', label: '6ft' },
  { value: '7ft', label: '7ft' },
  { value: '8ft', label: '8ft' },
];

const inchOptions = [
  { value: '1in', label: '1in' },
  { value: '2in', label: '2in' },
  { value: '3in', label: '3in' },
  { value: '4in', label: '4in' },
  { value: '5in', label: '5in' },
  { value: '6in', label: '6in' },
  { value: '7in', label: '7in' },
  { value: '8in', label: '8in' },
  { value: '9in', label: '9in' },
  { value: '10in', label: '10in' },
  { value: '11in', label: '11in' },
  { value: '12in', label: '12in' },
];

const MedicalHistory = ({ isProfileCard }: MedicalHistoryProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedPatient, values } = useAppSelector(selectPatientForm);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const { selectedPatient: currentPatient } = useAppSelector(selectPatient);
  const [questions, setQuestions] = React.useState<FormQuestion[]>();
  const [parentQuestionId, setParentQuestionId] = useState<number>();
  const [rendered, setRendered] = React.useState(true);
  const [formAnswerId, setFormAnswerId] = useState<number | null>(null);
  const [defaultValues, setDefaultValues] = useState<MedicalHistorySubmit>({});
  const [parentQuestion, setParentQuestion] = useState(false);
  const [medicalHistoryForm, setMedicalHistoryForm] = useState<MedicalHistorySubmit>({
    ...defaultValues,
  });
  const poundOptions: OptionCdts[] = [];

  for (let i = 2; i <= 350; i++) {
    poundOptions.push({
      value: `${i}lb`,
      label: `${i}lb`,
    });
  }
  const [formId, setFormId] = useState(0);
  const [tagsList, setTagsList] = useState<Option[]>([]);

  const [guardianPatient, setGuardianPatient] = useState<UserProfile>();
  useEffect(() => {
    const getGuardianPAtient = async (): Promise<void> => {
      if (
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin
      ) {
        if (currentPatient.guardian_id !== null) {
          const guardianResponse = await getUserById(currentPatient?.guardian_id as number);
          setGuardianPatient(guardianResponse);
        }
      }
    };
    getGuardianPAtient();
  }, [currentPatient]);

  const setValuesPayload = (data: MedicalHistorySubmit): FormValues[] => {
    return [
      ...Object.keys(data).map((value) => {
        const questionId = Number(value.replace('question_', ''));
        return {
          form_question_id: questionId,
          value: { data: data[value as keyof MedicalHistorySubmit] },
        };
      }),
    ];
  };

  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      const response = await listAllTags();
      setTagsList(response);
    };
    fetchOptions();
  }, []);

  const createQuestionTagMap = (givenQuestions: FormQuestion[]): { [key: string]: number } => {
    const map: { [key: string]: number } = {};
    for (const question of givenQuestions) {
      const matchingTag = tagsList.find((tag) => tag.description === question.title);
      if (matchingTag) {
        map[`question_${question.id}`] = matchingTag.id;
      }
    }
    return map;
  };

  const updateTags = async (questionsAnswers: MedicalHistorySubmit): Promise<void> => {
    for (const question in questionsAnswers) {
      if (questionsAnswers[question as keyof MedicalHistorySubmit]) {
        const tagId = createQuestionTagMap(questions!)[question];
        const payload: AddTagPayload = {
          answer: questionsAnswers[question as keyof MedicalHistorySubmit] !== 'no',
          note: questionsAnswers[question as keyof MedicalHistorySubmit],
        };
        try {
          if (tagId)
            if (
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
            ) {
              await setUserTag(selectedPatient?.value || state.patientId, tagId, payload);
            } else {
              await setTagsToMe(
                tagId,
                payload,
                Number(selectedPatient?.value) !== Number(userInfo.id) ? Number(selectedPatient?.value) : undefined,
              );
            }
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      }
    }
  };

  const checkExternalForms = async (): Promise<void> => {
    const [checkProfile, checkMedicalHistory, checkMedicalConditionsFormAnswer, checkDentalHistoryFormAnswer] =
      await checkExternalFormAnswers(
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? guardianPatient ?? (currentPatient as UserProfile)
          : userInfo,
        selectedPatient?.value,
        userInfo.role.name === UserRoleName.Patient && currentPatient.role.name === UserRoleName.Dependent
          ? currentPatient
          : currentPatient?.guardian_id !== null
          ? currentPatient
          : null,
      );
    const formsToSet = [];

    if (!checkProfile) {
      formsToSet.push('patient_profile');
    }
    if (!checkMedicalHistory) {
      formsToSet.push('patient_medical_history');
    }
    if (!checkMedicalConditionsFormAnswer) {
      formsToSet.push('patient_medical_conditions');
    }
    if (!checkDentalHistoryFormAnswer) {
      formsToSet.push('patient_dental_history');
    }

    dispatch(setExternalForms(formsToSet));
  };

  const onSubmit = async (data: MedicalHistorySubmit): Promise<void> => {
    setRendered(false);
    updateTags(data);
    if (!formAnswerId) {
      await createFormAnswer({
        form_id: formId,
        user_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (guardianPatient as UserProfile).id
              : (currentPatient as UserProfile).id
            : Number(userInfo.id),
        values: [...setValuesPayload(data)],
        dependent_id:
          userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
            ? currentPatient.guardian_id !== null
              ? (currentPatient as UserProfile).id
              : undefined
            : Number(selectedPatient?.value) !== Number(userInfo.id)
            ? Number(selectedPatient?.value)
            : undefined,
      });
    } else {
      await updateFormAnswer(
        formAnswerId,
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (guardianPatient as UserProfile).id
            : (currentPatient as UserProfile).id
          : Number(userInfo.id),
        {
          values: [...setValuesPayload(data)],
        },
        userInfo.role.name === UserRoleName.Admin ||
          userInfo.role.name === UserRoleName.SuperAdmin ||
          userInfo.role.name === UserRoleName.OrganizationAdmin
          ? currentPatient.guardian_id !== null
            ? (currentPatient as UserProfile).id
            : undefined
          : Number(selectedPatient?.value) !== Number(userInfo.id)
          ? Number(selectedPatient?.value)
          : undefined,
      );
    }
    await checkExternalForms();
    setRendered(true);
    navigate('/medical-information-summary', {
      state: {
        fromPatientIntake: state?.fromPatientIntake ?? false,
        caseType: state ? (values.find((item) => item.form_question_id === 20)?.value.caseType as string) : '',
        isPatientForm: state?.isPatientForm,
        shouldSeeLastModal: state?.shouldSeeLastModal,
        patientId: state?.patientId,
      },
    });
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const { register, handleSubmit, setValue, getValues } = useForm<MedicalHistorySubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema(questions, true, false, false)),
  });

  const getFormAnswers = useCallback(
    async (formIds: number, defaultVal: MedicalHistorySubmit) => {
      let formAnsId: number | null = null;
      const temporaryValues = { ...defaultVal };
      const formAnswers = await getFormAnswer({
        form_id: formIds,
        created_by_id: Number(selectedPatient?.value),
      });

      Object.values(formAnswers.form_answers)
        .sort((a, b) => a.id - b.id)
        .every((answer) => {
          let check = true;
          Object.values(answer.values)
            .sort((a, b) => a.form_question_id - b.form_question_id)
            .forEach((value: FormAnswerValue) => {
              if (Object.keys(temporaryValues).includes(`question_${value.form_question_id}`)) {
                temporaryValues[`question_${value.form_question_id}`] = value.value.data;
                check = false;
                formAnsId = answer.id;
              }
            });
          return check;
        });
      return { formAnswerIdentity: formAnsId, formObj: temporaryValues };
    },
    [defaultValues, dispatch, formId, selectedPatient?.value],
  );

  useEffect(() => {
    (async () => {
      setRendered(false);
      let defaultFormValues = {};
      const { forms: formList } = await listForms();
      const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
      setFormId(newFormId);
      await getForm(newFormId.toString()).then((res) => {
        if (res) {
          defaultFormValues = res.steps[0].questions.reduce((acc: MedicalHistorySubmit, question: FormQuestion) => {
            return {
              ...acc,
              [`question_${question.id}`]: 'no',
            };
          }, {});
          setDefaultValues(defaultFormValues);
          dispatch(
            addFormItem({
              form_id: res.id,
              step_id: res.steps[0].id,
              questions: res.steps[0].questions,
            }),
          );
        }
        setQuestions(res?.steps[0].questions);
        setParentQuestionId(
          res?.steps[0].questions.find((answerForm) => answerForm.title === 'Do you use? (Select all that apply)')?.id,
        );
      });
      const { formAnswerIdentity, formObj } = await getFormAnswers(newFormId, defaultFormValues);
      setFormAnswerId(formAnswerIdentity);
      setMedicalHistoryForm(formObj as MedicalHistorySubmit);
      setRendered(true);
    })();
  }, [selectedPatient?.value]);

  useEffect(() => {
    Object.keys(medicalHistoryForm).forEach((k: unknown) =>
      setValue(k as string, medicalHistoryForm[k as keyof MedicalHistorySubmit], {
        shouldValidate: true,
      }),
    );
  }, [medicalHistoryForm]);

  useEffect(() => {
    if (questions) {
      setParentQuestion(
        (medicalHistoryForm[`question_${parentQuestionId}`]?.length > 0 &&
          medicalHistoryForm[`question_${parentQuestionId}`] !== 'no') ||
          false,
      );
      const getChildQuestionId = questions.find(
        (answerForm) => answerForm.title === 'If yes, are you interested in stopping?',
      )?.id;

      if (getChildQuestionId && getValues(`question_${getChildQuestionId - 1}`)?.length === 0) {
        setParentQuestion(false);
        setValue(`question_${getChildQuestionId}`, '');
      }
    }
  }, [medicalHistoryForm[`question_${parentQuestionId}`]]);

  useEffect(() => {
    if (!parentQuestion && parentQuestionId) {
      setMedicalHistoryForm((prev: any) => ({
        ...prev,
        [`question_${parentQuestionId + 1}`]: '',
      }));
    }
  }, [parentQuestion]);

  if (!rendered || !questions || questions.length === 0) {
    return <Loading fullScreen />;
  }
  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2', paddingTop: '40px' }}
    >
      <div className={styles.medicalContainer}>
        <div className={`${styles.formTitle} d-flex mt-3`} style={{ display: isProfileCard ? 'none' : '' }}>
          {Number(selectedPatient?.value) !== Number(userInfo.id)
            ? 'Their Medical History'
            : 'Your Medical Information'}
        </div>
        <ProgressBar
          currentStep={state?.isPatientForm ? 2 : 1}
          totalSteps={state?.isPatientForm ? 5 : state?.isPatientForm ? 4 : 3}
          hideStepNumbers
        />
        <form
          className={styles.formContainer}
          style={{ width: isProfileCard ? '100%' : '', marginRight: isProfileCard ? '0px' : '' }}
          onSubmit={handleSubmit(onSubmit, onInvalid)}
        >
          <div
            className={styles.wrapper}
            style={{ width: isProfileCard ? '734px' : screenSize.width < 992 ? '90%' : '' }}
          >
            <div className={styles.formContent}>
              {questions !== undefined &&
                questions.map((element) => {
                  if (element.type === 'select') {
                    if (element.title !== 'Height' && element.title !== 'Weight') {
                      return (
                        <QuestionAndMultiselect
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          isEmoji
                        />
                      );
                    }
                  } else if (element.type === 'checkbox' && parentQuestion) {
                    return (
                      <QuestionAndMultiselectSideQuestion
                        {...register(`question_${element.id}` as string)}
                        element={element}
                        form={medicalHistoryForm}
                        key={element.id}
                        setForm={setMedicalHistoryForm}
                        isChild
                        isEmoji
                        isparentQuestionActive={parentQuestion}
                      />
                    );
                  } else if (
                    element.type !== 'checkbox' &&
                    element.type !== 'select' &&
                    element.title &&
                    !element.title.startsWith('Pharmacy')
                  )
                    return (
                      <QuestionAndRadioButton
                        {...register(`question_${element.id}`)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <br />
            <div className={styles.measurments}>
              {questions !== undefined &&
                questions.map((element) => {
                  if (element.type === 'select' && (element.title === 'Height' || element.title === 'Weight')) {
                    if (element.title === 'Height') {
                      return (
                        <QuestionAndDropdown
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          givingOptions={heightOptions}
                          twoInputs
                          givingOptionsTwo={inchOptions}
                        />
                      );
                    }
                    return (
                      <QuestionAndDropdown
                        {...register(`question_${element.id}`)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                        givingOptions={poundOptions}
                      />
                    );
                  }
                  return null;
                })}
            </div>
            <br />
            <div className={styles.pharmacy}>
              <span className={styles.pharmacyTitle}>Pharmacy</span>
              {questions !== undefined &&
                questions.map((element) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    (element.title === 'Pharmacy Name' || element.title === 'Pharmacy Street Address')
                  )
                    return (
                      <QuestionAndTextField
                        {...register(`question_${element.id}`)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <div className={styles.pharmacyAddress}>
              {questions !== undefined &&
                questions.map((element) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    (element.title === 'Pharmacy City' ||
                      element.title === 'Pharmacy State' ||
                      element.title === 'Pharmacy Zip Code')
                  )
                    if (element.title === 'Pharmacy City' || element.title === 'Pharmacy Zip Code') {
                      return (
                        <QuestionAndTextField
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                        />
                      );
                    } else if (element.title === 'Pharmacy State') {
                      return (
                        <QuestionAndDropdown
                          {...register(`question_${element.id}`)}
                          element={element}
                          key={element.id}
                          form={medicalHistoryForm}
                          setForm={setMedicalHistoryForm}
                          givingOptions={stateOptions}
                        />
                      );
                    }
                  return null;
                })}
            </div>
            <div className={styles.pharmacyPhone}>
              {questions !== undefined &&
                questions.map((element) => {
                  if (
                    element.title &&
                    element.title.startsWith('Pharmacy') &&
                    element.title === 'Pharmacy Phone Number'
                  )
                    return (
                      <QuestionAndPhoneInput
                        {...register(`question_${element.id}`)}
                        element={element}
                        key={element.id}
                        form={medicalHistoryForm}
                        setForm={setMedicalHistoryForm}
                      />
                    );
                  return null;
                })}
            </div>
            <div className={styles.btnContainer}>
              <Button type="submit" style={{ width: '245px', height: '46px' }}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
MedicalHistory.defaultProps = {
  isProfileCard: false,
};
export default MedicalHistory;
