import React, { SetStateAction } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type PlanSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const PlanSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: PlanSectionProps): JSX.Element => {
  const saveOneReportPlan = async (attributeName: string, attributeValue: any): Promise<void> => {
    if (attributeValue || attributeValue === '') {
      const updateData = {
        [attributeName]: attributeValue,
      };
      const response = await updateOneReport(thisCase?.one_report.id || '', updateData);

      setCase({
        ...thisCase,
        one_report: {
          ...thisCase.one_report,
          ...response,
        },
      });
    }
  };

  return (
    <div className={styles.subjectiveDiv}>
      <div className={styles.subjective}>
        <span>Disposition/Intervention or recommendations provided*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.disposition ? '#BA271A' : '' }}
            {...register('plan.disposition')}
            value={consultationForm.plan?.disposition || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                plan: {
                  ...consultationForm.plan,
                  disposition: e.target.value,
                },
              });

              setValue('plan.disposition', e.target.value);

              if (errors?.plan?.disposition) {
                trigger('plan.disposition');
              }
            }}
            onBlur={() => {
              saveOneReportPlan('disposition', consultationForm.plan.disposition);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.disposition || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.disposition ? '#BA271A' : '' }}
          />
        )}
      </div>
      <div className={styles.subjective}>
        <span>Prescriptions prescribed in ICore</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.prescription_in_icore ? '#BA271A' : '' }}
            {...register('plan.prescription_in_icore')}
            value={consultationForm.plan?.prescription_in_icore || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                plan: {
                  ...consultationForm.plan,
                  prescription_in_icore: e.target.value,
                },
              });

              setValue('plan.prescription_in_icore', e.target.value);

              if (errors?.plan?.prescription_in_icore) {
                trigger('plan.prescription_in_icore');
              }
            }}
            onBlur={() => {
              saveOneReportPlan('prescription_in_icore', consultationForm.plan.prescription_in_icore);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.prescription_in_icore || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.prescription_in_icore ? '#BA271A' : '' }}
          />
        )}
      </div>
      <div className={styles.subjective}>
        <span>Pharmacy</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.pharmacy ? '#BA271A' : '' }}
            {...register('plan.pharmacy')}
            value={consultationForm.plan?.pharmacy || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                plan: {
                  ...consultationForm.plan,
                  pharmacy: e.target.value,
                },
              });

              setValue('plan.pharmacy', e.target.value);

              if (errors?.plan?.pharmacy) {
                trigger('plan.pharmacy');
              }
            }}
            onBlur={() => {
              saveOneReportPlan('pharmacy', consultationForm.plan.pharmacy);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.pharmacy || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.pharmacy ? '#BA271A' : '' }}
          />
        )}
      </div>
      <div className={styles.subjective}>
        <span>Need for Oral Health Coaching through Dentistry.One</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.need_for_oral_health_coaching ? '#BA271A' : '' }}
            {...register('plan.need_for_oral_health_coaching')}
            value={consultationForm.plan?.need_for_oral_health_coaching || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                plan: {
                  ...consultationForm.plan,
                  need_for_oral_health_coaching: e.target.value,
                },
              });

              setValue('plan.need_for_oral_health_coaching', e.target.value);

              if (errors?.plan?.need_for_oral_health_coaching) {
                trigger('plan.need_for_oral_health_coaching');
              }
            }}
            onBlur={() => {
              saveOneReportPlan('need_for_oral_health_coaching', consultationForm.plan.need_for_oral_health_coaching);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.need_for_oral_health_coaching || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.plan?.need_for_oral_health_coaching ? '#BA271A' : '' }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(PlanSection);
