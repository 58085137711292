import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeModalIcon from '../../assets/icons/close.svg';
import { customStylesForgotPassword } from '../../Utils/customStyles';
import { displayErrorDetails } from '../../Utils/displayError';
import { getSecondary } from '../../Utils/getPrimaryColor';
import styles from './styles.module.scss';

type ForgotPasswordCredentials = {
  username: string;
  code: string;
  new_password: string;
};

interface ForgotPasswordModalStepTwoProps {
  forgotPasswordStep: number;
  setForgotPasswordStep: (num: number) => void;
  credentials: ForgotPasswordCredentials;
  setCredentials: (credentials: ForgotPasswordCredentials) => void;
  resetFlag: boolean;
}

type ForgotPasswordModalStepTwoSubmitForm = {
  code?: string;
};

const ForgotPasswordModalStepTwo: FC<ForgotPasswordModalStepTwoProps> = ({
  forgotPasswordStep,
  setForgotPasswordStep,
  credentials,
  setCredentials,
  resetFlag,
}) => {
  const [code, setCode] = useState<ForgotPasswordModalStepTwoSubmitForm>({ code: '' });
  const closeModal = (): void => {
    setForgotPasswordStep(0);
    setCredentials({
      username: '',
      code: '',
      new_password: '',
    });
  };

  useEffect(() => {
    if (forgotPasswordStep === 2) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && forgotPasswordStep === 0) {
      document.body.classList.remove('modal-open');
    }
  }, [forgotPasswordStep]);

  const onSubmit = (): void => {
    setCredentials({ ...credentials, ...code });
    setForgotPasswordStep(forgotPasswordStep + 1);
  };

  const resendCode = (): void => {
    try {
      Auth.forgotPassword(credentials.username);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Code number is required')
      .test('test-name', 'Enter Valid Code', (value: string | undefined) => {
        const codeRegex = /^[0-9]{6}$/;
        setCode({ code: value as string });
        return codeRegex.test(value as string);
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordModalStepTwoSubmitForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  return (
    <div className={styles.container}>
      <Modal
        isOpen={forgotPasswordStep === 2}
        onRequestClose={closeModal}
        style={customStylesForgotPassword}
        contentLabel="Modal"
        ariaHideApp={false}
        className={`${styles.wrapper} ${styles.forgotPasswordModal}`}
        shouldCloseOnOverlayClick={false}
      >
        <button
          type="button"
          className={styles.modalClose}
          aria-label="Close modal"
          onClick={closeModal}
          style={{ backgroundImage: `url(${closeModalIcon})` }}
        />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <span className={`${styles.formTitle} p-b-26 flex flex-column justify-content-center align-items-center`}>
            <span className={styles.formTitleForgot}>{resetFlag ? 'Reset Password' : 'Forgot Password'}</span>
            <div className={`${styles.formTitleLink} pt-1`}>
              <p className={`${styles.txt1} txt1 mb-0`} style={{ width: '224px' }}>
                Please enter the 6 digit OTP code that we sent to your email
              </p>
            </div>
          </span>
          <div className={styles.inputWrapper} data-validate="Valid code is: 123456">
            <input
              {...register('code')}
              className={`${styles.input100} ${errors.code ? 'is-invalid' : ''}`}
              type="text"
              name="code"
              placeholder="Code"
            />
            <span className={styles.focusInput100} />
            <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.code?.message}</div>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button type="submit" className={`${styles.formBtn} ${styles.signIn}`}>
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className={`text-center ${styles.formCheck}`} style={{ paddingLeft: '0px' }}>
          <span>Didn&apos;t get the code?&nbsp;</span>
          <button
            type="button"
            className={`text-center ${styles.txt3} txt3`}
            style={{ color: getSecondary() }}
            onClick={resendCode}
          >
            Resend
          </button>
        </div>
      </Modal>
      <div className={styles.modalOuterText}>
        <p className="text-center" style={{ width: '276px' }}>
          *If you don’t see the email in your inbox, check your spam folder.
        </p>
      </div>
    </div>
  );
};

Modal.setAppElement('#root');

export default ForgotPasswordModalStepTwo;
