import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CurrentPatientState {
  [userId: number]: string;
}

const initialState: CurrentPatientState = {};

const currentPatientStateSlice = createSlice({
  name: 'currentPatientState',
  initialState,
  reducers: {
    setPatientState: (state, action: PayloadAction<{ userId: number; state: string }>) => {
      const { userId, state: newState } = action.payload;
      if (typeof newState === 'string') {
        state[userId] = newState;
      } else {
        console.error('New state must be a string.');
      }
    },
  },
});

export const { setPatientState } = currentPatientStateSlice.actions;

export default currentPatientStateSlice.reducer;
