import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import { selectAuth } from '@brands/store/selectors/auth';
import { isEqual } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import CaseConversationList from '../../ChatWindow/ChatComponents/CaseConversationList';
import CaseMessageBody from '../../ChatWindow/ChatComponents/CaseMessageBody';
import {
  useConversationBySid,
  useConversationParticipants,
  useConversations,
  useSubscribe,
  useTwilioConversationBySid,
  useWindowSize,
} from '../../hooks';
import { useAppSelector } from '../../hooks/useReduxHook';
import { Subscriber } from '../../hooks/useSubscribe';
import { CasePayload } from '../../services/cases/createNewCase';
import { editCaseById } from '../../services/cases/editCase';
import { getOneCase } from '../../services/cases/getCaseById';
import { defaultICaseValues } from '../../services/cases/types/defaultICaseValues';
import { ICase } from '../../services/cases/types/ICase';
import { getConversation } from '../../services/communication/getConversation';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { displayErrorDetails } from '../../Utils/displayError';
import styles from './case-styles.module.scss';

type MessagesProps = {
  isProfileCard?: boolean;
};

const CaseChatWindow: React.FC<MessagesProps> = ({ isProfileCard }) => {
  const { state } = useLocation() as any;
  const { fromDashboard } = state || { fromDashboard: false };
  const { userInfo } = useAppSelector(selectAuth);
  const routeCaseId = useParams().caseId as string;
  const [thisCase, setCase] = useState<ICase>();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const updateThisCase = async (thisCaseId: number): Promise<void> => {
    const payload: CasePayload = {
      status: 'in_progress_provider',
    };

    await editCaseById(thisCaseId, payload);
  };
  const getCurrentCase = async (caseId: string): Promise<void> => {
    const currentCase = await getOneCase(Number(caseId), {
      include_patient_care_advisor_conversation:
        userInfo.role.name !== UserRoleName.Admin &&
        userInfo.role.name !== UserRoleName.SuperAdmin &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin,
      include_patient_provider_conversation:
        userInfo.role.name !== UserRoleName.Admin &&
        userInfo.role.name !== UserRoleName.SuperAdmin &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin,
      include_provider_care_advisor_conversation:
        userInfo.role.name !== UserRoleName.Patient &&
        userInfo.role.name !== UserRoleName.Admin &&
        userInfo.role.name !== UserRoleName.SuperAdmin &&
        userInfo.role.name !== UserRoleName.OrganizationAdmin,
    });
    setCase(currentCase);
    if (
      userInfo.role.name === UserRoleName.Provider &&
      currentCase.provider_id === userInfo.id &&
      currentCase.status === 'accepted_provider'
    ) {
      updateThisCase(currentCase.id);
    }
  };

  useEffect(() => {
    getCurrentCase(routeCaseId);
  }, []);

  const changeCaseUnreadIndicator = async (): Promise<void> => {
    if (thisCase?.id) {
      try {
        const updatedCase = await getOneCase(thisCase?.id, {
          include_patient_care_advisor_conversation: true,
          include_patient_provider_conversation: true,
          include_provider_care_advisor_conversation: userInfo.role.name !== UserRoleName.Patient,
        });
        if (updatedCase) {
          setCase({
            ...thisCase,
            patient_care_advisor_conversation_sid: updatedCase.patient_care_advisor_conversation_sid,
            patient_provider_conversation_sid: updatedCase.patient_provider_conversation_sid,
            provider_care_advisor_conversation_sid: updatedCase.provider_care_advisor_conversation_sid,
            patient_care_advisor_conversation_stats: updatedCase.patient_care_advisor_conversation_stats,
            patient_provider_conversation_stats: updatedCase.patient_provider_conversation_stats,
            provider_care_advisor_conversation_stats: updatedCase.provider_care_advisor_conversation_stats,
          });
        }
      } catch (error: unknown) {
        displayErrorDetails(error);
      }
    }
  };

  const onMessage = useCallback(
    async (message: Record<string, any>) => {
      if (thisCase && Number(message.case_id) === Number(thisCase.id)) {
        if (['case_new_message_created'].includes(message.command)) {
          if (thisCase && !isEqual(thisCase, defaultICaseValues)) {
            changeCaseUnreadIndicator();
          }
        } else if (['case_canceled', 'case_completed', 'case_updated'].includes(message.command)) {
          if (conversationSid) {
            const updatedConversation = await getReadOnlyConversation();
            if (updatedConversation?.state !== 'active') {
              refreshConversation();
            }
          }
        }
      }
    },
    [thisCase],
  );

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage,
      commands: ['case_new_message_created', 'case_canceled', 'case_completed', 'case_updated'],
    }),
    [onMessage],
  );

  const { subscribe, unsubscribe } = useSubscribe();

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  const [leaveModalOpen, setLeaveModalOpen] = useState(false);
  const routeConversationSid = useParams().conversationSid || null;
  const [conversationSid, setConversationSid] = useState<string | null>(routeConversationSid);
  const [readOnlyConversation, setReadOnlyConversation] = useState<IGMConversation | null>(null);
  const twilioConversation = useTwilioConversationBySid(conversationSid);
  const [conversations, setConversations] = useState<
    { conversationType: string; conversation?: IGMConversation; createNewConversation?: boolean }[]
  >([]);

  const getReadOnlyConversation = (): Promise<IGMConversation | null> => {
    if (conversationSid) return getConversation(conversationSid);
    return Promise.resolve(null);
  };

  const { conversation, refreshConversation } = useConversationBySid(conversationSid || null);

  const { participants } = useConversationParticipants(twilioConversation);

  const params = useMemo(
    () => ({
      include_participants: true,
      include_inactive: true,
    }),
    [],
  );

  const { ccJoin } = useConversations(params);

  useEffect(() => {
    refreshConversation();
  }, [participants]);

  const { participantJoin } = useConversationParticipants(twilioConversation);

  const previousParticipantJoin = useRef(participantJoin);

  useEffect(() => {
    if (previousParticipantJoin.current !== participantJoin) {
      window.location.reload();
    }
    previousParticipantJoin.current = participantJoin;
  }, [participantJoin]);

  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 970;

  useEffect(() => {
    if (!routeConversationSid) {
      return;
    }
    setConversationSid(routeConversationSid);
  }, [routeConversationSid]);

  useEffect(() => {
    getReadOnlyConversation().then((readOnly) => {
      setReadOnlyConversation(readOnly);
    });
  }, [conversationSid]);

  const updateUnreadNotification = (): void => {
    if (
      thisCase?.patient_care_advisor_conversation_stats &&
      thisCase?.patient_care_advisor_conversation_stats?.length > 0 &&
      thisCase?.patient_care_advisor_conversation_stats[0]?.conversation_sid === conversationSid
    ) {
      setCase({
        ...thisCase,
        patient_care_advisor_conversation_stats: null,
      });
    }
    if (
      thisCase?.patient_provider_conversation_stats &&
      thisCase?.patient_provider_conversation_stats?.length > 0 &&
      thisCase?.patient_provider_conversation_stats[0]?.conversation_sid === conversationSid
    ) {
      setCase({
        ...thisCase,
        patient_provider_conversation_stats: null,
      });
    }
    if (
      thisCase?.provider_care_advisor_conversation_stats &&
      thisCase?.provider_care_advisor_conversation_stats.length > 0 &&
      thisCase?.provider_care_advisor_conversation_stats[0]?.conversation_sid === conversationSid
    ) {
      setCase({
        ...thisCase,
        provider_care_advisor_conversation_stats: null,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.chatWindow}>
        {downloadLoading ? (
          <Loading dotted />
        ) : (
          <>
            {(!isMobile || (isMobile && !conversationSid)) && (
              <CaseConversationList
                thisCase={thisCase}
                activeConversationSid={conversationSid}
                setActiveConversationSid={setConversationSid}
                isProfileCard={isProfileCard || false}
                setConversations={setConversations}
                conversations={conversations}
                setCase={setCase}
                setLoading={setLoading}
                changeCaseUnreadIndicator={changeCaseUnreadIndicator}
              />
            )}
            {(!isMobile || (isMobile && conversationSid)) && conversationSid ? (
              <CaseMessageBody
                twilioConversation={twilioConversation}
                readOnlyConversation={readOnlyConversation}
                isMobile={isMobile}
                conversationSid={conversationSid}
                setConversationSid={setConversationSid}
                conversation={conversation}
                isProfileCard={isProfileCard || false}
                setLeaveModalOpen={setLeaveModalOpen}
                leaveModalOpen={leaveModalOpen}
                currentCase={thisCase}
                updateUnreadNotification={updateUnreadNotification}
                conversationType={
                  conversations.find(
                    (selectedConversation) =>
                      selectedConversation.conversation && selectedConversation.conversation.sid === conversationSid,
                  )?.conversationType
                }
                patientCurrentState={thisCase?.patient_current_state}
                loading={loading}
                setLoading={setDownloadLoading}
              />
            ) : !loading ? (
              <div className={styles.chatBody}>
                <span className={styles.centerText}>Choose a user on the left to enter the conversation</span>
              </div>
            ) : (
              <Loading dotted />
            )}
            {fromDashboard && !ccJoin && (
              <div style={{ display: isMobile ? 'none' : 'block' }}>
                <div className={styles.popupMessageContainer}>
                  <div className={styles.popupMessage}>
                    <p className={styles.notificationMessage}>A Care Advisor will reach out to you soonzz</p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
CaseChatWindow.defaultProps = {
  isProfileCard: false,
};
export default memo(CaseChatWindow);
