import PhoneInput from '@brands/Components/Inputs/PhoneInput/PhoneInput';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import AddressSection from '@brands/Dashboard/Dashboard/SuperAdmin/Components/AddressSection/AddressSection';
import { getUserById } from '@brands/services/identity/getUserById';
import { listAllOrganizations } from '@brands/services/identity/listAllOrganizations';
import { updateUserOrganization } from '@brands/services/identity/updateUserOrganization';
import { mapProfileToMyProfileSubmit } from '@brands/store/actions/organizationAdminAction';
import { selectAuth } from '@brands/store/selectors/auth';
import { setUser } from '@brands/store/slices/authSlice';
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { Option } from '@brands/Utils/selectOptions';
import { UnifiedProfileSubmit } from '@brands/Utils/types';
import { validateAddress, ValidationModalInterface } from '@brands/Utils/validateAddress';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormGetValues } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

import BlueCircle from '../../../assets/icons/light-blue-circle.svg';
import GrayCircle from '../../../assets/icons/light-gray-circle.svg';
import Button from '../../../Components/Button/Button';
import TextField from '../../../Components/Inputs/TextField/TextField';
import ProfileImage from '../../../Components/ProfileImage/ProfileImage';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { defaultUserValues } from '../../../services/cases/types/defaultUserValues';
import { RegisterMediaPayload } from '../../../services/forms/registerFormMedia';
import { confirmAvatarMedia } from '../../../services/identity/confirmAvatarMedia';
import { createBasicInformation } from '../../../services/identity/createBasicInformation';
import { deleteAvatar } from '../../../services/identity/deleteAvatar';
import { getCurrentProfile } from '../../../services/identity/getCurrentProfile';
import { registerAvatarMedia } from '../../../services/identity/registerAvatarMedia';
import { saveBasicInformation } from '../../../services/identity/saveBasicInformation';
import {
  Address,
  BasicInfo,
  Organization,
  UserProfile,
  UserRoleName,
} from '../../../services/identity/types/UserProfile';
import { UpdateMe, updateMe } from '../../../services/identity/updateMe';
import { displayErrorDetails } from '../../../Utils/displayError';
import Sidenav from '../../Sidenav/Sidenav';
import ReviewProfileHeader from '../Components/ReviewProfileHeader';
import ChangeUserStatusModal from '../Modal/ChangeUserStatusModal';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import { updateData } from '../Patient/utils/utilsFunctions';
import styles from '../styles.module.scss';
import { OrganizationAdminMyProfileSubmit } from './utils/types';
import { profileValidationSchema } from './utils/validationSchema';

type ReviewMyProfileProps = {
  reviewProfile?: boolean;
};

const MyProfile = ({ reviewProfile }: ReviewMyProfileProps): JSX.Element => {
  const userId = useParams().userId as string;
  const roleName = useParams().role as string;
  const { userInfo } = useAppSelector(selectAuth);
  const [thisUser, setThisUser] = useState<UserProfile>(userInfo || defaultUserValues);
  const [image, setImage] = React.useState<string>();
  const [currentAvatarURL, setCurrentAvatarURL] = useState<string>('');
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarPayload, setAvatarPayload] = useState<RegisterMediaPayload | null>(null);
  const [avatarAction, setAvatarAction] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const dispatch = useAppDispatch();
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [validationResponseId, setValidationResponseId] = useState('');
  const [resetPasswordConfirmation, setResetPasswordConfirmation] = useState<{ isOpen: boolean; userState: string }>({
    isOpen: false,
    userState: thisUser.state || '',
  });
  const [showValidationAddressModal, setShowValidationAddressModal] = useState<ValidationModalInterface>({
    isOpen: false,
    title: '',
    whatYouEntered: '',
    recommended: false,
    recommendedAddress: '',
    onClose: undefined,
    onConfirmChange: undefined,
    onKeepCurrentAddress: undefined,
  });
  const [changeUserStatusFlag, setChangeUserStatusFlag] = useState('');
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState<Option[]>([]);
  const [allOrganization, setAllOrganization] = useState<Organization[]>([]);
  const disableButton = (): void => {
    setResetEmailSent(true);
  };
  const disableDeleteButton = (): void => {
    setThisUser({
      ...thisUser,
      status: 'Deactivated',
    });
  };
  const [myProfileForm, setMyProfileForm] = useState<OrganizationAdminMyProfileSubmit>(
    {} as OrganizationAdminMyProfileSubmit,
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<OrganizationAdminMyProfileSubmit>({
    mode: 'onChange',
    resolver: yupResolver(profileValidationSchema(reviewProfile)),
  });

  const getCurrentUser = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      if (reviewProfile && userId) {
        const currentUser = await getUserById(Number(id));
        setThisUser(currentUser);
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetails = async (): Promise<void> => {
    const userDetails = await getCurrentProfile();
    setThisUser(userDetails);
  };

  const fetchProfile = (): void => {
    const mappedProfile = mapProfileToMyProfileSubmit(thisUser);
    let selectedOrganizations: SingleValue<Option> = {} as SingleValue<Option>;
    if (mappedProfile.organizations && (mappedProfile.organizations as Organization)) {
      for (let i = 0; i < organizationOptions.length; i++) {
        if (organizationOptions[i].id === mappedProfile.organizations.id) {
          selectedOrganizations = organizationOptions[i];
        }
      }
    }
    setMyProfileForm({
      ...mappedProfile,
      organization: selectedOrganizations,
      organizations: mappedProfile.organizations,
    });
  };

  useEffect(() => {
    fetchProfile();
  }, [thisUser, organizationOptions]);

  useEffect(() => {
    if (userId) {
      getCurrentUser(userId);
    } else {
      fetchUserDetails();
    }
  }, [userId]);

  const triggerProfileAvatar = React.useCallback((avatarUrl: string) => {
    setValue('profile_img', avatarUrl, { shouldValidate: true });
    setMyProfileForm({
      ...getValues(),
      profile_img: avatarUrl,
    });
  }, []);

  const onCloseConfirmationModal = (): void => {
    setShowValidationAddressModal({
      isOpen: false,
      title: '',
      whatYouEntered: '',
      recommended: false,
      recommendedAddress: '',
      onClose: undefined,
      onConfirmChange: undefined,
      onKeepCurrentAddress: undefined,
    });
  };

  const callSaveProfile = async (profileData: UnifiedProfileSubmit): Promise<void> => {
    setIsSavingProfile(true);
    try {
      if (avatarPayload && avatarFile && avatarAction === 'Upload') {
        const res = await registerAvatarMedia(avatarPayload);
        const mediaId = res.files[0].upload_id;
        await fetch(res.files[0].upload_url, {
          method: 'PUT',
          body: avatarFile,
          headers: { 'content-type': avatarFile.type },
        });
        await confirmAvatarMedia(mediaId, Number(thisUser?.id));
        triggerProfileAvatar(res.files[0].upload_url);
      } else if (avatarAction === 'Remove') {
        await deleteAvatar(thisUser?.id || 0);
        triggerProfileAvatar('');
      }

      let updatedUser;

      const dataWithUpdatedDOB = updateData(profileData);
      updatedUser = await updateMe(
        {
          ...(profileData as UpdateMe),
          ...((profileData as OrganizationAdminMyProfileSubmit).phone_number && {
            phone_number: parsePhoneNumber(
              (profileData as OrganizationAdminMyProfileSubmit).phone_number,
              'US',
            )?.format('E.164') as string,
          }),
          address: profileData as Address,
        },
        thisUser?.id || 0,
      );

      if (thisUser && thisUser.basic_info !== null) {
        updatedUser = await saveBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
          },
          thisUser?.id || 0,
        );
      } else {
        updatedUser = await createBasicInformation(
          {
            ...(dataWithUpdatedDOB as BasicInfo),
          },
          thisUser?.id || 0,
        );
      }

      if (thisUser && reviewProfile) {
        if (
          (profileData as OrganizationAdminMyProfileSubmit).organization &&
          thisUser.organizations[0]?.id !== (profileData as OrganizationAdminMyProfileSubmit).organization?.id
        ) {
          const updatedOrganizations = (profileData as OrganizationAdminMyProfileSubmit)?.organization;
          const organizationIdList: string[] = [];

          if (updatedOrganizations) {
            organizationIdList.push(updatedOrganizations.id as string);
          }

          if (organizationIdList.length > 0) {
            updateUserOrganization(thisUser.id, {
              organization_ids: organizationIdList,
            });
          }
        }
      }
      setThisUser({
        ...updatedUser,
        organizations: [(profileData as OrganizationAdminMyProfileSubmit).organizations],
      });
      const selectedOrganizationIds = [(profileData as OrganizationAdminMyProfileSubmit).organization?.id];
      setThisUser({
        ...updatedUser,
        organizations: allOrganization.filter((org) => selectedOrganizationIds?.includes(org.id)),
        organization_admin_profile: {
          ...updatedUser.organization_admin_profile,
        },
      });
      setMyProfileForm({
        ...getValues(),
        organizations: getValues('organizations') || [],
      });
      if (!reviewProfile) {
        dispatch(setUser({ userInfo: updatedUser }));
      }

      setIsEditProfile(false);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setIsSavingProfile(false);
  };

  const updateCurrentAddress = (standardizedAddress: any, postalAddress: any, addressComponents: any): void => {
    const streetNumber = addressComponents.find(
      (addressComponent: any) => addressComponent.componentType === 'street_number',
    );
    const address1 = addressComponents.find((addressComponent: any) => addressComponent.componentType === 'route');
    if (postalAddress) setValue('city', postalAddress.locality);
    if (streetNumber || address1) {
      setValue('address1', `${streetNumber.componentName.text} ${address1.componentName.text}`);
    }
    if (standardizedAddress?.state) setValue('state', standardizedAddress.state);
    if (postalAddress) setValue('zip_code', postalAddress.postalCode);
  };

  const onSubmit = async (profileData: OrganizationAdminMyProfileSubmit): Promise<void> => {
    await validateAddress(
      profileData,
      getValues as UseFormGetValues<UnifiedProfileSubmit>,
      validationResponseId,
      callSaveProfile,
      setValidationResponseId,
      setShowValidationAddressModal,
      onCloseConfirmationModal,
      updateCurrentAddress,
    );
  };

  const onEditProfile = (): void => {
    setIsEditProfile(true);
  };

  const onCancelEditProfile = (): void => {
    setImage(currentAvatarURL);
    setIsEditProfile(false);
  };

  useEffect(() => {
    if (Object.keys(myProfileForm).length > 1) {
      Object.keys(myProfileForm).forEach((key: unknown) => {
        setValue(
          key as keyof OrganizationAdminMyProfileSubmit,
          myProfileForm[key as keyof OrganizationAdminMyProfileSubmit],
        );
      });
      setRender(true);
      if (!reviewProfile) setLoading(false);
    }
  }, [myProfileForm]);

  const onInvalid = (errorsList: any): void => {
    const error = Object.values(errorsList)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const [selection, setSelection] = React.useState<[number | null, number | null] | null>(null);
  const ref = React.useRef<HTMLInputElement>(null);

  React.useLayoutEffect(() => {
    if (selection && ref.current) {
      [ref.current.selectionStart, ref.current.selectionEnd] = selection;
    }
  }, [selection]);

  const setTextValue = (e: React.ChangeEvent<HTMLInputElement>, name: string): void => {
    const { value } = e.target;
    setValue(name as keyof OrganizationAdminMyProfileSubmit, value, { shouldValidate: true });
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  };

  const fetchOrganizationOptions = async (): Promise<void> => {
    try {
      const data = await listAllOrganizations();
      setAllOrganization(data.nodes);
      const transformedData = data.nodes.map((organization) => ({
        value: organization.id,
        label: organization.name,
        id: organization.id,
      }));
      setOrganizationOptions(transformedData);
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    if (reviewProfile) fetchOrganizationOptions();
  }, []);

  const renderEditProfile = (): JSX.Element => {
    return (
      <div className={styles.editProfileContainer}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>Profile</span>
        </div>
        <div className={styles.editContainer}>
          <ProfileImage
            reviewProfile={reviewProfile}
            id={userId}
            setCurrentAvatarURL={setCurrentAvatarURL}
            setAvatarFile={setAvatarFile}
            setAvatarPayload={setAvatarPayload}
            setAvatarAction={setAvatarAction}
            triggerProfileAvatar={triggerProfileAvatar}
            setImage={setImage}
            image={image}
            errors={errors}
            size="80px"
          />
          <p className={styles.requiredIndicator}>
            Required <span>*</span>
          </p>
          <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className={styles.row}>
              <div className={cn(styles.rowItem, styles.details, styles.midRow)}>
                <div className={styles.caption}>
                  First Name <span>*</span>
                </div>
                <TextField
                  {...register('first_name')}
                  errors={errors}
                  name="first_name"
                  value={getValues('first_name')}
                  onChange={(e) => setTextValue(e, 'first_name')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
              <div className={cn(styles.rowItem, styles.details, styles.midRow)}>
                <div className={styles.caption}>
                  Last Name <span>*</span>
                </div>
                <TextField
                  {...register('last_name')}
                  errors={errors}
                  name="last_name"
                  value={getValues('last_name')}
                  onChange={(e) => setTextValue(e, 'last_name')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.row}>
              <AddressSection
                register={register}
                errors={errors}
                getValues={getValues}
                setTextValue={setTextValue}
                setValue={setValue}
                showValidationAddressModal={showValidationAddressModal}
                required
              />
            </div>
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <div className={styles.caption}>Phone Number</div>
                <div className={styles.inputWrapper}>
                  <PhoneInput
                    {...register('phone_number')}
                    value={getValues('phone_number')}
                    id="phone_number"
                    onChange={(val) => {
                      setValue('phone_number', val as string, {
                        shouldValidate: true,
                      });
                    }}
                    inputClass={styles.input}
                    errors={errors}
                    wrapperClassName={styles.inputWrapper}
                    ref={phoneInputRef}
                  />
                </div>
              </div>
            </div>
            <div className={styles.separator} />
            <div className={styles.row}>
              <div className={styles.rowItem}>
                <div className={styles.caption}>
                  Email Address <span>*</span>
                </div>
                <TextField
                  {...register('email')}
                  errors={errors}
                  name="email"
                  value={getValues('email')}
                  onChange={(e) => setTextValue(e, 'email')}
                  wrapperClassName={styles.inputWrapper}
                  inputClass={styles.input}
                />
              </div>
            </div>
            {reviewProfile && (
              <div className={styles.row}>
                <div className={cn(styles.rowItem, styles.details, styles.big)}>
                  <div className={styles.caption}>
                    Organization <span>*</span>
                  </div>
                  <SelectInput
                    {...register('organization')}
                    name="organization"
                    options={organizationOptions}
                    containerClass={styles.inputWrapper}
                    selectorClass={styles.selector}
                    style={selectCommonCustomStylesLarge}
                    selectedValue={getValues('organization')}
                    errors={errors}
                    onChange={(selectedOptions: SingleValue<Option>) => {
                      if (selectedOptions) {
                        const selectedOrganizations = selectedOptions;
                        const organizationsArray = allOrganization.find(
                          (selectedOrg) => selectedOrg.id === selectedOrganizations?.id,
                        );

                        setValue(
                          'organizations',
                          {
                            ...organizationsArray,
                            divisions: [],
                            practices: [],
                          } as Organization,
                          { shouldValidate: true },
                        );

                        setValue('organization', selectedOrganizations, { shouldValidate: true });
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className={styles.actionBtnContainer}>
              <Button type="button" className={styles.cancel} onClick={onCancelEditProfile}>
                Cancel
              </Button>
              <Button
                type="submit"
                className={styles.submit}
                disabled={(isEqual(myProfileForm, getValues()) && isEqual(avatarAction, '')) || isSavingProfile}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const fullAddress = useMemo(() => {
    if (thisUser?.address) {
      const { address1, address2, city, state, zip_code: zipCode } = thisUser.address;
      return `${address1}${address2 ? ` , ${address2}` : ''}, ${city}, ${state} ${zipCode}`;
    }

    return ' ';
  }, [thisUser]);

  const displayPhoneNumber = useMemo(() => {
    if (thisUser?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisUser?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }

    return '';
  }, [thisUser]);

  const userOrganization = useMemo(() => {
    if (thisUser?.organizations) {
      return thisUser?.organizations
        .map((organization) => {
          return organization.name;
        })
        .join(', ');
    }
    return ' ';
  }, [thisUser]);

  const displayEmail = useMemo(() => {
    if (thisUser?.email) {
      return thisUser.email;
    }

    return '';
  }, [thisUser]);

  const renderReadOnlyProfile = (): JSX.Element => {
    return (
      <div className={styles.readOnlyProfileContainer}>
        {reviewProfile && (
          <ReviewProfileHeader
            thisUser={thisUser}
            setThisUser={setThisUser}
            resetEmailSent={resetEmailSent}
            setChangeUserStatusFlag={setChangeUserStatusFlag}
            setResetPasswordConfirmation={setResetPasswordConfirmation}
            adminRole={roleName === UserRoleName.SuperAdmin || roleName === UserRoleName.Admin}
            userId={userId}
            userRole={thisUser.role.name}
            userOrganizations={thisUser.organizations}
          />
        )}
        <div className={styles.titleContainer}>
          <span className={styles.title}>Profile</span>
          {!(
            userInfo.role.name === UserRoleName.Admin &&
            (roleName === UserRoleName.SuperAdmin || roleName === UserRoleName.Admin)
          ) && (
            <button className={styles.editBtn} type="button" onClick={onEditProfile}>
              Edit
              <FiEdit className={styles.editIcon} />
            </button>
          )}
        </div>
        <div className={styles.detailsContainer}>
          <ProfileImage
            reviewProfile={reviewProfile}
            id={userId}
            image={image}
            setImage={setImage}
            className={styles.profileImg}
            size="80px"
            isUpload={false}
          />
          <div className={styles.readOnlyItem}>
            <div className={styles.caption}>Name</div>
            <div className={styles.value}>
              {thisUser?.basic_info?.first_name} {thisUser?.basic_info?.last_name}
            </div>
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.readOnlyItem}>
            <div className={styles.caption}>Address</div>
            <div className={styles.value}>{fullAddress}</div>
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.readOnlyItem}>
            <div className={styles.caption}>Email Address</div>
            <div className={styles.value}>{thisUser?.email}</div>
          </div>
        </div>
        {thisUser?.phone_number && (
          <div className={styles.detailsContainer}>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Phone Number</div>
              <div className={styles.value}>{displayPhoneNumber}</div>
            </div>
          </div>
        )}
        {thisUser?.email && (
          <div className={styles.detailsContainer}>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Email</div>
              <div className={styles.value}>{displayEmail}</div>
            </div>
          </div>
        )}
        {reviewProfile && (
          <div className={styles.detailsContainer}>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Organization</div>
              <div className={styles.value}>{userOrganization}</div>
            </div>
          </div>
        )}
        <div className={styles.separator} />
        <div
          className={styles.resetPassword}
          style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '20px', width: '100%' }}
        >
          <button
            type="button"
            className={styles.resetPasswordButton}
            onClick={(): void => setResetPasswordConfirmation({ isOpen: true, userState: thisUser.status || 'Active' })}
          >
            Reset Password
          </button>
        </div>
      </div>
    );
  };

  if (!render) {
    return <Loading fullScreen />;
  }

  if (loading) {
    return <Loading fullScreen />;
  }

  return (
    <div className={styles.profileContainer}>
      <img className={styles.bkgTopImage} src={BlueCircle} alt="profile" />
      <img className={styles.bkgBottomImage} src={GrayCircle} alt="profile" />
      <Sidenav isVisibleOnMobile />
      <div className={styles.contentContainer}>{isEditProfile ? renderEditProfile() : renderReadOnlyProfile()}</div>
      {resetPasswordConfirmation.isOpen && (
        <ResetPasswordModal
          setOpenModal={setResetPasswordConfirmation}
          disableButton={disableButton}
          userId={Number(userId)}
          userEmail={thisUser.email}
          userName={thisUser.full_name}
          isDeactivated={thisUser.status === 'Deactivated'}
          userState={resetPasswordConfirmation.userState}
        />
      )}
      {changeUserStatusFlag && (
        <ChangeUserStatusModal
          setOpenModal={setChangeUserStatusFlag}
          disableButton={disableDeleteButton}
          userId={Number(userId)}
          userName={thisUser.full_name}
        />
      )}
      ;
    </div>
  );
};
MyProfile.defaultProps = {
  reviewProfile: false,
};
export default MyProfile;
