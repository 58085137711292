/* eslint-disable no-nested-ternary */
import { Option } from '../../../Utils/selectOptions';

export const timeOptions = ((): Option[] => {
  const options: Option[] = [];
  let index = 0;
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      index += 1;
      const hour = i === 0 || i === 12 ? '12' : i < 12 ? i.toString() : (i - 12).toString();
      const minute = j === 0 ? '00' : '30';
      const meridiem = i < 12 ? 'am' : 'pm';

      options.push({
        id: index.toString(),
        label: `${hour}:${minute}${meridiem}`,
        value: `${hour}:${minute}${meridiem}`,
      });
    }
  }

  options.push({
    id: (index + 1).toString(),
    label: '12:00am',
    value: '12:00am',
  });

  return options;
})();
