import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SmileScanCaseState {
  case_id: string;
}

const initialState: SmileScanCaseState = {
  case_id: '',
};

const smileScanCaseSlice = createSlice({
  name: 'smileScanCase',
  initialState,
  reducers: {
    setCaseId: (state, action: PayloadAction<string>) => {
      state.case_id = action.payload;
    },
    clearCaseId: (state) => {
      state.case_id = '';
    },
  },
});

// Export actions for use in components
export const { setCaseId, clearCaseId } = smileScanCaseSlice.actions;

// Export the reducer to be included in the store
export default smileScanCaseSlice.reducer;
