import MultiselectInput from '@brands/Components/Inputs/MultiselectInput/MultiselectInput';
import SelectInput from '@brands/Components/Inputs/SelectInput/SelectInput';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { FormValues, setForm } from '@brands/store/slices/patientFormSlice';
import { multiSelectCommonCustomStylesLarge, selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { getPainLevelColor } from '@brands/Utils/getFriendlyName';
import { painDurationOptions } from '@brands/Utils/selectButtonOptions';
import { OptionPainLevel } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import { FaMeh, FaSmile } from 'react-icons/fa';
import { PiSmileySadFill } from 'react-icons/pi';
import { RiEmotionSadFill } from 'react-icons/ri';
import { TbMoodSadFilled } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '../../../../Components/Button/Button';
import Container from '../../../../Components/Container/Container';
import ProgressBar from '../../../../Components/ProgressBar/ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import useWindowSize from '../../../../hooks/useWindowSize';
import { FormQuestion } from '../../../../services/forms/types/FormQuestion';
import styles from '../styles.module.scss';
import { describePainLevelQuestions } from '../utils/questions';
import { DescribePainLevelSubmit } from '../utils/types';

const DescribePainLevel = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectAuth);
  const { values, selectedPatient } = useAppSelector(selectPatientForm);
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 768;
  const { state } = useLocation();
  const painOptions: OptionPainLevel[] = [
    { value: 'sharp', label: 'Sharp', id: 'sharp' },
    { value: 'dull', label: 'Dull', id: 'dull' },
    { value: 'throbbing', label: 'Throbbing', id: 'throbbing' },
    { value: 'sensitive_to_hot', label: 'Sensitive to hot', id: 'sensitive_to_hot' },
    { value: 'sensitive_to_cold', label: 'Sensitive to cold', id: 'sensitive_to_cold' },
    { value: 'sensitive_to_sweet', label: 'Sensitive to sweet', id: 'sensitive_to_sweet' },
    { value: 'off_and_on', label: 'On and off', id: 'off_and_on' },
    { value: 'constant', label: 'Constant', id: 'constant' },
    { value: 'burning', label: 'Burning', id: 'burning' },
  ];
  const [formValues, setFormValues] = React.useState<FormValues[]>([]);
  const [patientForm, setPatientForm] = useState<DescribePainLevelSubmit>({
    inPain: 'yes',
    describePain:
      (values?.find((item) => item.form_question_id === 16)?.value as DescribePainLevelSubmit)?.describePain || [],
    painLevel:
      (values?.find((item) => item.form_question_id === 16)?.value as DescribePainLevelSubmit)?.painLevel || 'noPain',
    daysInPain:
      (values?.find((item) => item.form_question_id === 16)?.value as DescribePainLevelSubmit)?.daysInPain || '',
    anythingElse:
      (values?.find((item) => item.form_question_id === 18)?.value as DescribePainLevelSubmit)?.anythingElse || '',
  });

  const [questions] = useState<FormQuestion[]>(describePainLevelQuestions);
  const validationSchema = Yup.object().shape({
    painLevel: Yup.string().required('Select an option in <How would you rate your pain level?>'),
    describePain: Yup.array().when('painLevel', {
      is: (painLevel: string) => painLevel !== 'noPain',
      then: Yup.array().min(1, 'Select at least one option in <How would you describe the pain?>'),
      otherwise: Yup.array(),
    }),
    daysInPain: Yup.string().when('painLevel', {
      is: (painLevel: string) => painLevel !== 'noPain',
      then: Yup.string().required('Select an option in <How long have you been in pain?>'),
      otherwise: Yup.string(),
    }),
    anythingElse: Yup.string(),
  });

  const updateFormValue = (form_question_id: number, value: any): void => {
    const updatedFormValues = formValues.map((item) => {
      if (item.form_question_id === form_question_id) {
        return { ...item, value };
      }
      return item;
    });

    const formQuestionExists = formValues.some((item) => item.form_question_id === form_question_id);
    if (!formQuestionExists) {
      updatedFormValues.push({ form_question_id, value });
    }

    setFormValues(updatedFormValues);
    dispatch(setForm(updatedFormValues));
  };

  const getPainLevelIcon = (painLevel: string, color: string): JSX.Element | null => {
    switch (painLevel.toLowerCase()) {
      case 'no\npain':
        return <FaSmile color={color} />;
      case 'mild\npain':
        return <FaSmile color={color} />;
      case 'moderate\npain':
        return <FaMeh color={color} />;
      case 'severe\npain':
        return <TbMoodSadFilled color={color} />;
      case 'very severe\npain':
        return <PiSmileySadFill color={color} />;
      case 'worst pain\npossible':
        return <RiEmotionSadFill color={color} />;
      default:
        return null;
    }
  };

  const onSubmit = (data: DescribePainLevelSubmit): void => {
    const payload = [...formValues!];
    let filteredPayload = payload;
    filteredPayload = payload.filter((item) => item.form_question_id !== 16 && item.form_question_id !== 18);
    filteredPayload.push({
      form_question_id: 16,
      value: {
        inPain: data.inPain,
        describePain: data.describePain,
        painLevel: data.painLevel,
        daysInPain: data.daysInPain,
      },
    });
    filteredPayload.push({
      form_question_id: 18,
      value: {
        anythingElse: data.anythingElse,
      },
    });
    setFormValues(filteredPayload);
    dispatch(setForm(filteredPayload));
    navigate('/upload-image', {
      state: {
        caseType: state ? state.caseType : '',
        patientId: state ? selectedPatient?.value : '',
      },
    });
  };

  const onBack = (): void => {
    dispatch(setForm([...formValues!]));
    navigate(-1);
  };

  const onInvalid = (errors: any): void => {
    const error = Object.values(errors)[0] as any;
    if (error) {
      toast.error(error.message as string);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<DescribePainLevelSubmit>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    Object.keys(patientForm).forEach((key: unknown) => {
      setValue(key as keyof DescribePainLevelSubmit, patientForm[key as keyof DescribePainLevelSubmit]);
    });
  }, [patientForm]);

  useEffect(() => {
    setPatientForm(getValues());
  }, [getValues]);

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, []);

  return (
    <Container
      isBGEclipse
      isVisibleOnMobile
      containerStyle={{
        justifyContent: screenSize.width >= 564 ? 'center' : 'start',
      }}
      eclipseStyle={{ opacity: 0.2, display: screenSize.width <= 768 ? 'none' : '' }}
      childrenStyle={{ zIndex: '2' }}
    >
      <div className={styles.patientFormContainer}>
        <div className={`${styles.formTitle} mt-3`}>
          {Number(selectedPatient?.value) !== Number(userInfo.id) ? '(Are they) in pain?' : '(Are you) in pain?'}
        </div>
        <ProgressBar currentStep={2} totalSteps={4} onBack={onBack} hideStepNumbers />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div className={styles.wrapper}>
            <div className={styles.formContent} style={{ display: 'flex' }}>
              <div className={`${styles.formTitle} mt-3 py-2`}>
                {Number(selectedPatient?.value) !== Number(userInfo.id)
                  ? 'How would you rate their pain level?'
                  : questions[4].title}
              </div>
              <div className={`${styles.radio}`}>
                {questions[4].optionsObject &&
                  Object.entries(questions[4].optionsObject).map(([key, value]) => (
                    <div key={key}>
                      <label
                        className={`${styles.label} ${patientForm.painLevel === key ? styles.primaryColor : ''}`}
                        style={{
                          color: patientForm.painLevel === key ? '#FFF' : '',
                        }}
                        htmlFor={key}
                      >
                        <div
                          className={styles[key.toLowerCase().split(' ').join('_')]}
                          style={{
                            filter:
                              patientForm.painLevel === key
                                ? 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(29deg) brightness(105%) contrast(102%)'
                                : '',
                          }}
                        />
                        {getPainLevelIcon(value, getPainLevelColor(value))}
                        {value}
                      </label>
                      <input
                        {...register('painLevel')}
                        name={key}
                        id={key}
                        value={key}
                        type="radio"
                        onChange={(e) => {
                          setPatientForm({
                            ...patientForm,
                            painLevel: e.target.value,
                            describePain: e.target.value === 'noPain' ? [] : patientForm.describePain,
                            daysInPain: e.target.value === 'noPain' ? '' : patientForm.daysInPain,
                          });
                          updateFormValue(16, {
                            ...patientForm,
                            painLevel: e.target.value,
                            describePain: e.target.value === 'noPain' ? undefined : patientForm.describePain,
                            daysInPain: e.target.value === 'noPain' ? undefined : patientForm.daysInPain,
                          });
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`row ${styles.requiredFieldsDiv} `}
              style={{ display: patientForm.painLevel !== 'noPain' ? 'flex' : 'none' }}
            >
              <div className="col-md-6">
                <span style={{ opacity: '0.5' }}>How would you describe the pain?</span>
                <span className={styles.redText}> *</span>
                <MultiselectInput
                  {...register('describePain')}
                  name="describePain"
                  containerClass="mt-0"
                  options={painOptions}
                  style={multiSelectCommonCustomStylesLarge}
                  errors={errors}
                  selectedValue={patientForm.describePain?.map((item: string) => {
                    const label = painOptions.find((option) => option.value === item)?.label || 'Unknown label';
                    return { label, value: item };
                  })}
                  onChange={(value) => {
                    if (value) {
                      const isValueArray = Array.isArray(value);
                      if (isValueArray) {
                        setValue(
                          'describePain',
                          value.map((item: any) => item.value),
                          { shouldValidate: true },
                        );
                        setPatientForm({
                          ...patientForm,
                          describePain: value.map((item: any) => item.value),
                        });
                        updateFormValue(16, { ...patientForm, describePain: value.map((item: any) => item.value) });
                      }
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <span style={{ opacity: '0.5' }}>How long have they been in pain?</span>
                <span className={styles.redText}> *</span>
                <SelectInput
                  {...register('daysInPain')}
                  name="daysInPain"
                  options={painDurationOptions}
                  containerClass={styles.inputWrapper}
                  selectorClass={styles.selector}
                  style={selectCommonCustomStylesLarge}
                  errors={errors}
                  selectedValue={patientForm.daysInPain}
                  onChange={(value) => {
                    setValue('daysInPain', value && value.value, { shouldValidate: true });
                    setPatientForm({ ...patientForm, daysInPain: value ? value.value : '' });
                    updateFormValue(16, { ...patientForm, daysInPain: value ? value.value : '' });
                  }}
                />
              </div>
            </div>
            <div>
              <div className={styles.additionalInformationTitle}>
                Is there anything else that you want the dentist to know?
              </div>
              <div className={styles.wrapper}>
                <div className={`${styles.formContent}`}>
                  <div className={styles.textAreaWrapper}>
                    <textarea
                      {...register('anythingElse')}
                      className={styles.textArea}
                      onChange={(e) => {
                        setValue('anythingElse', e.target.value);
                        setPatientForm({ ...patientForm, anythingElse: e.target.value });
                      }}
                      onBlur={(e) => {
                        updateFormValue(18, { anythingElse: e.target.value });
                      }}
                      placeholder="Start note…"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Button
                type="submit"
                style={{ width: '245px', height: '46px' }}
                onTouchStart={isMobile || mobileDetect ? handleSubmit(onSubmit, onInvalid) : undefined}
              >
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};
export default DescribePainLevel;
