/* eslint-disable import/no-cycle */
import { useEffect, useState } from 'react';

import {
  IListAvailableProviderParams,
  listAvailableProvidersByDate,
} from '../services/identity/listAvailableProvidersByDate';
import { isValidDateFormat } from '../Utils/validation';

interface IUseUsers {
  dates: string[];
  dataLoading: boolean;
  refreshProviders: () => void;
}

export interface IslotTime {
  end_time: string;
  start_time: string;
  synchronous: boolean;
  asynchronous: boolean;
}

export function sortSlotTimes(slotTimes: IslotTime[]): IslotTime[] {
  const sortedSlotTimes = slotTimes.sort((a, b) => {
    return new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
  });

  return sortedSlotTimes;
}

export default function useAvailableDates(
  params: Pick<
    IListAvailableProviderParams,
    | 'sort_by'
    | 'sort_direction'
    | 'limit'
    | 'start'
    | 'period'
    | 'tz'
    | 'carrier_id'
    | 'language'
    | 'state'
    | 'license_states'
    | 'case_id'
  >,
): IUseUsers {
  const [dates, setDates] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    let canceled = false;
    if (params.start !== '' && isValidDateFormat(params.start)) {
      setDataLoading(true);
      listAvailableProvidersByDate(params).then((usersList) => {
        if (!canceled) {
          setDates(usersList.nodes);
          setDataLoading(false);
        }
      });
    }
    return (): void => {
      canceled = true;
    };
  }, [params]);

  const refreshProviders = (): void => {
    if (params.start !== '' && isValidDateFormat(params.start)) {
      setDataLoading(true);
      listAvailableProvidersByDate(params).then((usersList) => {
        setDates(usersList.nodes);
        setDataLoading(false);
      });
    }
  };

  return {
    dates,
    dataLoading,
    refreshProviders,
  };
}
