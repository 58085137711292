import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { displayErrorDetails } from '@brands/Utils/displayError';
import moment from 'moment';
import React, { useMemo } from 'react';
import { BsTrash3 } from 'react-icons/bs';

import { useWindowSize } from '../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../hooks/useReduxHook';
import { checkUserAvailability } from '../../../../../../../services/availability/checkUserAvailability';
import { deleteUserAvailability } from '../../../../../../../services/availability/deleteUserAvailability';
import { ProviderAvailability } from '../../../../../../../services/availability/types/ProviderAvailability';
import { ICase, Status } from '../../../../../../../services/cases/types/ICase';
import { getPrimaryColor } from '../../../../../../../Utils/getPrimaryColor';
import styles from './styles.module.scss';

type GroupedDateOverrides = {
  date: string;
  dateOverrides: ProviderAvailability[];
}[];

type DateOverridesProps = {
  setAddDateModal: React.Dispatch<React.SetStateAction<boolean>>;
  dateOverrides: ProviderAvailability[];
  setDateOverrides: React.Dispatch<React.SetStateAction<ProviderAvailability[]>>;
  setAlertModal: React.Dispatch<
    React.SetStateAction<{
      cases: ICase[];
      kind: 'override' | 'weekly';
      type: 'delete' | 'update' | 'add';
      selectedDates?: Date[];
      isUnavailable?: boolean;
      slots: ProviderAvailability[];
    } | null>
  >;
  requestSending: boolean;
};

const DateOverrides = ({
  setAddDateModal,
  dateOverrides,
  setDateOverrides,
  setAlertModal,
  requestSending,
}: DateOverridesProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();
  const [loading, setLoading] = React.useState(false);

  const groupOverridesByDate = (overrides: ProviderAvailability[]): GroupedDateOverrides => {
    const groupedOverrides: GroupedDateOverrides = [];

    overrides.forEach((override) => {
      const { date } = override;
      if (moment(date).isBefore(moment().format('YYYY-MM-DD'))) {
        return;
      }
      const dateOverride = groupedOverrides.find((group) => group.date === date);

      if (dateOverride) {
        dateOverride.dateOverrides.push(override);
      } else {
        groupedOverrides.push({ date, dateOverrides: [override] });
      }
    });

    groupedOverrides.sort((a, b) => {
      const aDate = moment(a.date);
      const bDate = moment(b.date);
      if (aDate.isBefore(bDate)) {
        return -1;
      }
      if (aDate.isAfter(bDate)) {
        return 1;
      }
      return 0;
    });

    groupedOverrides.forEach((group) => {
      group.dateOverrides.sort((a, b) => {
        const aStartTime = moment(a.start_time, 'h:mmA').format('HH:mm:ss');
        const bStartTime = moment(b.start_time, 'h:mmA').format('HH:mm:ss');
        if (aStartTime < bStartTime) {
          return -1;
        }
        if (aStartTime > bStartTime) {
          return 1;
        }
        return 0;
      });
    });

    return groupedOverrides;
  };

  const groupedOverrides = useMemo(() => groupOverridesByDate(dateOverrides), [dateOverrides]);

  const deleteDateOverride = async (override: ProviderAvailability): Promise<void> => {
    await deleteUserAvailability(userInfo.id, override.id?.toString() || '')
      .then(() => {
        setDateOverrides((prevOverrides) => prevOverrides.filter((prevOverride) => prevOverride.id !== override.id));
      })
      .catch((error: unknown) => {
        displayErrorDetails(error);
      });
  };

  const handleDeleteDateOverride = (overrides: ProviderAvailability[], index: number): void => {
    (async () => {
      setLoading(true);
      let res: ICase[] = [];
      if (
        UserRoleName.Provider === userInfo.role.name &&
        (overrides[index].asynchronous || overrides[index].synchronous)
      ) {
        try {
          res = await checkUserAvailability(userInfo.id, {
            availability_id: overrides[index].id || 0,
          });
        } catch (error: unknown) {
          displayErrorDetails(error);
        }
      }
      if (
        res.length > 0 &&
        !res.every(
          (item) =>
            item.status === Status.Canceled || item.status === Status.Deleted || item.status === Status.Completed,
        )
      ) {
        setAlertModal({
          cases: res,
          kind: 'override',
          type: 'delete',
          slots: [overrides[index]],
        });
      } else {
        await deleteDateOverride(overrides[index]);
      }
      setLoading(false);
    })();
  };

  return (
    <div className={styles.dateOverridesContainer}>
      {screenSize.width >= 1024 && <div className={styles.dateOverridesHeader}>Date Overrides</div>}
      <div className={styles.dateOverridesBody}>
        <div className={styles.dateOverridesBodyContainer}>
          <button
            className={styles.addDateOverrideButton}
            type="button"
            onClick={() => {
              setAddDateModal(true);
            }}
          >
            Add a Date Override
          </button>
          <div className={styles.dateOverrides}>
            {!requestSending ? (
              groupedOverrides.map((date) => (
                <div
                  className={styles.dateOverride}
                  style={{
                    borderBottom: groupedOverrides[groupedOverrides.length - 1] === date ? '1px solid #abafb3' : 'none',
                  }}
                  key={date.date}
                >
                  <div className={styles.dateOverrideDate}>{moment(date.date).format('MMM DD, YYYY')}</div>
                  <div className={styles.dateOverrideTimes}>
                    {date.dateOverrides.length > 0 ? (
                      date.dateOverrides.map((overrides, index) => (
                        <div className={styles.dateOverrideTimeContainer} key={overrides.id}>
                          <div className={styles.dateOverrideTime} key={overrides.id}>
                            <div className={styles.dateOverrideTimeHours}>
                              {overrides.start_time === '12:00am' && overrides.end_time === '12:00am' ? (
                                <span style={{ opacity: '0.6' }}>Unavailable</span>
                              ) : (
                                <>
                                  <span>{overrides.start_time}</span>
                                  <span> - </span>
                                  <span>{overrides.end_time}</span>
                                </>
                              )}
                            </div>
                            {userInfo.role.name === UserRoleName.Provider && (
                              <div className={styles.dateOverrideTypes}>
                                {overrides.synchronous && <span>Synchronous</span>}
                                {overrides.asynchronous && overrides.synchronous && <span>, </span>}
                                {overrides.asynchronous && <span>Asynchronous</span>}
                              </div>
                            )}
                          </div>
                          <div className={styles.dateOverrideActions}>
                            <button
                              type="button"
                              onClick={() => {
                                handleDeleteDateOverride(date.dateOverrides, index);
                              }}
                              disabled={loading}
                              aria-label="Delete Date Override"
                            >
                              <BsTrash3 size={18} color={loading ? 'black' : getPrimaryColor()} />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className={styles.dateOverrideTimesUnavailable}>Unavailable</div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.loadingContainer}>
                <Loading dotted />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateOverrides;
