/* eslint-disable no-nested-ternary */
import Container from '@brands/Components/Container/Container';
import { config } from '@brands/config/config';
import GetStartImg from '@brands/Dashboard/Dashboard/Patient/utils/get_started_design.svg';
import ScheduleImg from '@brands/Dashboard/Dashboard/Patient/utils/schedule_or_start_design.png';
import SendNowImg from '@brands/Dashboard/Dashboard/Patient/utils/send_now_design.svg';
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import SmileScanStars from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_star.svg';
import { IListCases, listAllCases } from '@brands/services/cases/listAllCases';
import { completeMobileHandoff } from '@brands/services/communication/completeMobileHandover';
import { getPricings, Pricings } from '@brands/services/identity/getPricings';
import { getUserAvatar } from '@brands/services/identity/getUserAvatar';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectIsFromHandOff } from '@brands/store/selectors/isFromHandOff';
import { selectShowStateModal } from '@brands/store/selectors/location';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setDependents } from '@brands/store/slices/dependentsSlice';
import { setIsFromHandoff } from '@brands/store/slices/isFromHandOffSlice';
import { setShowStateModal } from '@brands/store/slices/location';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { clearPageState } from '@brands/store/slices/pageStateSlice';
import { FormValues, resetForm, setForm, setSelectedPatient } from '@brands/store/slices/patientFormSlice';
import { setSelectedPatientByAdmin } from '@brands/store/slices/selectedPatientSlice';
import { setVCCaseId } from '@brands/store/slices/videoCallCredentialsSlice';
import { displayErrorDetails } from '@brands/Utils/displayError';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile as mobileDetect } from 'react-device-detect';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { TbAmbulance } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';

import Button from '../../../Components/Button/Button';
import SelectInput from '../../../Components/Inputs/SelectInput/SelectInput';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useOrganizationFlags } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import useWindowSize from '../../../hooks/useWindowSize';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { listDependents } from '../../../services/identity/listDependents';
import { calculateCaseDue } from '../../../Utils/calculateCaseDue';
import { selectCommonCustomStyles } from '../../../Utils/customStyles';
import { DEFAULT_PATIENT_LOCATION_STATE, setCurrentPatientState } from '../../../Utils/localStorage';
import { Option, stateOptions } from '../../../Utils/selectOptions';
import ProfileComponent from '../CareAdvisor/Component/ProfileComponent';
import OpenCaseListModal from '../Components/OpenCaseListModal/OpenCaseListModal';
import EmergencyModal from '../DashboardModals/EmergencyModal';
import StateModal from '../DashboardModals/StateModal/StateModal';
import styles from './styles.module.scss';

const Dashboard = (): JSX.Element => {
  const screenSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const showStateModal = useAppSelector(selectShowStateModal);

  const [emergencyModal, openEmergencyModal] = useState(false);
  const [pricingList, setPricingList] = useState<Pricings[]>([]);
  const [casesIndex, setCasesIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [displayingCases, setDisplayingCases] = useState<ICase[]>([]);
  const currentState: string = useAppSelector(selectCurrentPatientState);
  const isFromHandoffMobile = useAppSelector(selectIsFromHandOff);
  const isMobile = screenSize.width < 768;
  const [state, setState] = useState<SingleValue<Option> | string>(
    currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE,
  );
  const [openCases, setOpenCases] = useState<ICase[]>([]);
  const [openCasesModal, toggleOpenCasesModal] = useState<string | null>(null);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: Number(userInfo.id),
      include_vc_stats: true,
      type: ['video_call_instant', 'video_call_scheduled'],
      include_dependents_cases: true,
    }),
    [userInfo.id],
  );

  const { videoCaseEnabled, asyncCaseEnabled, secondOpinionCaseEnabled } = useOrganizationFlags(state as string);
  const { cases, dataLoading } = useCases(params, true);

  const resetPatientIntakeForm = (): void => {
    dispatch(resetForm());
    localStorage.setItem('selectedPatientId', '');
    dispatch(setIsFromHandoff(false));
  };

  const getOpenCases = async (): Promise<IListCases> => {
    const openCaseParams = {
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: Number(userInfo.id),
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
      ],
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
      ],
      limit: 100,
    };

    const queryStr = await Promise.all(
      Object.keys(openCaseParams).map((key) => {
        const value = openCaseParams[key as keyof typeof openCaseParams];
        if (Array.isArray(value)) {
          return value.map((v) => `${key}=${v}`).join('&');
        }
        return `${key}=${value}`;
      }),
    );

    const openCaseList = await listAllCases(queryStr.join('&'));

    await Promise.all(
      (openCaseList.nodes = openCaseList.nodes.reduce((acc: ICase[], current: ICase) => {
        if (
          (current.type === 'video_call_scheduled' || current.type === 'phone_call_scheduled') &&
          (current.status === 'in_progress_provider' || current.status === 'in_progress_care_advisor')
        ) {
          return acc;
        }
        return [...acc, current];
      }, [])),
    );

    return openCaseList;
  };

  const startPatientForm = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('video_call_scheduled');
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: 'video_call_scheduled',
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', {
      state: {
        caseType: 'video_call_scheduled',
      },
    });
    setIsButtonDisabled(false);
  };

  const startOneReport = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('one_report');
        return;
      }
    }
    setIsButtonDisabled(true);
    const formPayload: FormValues[] = [
      {
        form_question_id: 20,
        value: {
          caseType: 'one_report',
        },
      },
    ];

    dispatch(setForm(formPayload));
    navigate('/select-patient', { state: { caseType: 'one_report' } });
    setIsButtonDisabled(false);
  };

  const startSecondOpinionCase = async (getCases = true): Promise<void> => {
    if (getCases) {
      const { nodes: openCaseList } = await getOpenCases();
      if (openCaseList?.length > 0) {
        setOpenCases(openCaseList);
        toggleOpenCasesModal('second_opinion');
        return;
      }
    }
    setIsButtonDisabled(true);

    const formPayload: FormValues[] = [
      {
        form_question_id: 15,
        value: {
          dental_emergency: 'no',
          sensitive_teeth: 'no',
          second_opinion: 'yes',
          improve_smile: 'no',
          improve_gum_health: 'no',
          grind_teeth_or_snore: 'no',
          something_else: 'no',
        },
      },
      {
        form_question_id: 16,
        value: {
          inPain: 'no',
          pain_description: ['sensitive'],
          painLevel: 'noPain',
          daysInPain: '',
        },
      },
      {
        form_question_id: 20,
        value: {
          caseType: 'second_opinion',
        },
      },
    ];

    dispatch(setForm(formPayload));
    // navigate('/upload-image', { state: { caseType: 'second_opinion' } });
    navigate('/select-patient', { state: { caseType: 'second_opinion' } });
    setIsButtonDisabled(false);
  };

  const getPricingsFn = async (): Promise<void> => {
    setLoading(true);
    try {
      const pricings = await getPricings();
      setPricingList(pricings);
      dispatch(setOrganizationPrices({ currentOrganizationPrices: pricings }));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
    setLoading(false);
  };

  const checkCaseStatus = (thisCase: ICase): boolean => {
    switch (thisCase?.status) {
      case 'new':
        return true;
      case 'accepted_care_advisor':
        return true;
      case 'accepted_provider':
        return true;
      case 'in_progress_provider':
        return true;
      case 'in_progress_care_advisor':
        return true;
      default:
        return false;
    }
  };

  const checkVCDuration = (thisCase: ICase, minDuration: number): boolean => {
    if (thisCase?.patient_vc_stats?.joined_at === null) {
      return false;
    }
    if (thisCase?.patient_vc_stats?.joined_at !== null && thisCase?.patient_vc_stats?.left_at === null) {
      return true;
    }
    const startedAt = new Date(thisCase?.patient_vc_stats?.joined_at ?? 0);
    const endedAt = new Date(thisCase?.patient_vc_stats?.left_at ?? 0);
    const duration = endedAt.getTime() - startedAt.getTime();
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    if (minutes >= minDuration) {
      return true;
    }
    return false;
  };

  const onCloseStateModal = (): void => {
    dispatch(setShowStateModal({ showStateModal: false }));
    setState(currentState[userInfo.id] || DEFAULT_PATIENT_LOCATION_STATE);
  };

  useEffect(() => {
    setState(currentState[userInfo.id]);
  }, [currentState[userInfo.id]]);

  const completeMobileHandoffFn = async (): Promise<void> => {
    try {
      await completeMobileHandoff();
      dispatch(setIsFromHandoff(false));
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  useEffect(() => {
    getPricingsFn();
    resetPatientIntakeForm();
    if ((isMobile || mobileDetect) && isFromHandoffMobile) {
      // Complete Handoff
      completeMobileHandoffFn();
    }
    (async () => {
      if (userInfo.id) {
        dispatch(
          setSelectedPatient({
            value: userInfo.id.toString(),
            label: 'Myself',
          }),
        );
        dispatch(clearPageState());
        dispatch(setSelectedPatientByAdmin({ selectedPatient: userInfo }));
        const listOfDependents = await listDependents(userInfo.id, { include_deleted: true });
        if (listOfDependents.length > 0) {
          dispatch(
            setDependents(
              listOfDependents
                .slice()
                .sort((a, b) => b.id - a.id)
                .map((dependent) => ({
                  value: dependent.id.toString(),
                  label: dependent.full_name,
                  status: dependent.status,
                  image: getUserAvatar(Number(dependent.id)),
                })),
            ),
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (cases) {
      setDisplayingCases((prev) => {
        const newCases = cases.filter(
          (thisCase) =>
            (thisCase.type === 'video_call_instant' || thisCase.type === 'video_call_scheduled') &&
            checkCaseStatus(thisCase) &&
            !checkVCDuration(thisCase, 2) &&
            prev.some((prevCase) => prevCase.id === thisCase.id) === false,
        );
        return [...prev, ...newCases].sort((a, b) => b.id - a.id);
      });
    }
  }, [cases]);

  const renderComingSoon = (): JSX.Element => (
    <div className={styles.comingSoon}>We are not currently able to offer this option in your state</div>
  );

  if (loading) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse={false}
      patientDashboard
      childClassName={styles.dashboard}
      containerStyle={{
        justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
        overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
      }}
    >
      <div className={styles.dashboardContainer}>
        <div className={styles.stateContainer}>
          <p className={styles.yourStateTitle}>Current location for patient</p>
          <SelectInput
            name="state"
            options={stateOptions}
            selectorClass={styles.selector}
            style={selectCommonCustomStyles}
            containerClass={styles.selectorContainer}
            selectedValue={state}
            onChange={(value) => {
              setState(value?.value || DEFAULT_PATIENT_LOCATION_STATE);
              setCurrentPatientState(userInfo.id, value?.value);
              dispatch(setPatientState({ userId: userInfo.id, state: value?.value || DEFAULT_PATIENT_LOCATION_STATE }));
            }}
          />
        </div>
        {displayingCases.length > 0 && (
          <div className={styles.upcomingConsultations}>
            {dataLoading ? (
              <Loading dotted />
            ) : (
              <>
                {displayingCases.length > 1 && (
                  <div className={styles.navigationButtons}>
                    <div
                      className={styles.navigationButton}
                      onClick={() => {
                        if (casesIndex > 0) {
                          setCasesIndex(casesIndex - 1);
                        } else {
                          setCasesIndex(displayingCases.length - 1);
                        }
                      }}
                    >
                      <MdOutlineKeyboardArrowLeft />
                    </div>
                  </div>
                )}
                <div className={styles.upcomingConsultationsContent}>
                  {displayingCases[casesIndex]?.type === 'video_call_instant' && (
                    <>
                      Your live consultation with a dentist is pending. You can enter the waiting room now or we'll
                      notify you when ready
                      <Button
                        className={styles.upcomingConsultationsBtn}
                        type="button"
                        onClick={() => {
                          navigate(`/video-room/${displayingCases[casesIndex]?.id}`, {
                            state: {
                              status: displayingCases[casesIndex].status,
                            },
                          });
                          dispatch(setVCCaseId(displayingCases[casesIndex]?.id.toString()));
                        }}
                      >
                        Waiting Room
                      </Button>
                    </>
                  )}
                  {displayingCases[casesIndex]?.type === 'video_call_scheduled' && (
                    <>
                      You have a scheduled live talk with a dentist{' '}
                      {displayingCases[casesIndex]?.provider && (
                        <span className="mx-2">
                          <ProfileComponent caseRow={displayingCases[casesIndex]} role="Provider" displayRole={false} />
                        </span>
                      )}
                      {displayingCases[casesIndex] &&
                        calculateCaseDue(
                          displayingCases[casesIndex]?.type,
                          displayingCases[casesIndex]?.created_at,
                          displayingCases[casesIndex]?.video_conference_date || '',
                        )}
                      <Button
                        className={`${styles.upcomingConsultationsBtn} ${styles.upcomingConsultationsBtnScheduled}`}
                        type="button"
                        onClick={() => {
                          navigate(`/video-room/${displayingCases[casesIndex]?.id}`, {
                            state: {
                              status: displayingCases[casesIndex].status,
                            },
                          });
                          dispatch(setVCCaseId(displayingCases[casesIndex]?.id.toString()));
                        }}
                      >
                        {screenSize.width >= 768 ? 'Waiting Room' : 'Join'}
                      </Button>
                    </>
                  )}
                </div>
                {displayingCases.length > 1 && (
                  <div className={styles.navigationButtons}>
                    <div
                      className={styles.navigationButton}
                      onClick={() => {
                        if (casesIndex < displayingCases.length - 1) {
                          setCasesIndex(casesIndex + 1);
                        } else {
                          setCasesIndex(0);
                        }
                      }}
                    >
                      <MdOutlineKeyboardArrowRight />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {config.organization.name === 'default' ? (
          <div className={styles.smileScanDiv}>
            <div className={styles.smileScanImages}>
              <img className={styles.smileScanStars} src={SmileScanStars} alt="SmileScanStars" />
              <img className={styles.smileScanMobile} src={SmileScanMobile} alt="SmileScanMobile" />
            </div>
            <div className={styles.smileScanTextDiv}>
              <p className={styles.smileScanTextHeader}>FREE SmileScan</p>
              <span className={styles.smileScanText}>
                Use artificial intelligence to detect possible oral health concerns.
              </span>
            </div>
            <Button onClick={() => navigate('/smile-scan')} className={styles.dashboardBtn} id="smile_scan_get_started">
              Get Started
            </Button>
          </div>
        ) : (
          <div className={styles.titleContainer} style={{ paddingTop: displayingCases.length > 0 ? '0px' : '40px' }}>
            <span>
              THE DENTAL CARE YOU NEED{' '}
              <span className={styles.extraBold}>
                <br />
                WHEN YOU NEED IT
              </span>
            </span>
          </div>
        )}
        <div className={styles.cardsContainer}>
          {pricingList.find(
            (price) => price.case_types[0] === 'video_call_instant' || price.case_types[0] === 'video_call_scheduled',
          ) && (
            <div className={`${styles.dashboardCard} ${styles.first}`}>
              <div className={styles.cardWrap}>
                <div className={styles.flexColumn}>
                  <div className={styles.cardHeader}>
                    <div
                      className={`${styles.cardImage} ${styles.scheduleOrStart}`}
                      style={{
                        width: '114px',
                        height: '99.5px',
                        backgroundImage: `url(${ScheduleImg})`,
                      }}
                    />
                    <div className={styles.cardHeaderContent}>
                      <span className={styles.cardTitle}>Talk LIVE with a Dentist</span>
                      <hr />
                      <div className={styles.cardHeaderPrice} style={{ justifyContent: 'center' }}>
                        {pricingList.find(
                          (price) =>
                            price.case_types[0] === 'video_call_instant' ||
                            price.case_types[0] === 'video_call_scheduled',
                        )?.amount &&
                        pricingList.find(
                          (price) =>
                            price.case_types[0] === 'video_call_instant' ||
                            price.case_types[0] === 'video_call_scheduled',
                        )?.amount !== 0 ? (
                          <span style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={styles.text}>Typically covered by your insurance</span>
                            <span className={`${styles.price} ${styles.selfPay}`}>
                              <span className={styles.dollarSign} style={{ fontSize: '12px', fontWeight: '400' }}>
                                $
                              </span>
                              {(() => {
                                const priceInCents = pricingList.find(
                                  (price) =>
                                    price.case_types[0] === 'video_call_instant' ||
                                    price.case_types[0] === 'video_call_scheduled',
                                )?.amount;

                                if (priceInCents === undefined) {
                                  return null;
                                }

                                const priceInDollars = priceInCents / 100;
                                return priceInDollars === 0
                                  ? 'Free'
                                  : priceInDollars % 1 === 0
                                  ? `${priceInDollars.toFixed(0)} Self Pay`
                                  : `${priceInDollars.toFixed(2)} Self Pay`;
                              })()}
                            </span>
                          </span>
                        ) : (
                          <>
                            <span className={styles.price}>Free</span>
                            {config.organization.name === 'health-plan-san-mateo' && (
                              <span className={styles.text}>Typically covered by your insurance</span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardDetais}>
                    <ul>
                      <li>Schedule a consultation with a Dentistry.One dentist.</li>
                      <hr />
                      <li>
                        Following your consultation the dentist will prepare an Oral Health Report with analysis and
                        recommended next steps.
                      </li>
                      <hr />
                      <li>
                        You will get an alert within 1 business day of your appointment to let you know your Oral Health
                        Report is ready to read or download.
                      </li>
                    </ul>
                  </div>
                  <div className={styles.cardFooter} id="main-talk-live-button">
                    {videoCaseEnabled ? (
                      <Button
                        onClick={() => startPatientForm()}
                        className={styles.dashboardBtn}
                        disabled={isButtonDisabled}
                        id="video_case"
                      >
                        Schedule Now
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pricingList.find((price) => price.case_types[0] === 'one_report') && (
            <div className={`${styles.dashboardCard} ${styles.second}`}>
              <div className={styles.cardWrap}>
                <div className={styles.flexColumn}>
                  <div className="d-flex flex-column">
                    <div className={styles.cardHeader} style={{ backgroundColor: 'rgb(255, 247, 236)' }}>
                      <div
                        className={`${styles.cardImage} ${styles.sendNow}`}
                        style={{ width: '97.13px', height: '120.51px', backgroundImage: `url(${SendNowImg})` }}
                      />
                      <div className={styles.cardHeaderContent}>
                        <span className={styles.cardTitle}>Send Photos for Review</span>
                        <hr />
                        <div className={styles.cardHeaderPrice} style={{ justifyContent: 'center' }}>
                          {pricingList.find((price) => price.case_types[0] === 'one_report')?.amount &&
                          pricingList.find((price) => price.case_types[0] === 'one_report')?.amount !== 0 ? (
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                              <span className={styles.text}>Typically covered by your insurance</span>
                              <span className={`${styles.price} ${styles.selfPay}`}>
                                <span className={styles.dollarSign} style={{ fontSize: '12px', fontWeight: '400' }}>
                                  $
                                </span>
                                {(() => {
                                  const priceInCents = pricingList.find(
                                    (price) => price.case_types[0] === CaseType.one_report,
                                  )?.amount;

                                  if (priceInCents === undefined) {
                                    return null;
                                  }

                                  const priceInDollars = priceInCents / 100;
                                  return priceInDollars === 0
                                    ? 'Free'
                                    : priceInDollars % 1 === 0
                                    ? `${priceInDollars.toFixed(0)} Self Pay`
                                    : `${priceInDollars.toFixed(2)} Self Pay`;
                                })()}
                              </span>
                            </span>
                          ) : (
                            <>
                              <span className={styles.price}>Free</span>
                              {config.organization.name === 'health-plan-san-mateo' && (
                                <span className={styles.text}>Typically covered by your insurance</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardDetais} style={{ padding: '0px 40px' }}>
                    <ul>
                      <li>Upload photos, images, or explanation of your concern.</li>
                      <hr />
                      <li>
                        A dentist will review your information and prepare an Oral Health Report with analysis and
                        recommended next steps.
                      </li>
                      <hr />
                      <li>
                        You will get an alert within 1 business day to let you know your Oral Health Report is ready to
                        read or download.
                      </li>
                    </ul>
                  </div>
                  <div className={styles.cardFooter} id="main-send-photos-button">
                    {asyncCaseEnabled ? (
                      <Button
                        onClick={() => startOneReport()}
                        className={styles.dashboardBtn}
                        disabled={isButtonDisabled}
                        id="async_case"
                      >
                        Send Now
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {pricingList.find((price) => price.case_types[0] === 'second_opinion') && (
            <div className={`${styles.dashboardCard} ${styles.third}`}>
              <div className={styles.cardWrap}>
                <div className={styles.flexColumn}>
                  <div className={styles.cardHeader}>
                    <div
                      className={`${styles.cardImage} ${styles.getStarted}`}
                      style={{ width: '120.83px', height: '100px', backgroundImage: `url(${GetStartImg})` }}
                    />
                    <div className={styles.cardHeaderContent}>
                      <span className={styles.cardTitle}>Get a Second Opinion</span>
                      <hr />
                      <div className={styles.cardHeaderPrice}>
                        {pricingList.find((price) => price.case_types[0] === 'second_opinion')?.amount &&
                        pricingList.find((price) => price.case_types[0] === 'second_opinion')?.amount !== 0 ? (
                          <span className={styles.price}>
                            <span className={styles.dollarSign}>$</span>
                            {(() => {
                              const priceInCents = pricingList.find(
                                (price) => price.case_types[0] === 'second_opinion',
                              )?.amount;

                              if (priceInCents === undefined) {
                                return null;
                              }

                              const priceInDollars = priceInCents / 100;
                              return priceInDollars === 0
                                ? 'Free'
                                : priceInDollars % 1 === 0
                                ? `${priceInDollars.toFixed(0)}`
                                : `${priceInDollars.toFixed(2)}`;
                            })()}
                          </span>
                        ) : (
                          <>
                            <span className={styles.price}>Free</span>
                            {config.organization.name === 'health-plan-san-mateo' && (
                              <span className={styles.text}>Typically covered by your insurance</span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardDetais}>
                    <ul>
                      <li>Upload an existing treatment plan for a dentist’s second opinion.</li>
                      <hr />
                      <li>
                        You will get an alert within 1 business day to let you know your second opinion report is ready
                        to read or download.
                      </li>
                      <hr />
                      <li>As a reminder, second opinions are not covered by insurance.</li>
                    </ul>
                  </div>
                  <div className={styles.cardFooter} id="main-second-opinion-button">
                    {secondOpinionCaseEnabled ? (
                      <Button
                        onClick={(): void => {
                          startSecondOpinionCase();
                        }}
                        disabled={isButtonDisabled}
                        className={styles.dashboardBtn}
                        id="second_opinion_case"
                      >
                        Get Started
                      </Button>
                    ) : (
                      renderComingSoon()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {config.organization.name === 'default' && (
          <div className={styles.smileScanNote}>Connect from any device, no app needed.</div>
        )}
        <div className={styles.cardHeaderCallYou} onClick={() => openEmergencyModal(true)}>
          <TbAmbulance size={20} />
          Do you have an emergency?
        </div>
        <div className={styles.operatingHours}>
          <span className={styles.title}>
            <span>Request a phone consultation</span>
            <span>{isMobile || mobileDetect ? <a href="tel:844-240-1883">844-240-1883</a> : '844-240-1883'}</span>
          </span>
          <span>
            Operating hours: 8 am - 11 pm ET (excluding weekends and bank holidays). If contacted outside of these
            times, a member of our care team will follow-up during business hours.
          </span>
        </div>
      </div>
      {emergencyModal && <EmergencyModal setOpenModal={openEmergencyModal} />}
      <StateModal isOpen={showStateModal} onClose={onCloseStateModal} />
      <OpenCaseListModal
        isOpen={openCasesModal}
        toggleModal={toggleOpenCasesModal}
        openCases={openCases}
        startOneReport={startOneReport}
        startPatientForm={startPatientForm}
        startSecondOpinionCase={startSecondOpinionCase}
      />
    </Container>
  );
};
export default React.memo(Dashboard);
