import { client } from '../client';
import { SmileScanReportMedia } from '../cases/types/SmileScanReport';

interface SmileScanReport {
    id: number;
    patient_id: number;
    case_id: number;
    overall_score: number;
    cavity_score: number;
    plaque_buildup_score: number;
    root_recession_score: number;
    swollen_gums_score: number;
    gaps_detected: boolean;
    toothlens_json: string;
    created_at: string;
    updated_at: string;
    media: SmileScanReportMedia[];
    percentage_change_with_previous_scan: number;
    previous_scan_id: number;
}

export const getSmileScanReports = async (patientId: string): Promise<SmileScanReport[]> => {
  const { data } = await client.get<SmileScanReport[]>(`/toothlens/${patientId}`);

  return data;
};