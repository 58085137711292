import { client } from '../client';

interface Payload {
  reset_password_hash: string;
  new_password: string;
}

export interface ConfirmResetPasswordResponse {
  response: any;
}

export const confirmResetPassword = async (payload: Payload): Promise<ConfirmResetPasswordResponse> => {
  const { data } = await client.post<ConfirmResetPasswordResponse>(`/auth/confirm_reset_password`, payload);

  return data;
};
