/* eslint-disable max-len */
import { sendResetEmail } from '@brands/services/auth/sendResetEmail';
import { yupResolver } from '@hookform/resolvers/yup';
// import { Auth } from 'aws-amplify';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import * as Yup from 'yup';

import closeModalIcon from '../../assets/icons/close.svg';
import { customStylesForgotPassword } from '../../Utils/customStyles';
import { displayErrorDetails } from '../../Utils/displayError';
// import { isValidPhoneNumber } from '../../Utils/validation';
import styles from './styles.module.scss';

interface ForgotPasswordModalStepOneProps {
  forgotPasswordStep: number;
  setForgotPasswordStep: (num: number) => void;
  setCredentials: (credentials: ForgotPasswordCredentials) => void;
}

type ForgotPasswordCredentials = {
  username: string;
  code: string;
  new_password: string;
};

type ForgotPasswordModalStepOneSubmitForm = {
  email?: string;
  phoneNumber?: string;
};

const ForgotPasswordModalStepOne: FC<ForgotPasswordModalStepOneProps> = ({
  forgotPasswordStep,
  setForgotPasswordStep,
  setCredentials,
}) => {
  const [loading, setLoading] = useState(false);
  const closeModal = (): void => {
    setForgotPasswordStep(0);
    setCredentials({
      username: '',
      code: '',
      new_password: '',
    });
  };

  useEffect(() => {
    if (forgotPasswordStep === 1) {
      document.body.classList.add('modal-open');
    } else if (document.body.classList.contains('modal-open') && forgotPasswordStep === 0) {
      document.body.classList.remove('modal-open');
    }
  }, [forgotPasswordStep]);

  const onSubmit = async (data: ForgotPasswordModalStepOneSubmitForm): Promise<void> => {
    try {
      setLoading(true);
      await sendResetEmail({ email: data.email?.toLowerCase() as string });
      // await Auth.forgotPassword(
      //   (isValidPhoneNumber(data?.phoneNumber || '') ? `${data?.phoneNumber}` : data.email?.toLowerCase()) as string,
      // );
      // setCredentials({
      //   username: (isValidPhoneNumber(data?.phoneNumber || '')
      //     ? `+1${data?.phoneNumber}`
      //     : data.email?.toLowerCase()) as string,
      //   code: '',
      //   new_password: '',
      // });
      // setForgotPasswordStep(forgotPasswordStep + 1);
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      closeModal();
      setLoading(false);
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .test('test-name', 'Enter Valid Email', (value: string | undefined) => {
        const emailRegex =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const isValidEmail = emailRegex.test(value as string);
        const isValidPhone = phoneRegex.test(value as string);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordModalStepOneSubmitForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  return (
    <div className={styles.container}>
      <Modal
        isOpen={forgotPasswordStep === 1}
        onRequestClose={closeModal}
        style={customStylesForgotPassword}
        contentLabel="Modal"
        ariaHideApp={false}
        className={`${styles.wrapper} ${styles.forgotPasswordModal}`}
        shouldCloseOnOverlayClick={false}
      >
        <button
          type="button"
          className={styles.modalClose}
          aria-label="Close modal"
          onClick={closeModal}
          style={{ backgroundImage: `url(${closeModalIcon})` }}
        />
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <span className={`${styles.formTitle} p-b-26`}>
            <span className={styles.formTitleForgot}>Forgot Password</span>
            <div className={`text-center ${styles.formTitleLink} p-t-115`}>
              <span className={`${styles.txt1} txt1`}>Reset your password in three quick steps</span>
            </div>
          </span>
          <div className={styles.inputWrapper} data-validate="Valid email is: a@b.c">
            <input
              {...register('email')}
              className={`${styles.input100} ${errors.email ? 'is-invalid' : ''}`}
              type="text"
              name="email"
              placeholder="Email"
            />
            <span className={styles.focusInput100} />
            <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.email?.message}</div>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <div className={styles.buttonBG} />
              <button type="submit" className={styles.formBtn} disabled={loading}>
                Reset Password
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

Modal.setAppElement('#root');

export default ForgotPasswordModalStepOne;
