/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { displayErrorDetails } from '@brands/Utils/displayError';
import { getSecondary } from '@brands/Utils/getPrimaryColor';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { BsFilter } from 'react-icons/bs';
import { CgFileDocument } from 'react-icons/cg';
import { FaCheckCircle } from 'react-icons/fa';
import { HiChevronDown, HiChevronUp, HiOutlineVideoCamera } from 'react-icons/hi';
import { TiMessages } from 'react-icons/ti';
import Select, { CSSObjectWithLabel, MultiValue } from 'react-select';

import Button from '../../../../Components/Button/Button';
import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import SelectInput from '../../../../Components/Inputs/SelectInput/SelectInput';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import Radio from '../../../../Components/Radio/Radio';
import { useInsuranceCarriers, useWindowSize } from '../../../../hooks';
import useDebounce from '../../../../hooks/useDebounce';
import useProviders from '../../../../hooks/useProviders';
import { IAssignProviderCase } from '../../../../services/cases/assignProvider';
import { CaseType, ICase } from '../../../../services/cases/types/ICase';
import { getUserAvatar } from '../../../../services/identity/getUserAvatar';
import { listAllLanguages } from '../../../../services/identity/listAllLanguages';
import { ProviderFilterAvailability } from '../../../../services/identity/listAvailableProvidersByDate';
import { License, UserProfile } from '../../../../services/identity/types/UserProfile';
import {
  multiSelectCommonCustomStyles,
  selectCommonCustomStyles,
  selectSortByStyles,
} from '../../../../Utils/customStyles';
import { getFriendlyName } from '../../../../Utils/getFriendlyName';
import { sortData, SortKeys, SortOrder } from '../../../../Utils/providerSortData';
import { Option, OptionLanguage, sortByOptions, specialtyOptions, stateOptions } from '../../../../Utils/selectOptions';
import phone from '../../../Dashboard/CareAdvisor/assets/phone.svg';
import ProviderProfileCardModal from '../../../ProfileCards/Dentist/ProfileCards';
import video from '../../assets/icons/liveVideo.svg';
import report from '../../assets/icons/oralHealthReport.svg';
import sVideo from '../../assets/icons/scheduledVideo.svg';
import { OralHealthReportSubmit, ProviderFilter } from '../../assets/types';
import styles from './providerList.module.scss';

interface IModal {
  openModal: (arg0: boolean) => void;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  consultationForm: OralHealthReportSubmit;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  saveCaseNotes: (internalCasesNotes: string) => Promise<void>;
  assignToProvider: (case_id: number, body: IAssignProviderCase) => Promise<ICase | null>;
}

const MAX_ITEMS = 30;
const ProviderListTable = ({
  openModal,
  thisCase,
  setCase,
  consultationForm,
  setLoading,
  saveCaseNotes,
  assignToProvider,
}: IModal): JSX.Element => {
  const screenSize = useWindowSize();
  const [targetProvider, setTargetProvider] = useState<UserProfile | null>(null);
  const [isMore, setIsMore] = useState(false);
  const [isMoreState, setIsMoreState] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<number>();
  const [languageOptions, setLanguageOptions] = useState<OptionLanguage[]>([]);
  const [providersNetwork, setProvidersNetwork] = useState<string>(
    thisCase.insurance?.insurance_membership?.insurance_carrier_id ? 'InOnly' : '',
  );
  const insuranceCarriers = useInsuranceCarriers();
  const insuranceCarrierOptions = useMemo(() => {
    return insuranceCarriers.map((insuranceCarrier) => ({
      value: insuranceCarrier.id,
      label: insuranceCarrier.name,
    }));
  }, [insuranceCarriers]);
  const [filters, setFilters] = useState<ProviderFilter>({
    availability: [
      thisCase.type === 'video_call_instant' ||
      thisCase.type === 'video_call_scheduled' ||
      thisCase.type === CaseType.phone_call_scheduled
        ? 'CaseType.video_call_instant'
        : 'CaseType.one_report',
    ],
    synchronous:
      thisCase.type === 'video_call_instant' ||
      thisCase.type === 'video_call_scheduled' ||
      thisCase.type === CaseType.phone_call_scheduled,
    asynchronous: thisCase.type === 'one_report' || thisCase.type === 'second_opinion',
    license_states: [stateOptions.find((state) => state.value === thisCase.patient_current_state)!],
  });

  const toggle = (id: number): void => {
    setIsMore(!isMore);
    setSelectedTarget(id);
  };

  const toggleState = (id: number): void => {
    setIsMoreState(!isMoreState);
    setSelectedTarget(id);
  };

  const fetchLanguageOptions = async (): Promise<void> => {
    const data = await listAllLanguages();
    const transformedData = data.map((language) => ({
      value: language.locale,
      label: language.name,
      id: language.id,
    }));
    setLanguageOptions(transformedData);
  };

  useEffect(() => {
    setFilters({
      ...filters,
      ...(thisCase.insurance?.insurance_membership?.insurance_carrier?.name && {
        carrier_id:
          insuranceCarrierOptions.find(
            (plan) => plan.value === thisCase?.insurance?.insurance_membership?.insurance_carrier?.id || 0,
          )?.value || 0,
      }),
    });
  }, [insuranceCarrierOptions]);

  useEffect(() => {
    fetchLanguageOptions();
  }, []);

  const getCurrentTimezone = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  }, []);

  const observer = useRef<IntersectionObserver>();
  const lastProviderItemRef = useRef<HTMLTableRowElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const params = useMemo(() => {
    const videoConferenceDateUTC = moment.utc(thisCase.video_conference_date);
    const currentLocalTime = moment().tz(browserTimeZone);
    const utcTime = currentLocalTime.utc();
    return {
      date:
        thisCase.type === CaseType.video_call_scheduled || thisCase.type === CaseType.phone_call_scheduled
          ? videoConferenceDateUTC.isBefore(utcTime)
            ? moment().format('YYYY-MM-DD')
            : videoConferenceDateUTC.tz(browserTimeZone).format('YYYY-MM-DD')
          : moment(new Date()).format('YYYY-MM-DD'),
      tz: getCurrentTimezone,
      specialty: filters.specialty ? filters.specialty.map((item) => item.value) : undefined,
      language: filters.language ? filters.language.map((item) => item.label) : undefined,
      carrier_id: filters.carrier_id ? Number(filters.carrier_id) : undefined,
      license_states: filters.license_states
        ? filters.license_states.map((item) => item.label.split(' - ')[0])
        : undefined,
      ...(filters.asynchronous ? { asynchronous: filters.asynchronous } : { asynchronous: false }),
      ...(filters.synchronous ? { synchronous: filters.synchronous } : { synchronous: false }),
      case_id: Number(thisCase.id),
    };
  }, [filters]);

  const debouncedParams = useDebounce(params, 500);
  const { providers, loadNextPage, dataLoading } = useProviders(debouncedParams);
  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  const assignProvider = async (selectedProvider: number): Promise<void> => {
    setLoading(true);
    try {
      if (consultationForm.caseNotes !== '') await saveCaseNotes(consultationForm.caseNotes);
      localStorage.removeItem('notes');
      if (thisCase) {
        setLoading(true);
        const updatedCase = await assignToProvider(thisCase.id, { provider_id: selectedProvider });
        if (updatedCase) {
          setCase({
            ...thisCase,
            status: updatedCase.status,
            provider: updatedCase.provider,
            provider_id: updatedCase.provider_id,
            updated_at: updatedCase.updated_at,
          });
        }
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (thisCase) {
      const newFilters = { ...filters };
      if (thisCase.type === 'one_report') {
        newFilters.availability = ['CaseType.one_report'];
        newFilters.asynchronous = true;
        newFilters.synchronous = false;
      }

      setFilters(newFilters);
    }
  }, [thisCase]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastProviderItemRef.current) observer.current.observe(lastProviderItemRef.current);
  }, [providers]);

  const getRenderedItems = (licenseArray: License[], target: number): string => {
    if (licenseArray.length > 0) {
      const licenseNames = licenseArray
        .flatMap((license) => license.insurance_carriers?.flatMap((carrier) => carrier.insurance_carrier?.name))
        .join(', ');

      if (isMore && target === selectedTarget) {
        return licenseNames;
      }

      // Add three dots if the renderedItems length is greater than 30
      return licenseNames.slice(0, MAX_ITEMS).concat(licenseNames.length > MAX_ITEMS ? '...' : '');
    }
    return '';
  };

  const getRenderedStateItems = (licenseArray: License[], target: number): string => {
    if (licenseArray.length > 0) {
      const todaysDate = moment().format('YYYY-MM-DD');
      const filteredLicenseName = licenseArray.filter(
        (license) => license.expiry_date && moment(license.expiry_date).isAfter(todaysDate),
      );
      const licenseNames = filteredLicenseName.map((license) => license.state).join(', ');

      if (isMoreState && target === selectedTarget) {
        return licenseNames;
      }

      // Add three dots if the renderedItems length is greater than 30
      return licenseNames.slice(0, MAX_ITEMS).concat(licenseNames.length > MAX_ITEMS ? '...' : '');
    }
    return '';
  };

  const isMobile = screenSize.width < 768;
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const headers: { key: SortKeys; label: string; isSorted: boolean }[] = [
    { key: 'id', label: 'ID', isSorted: false },
    { key: 'full_name', label: 'Name', isSorted: false },
    { key: 'license_states', label: 'License State', isSorted: false },
    { key: 'specialty', label: 'Specialty', isSorted: false },
    { key: 'plan', label: 'Insurance', isSorted: false },
    { key: 'availability', label: 'Availability', isSorted: false },
    { key: 'languages', label: 'Language', isSorted: false },
  ];
  const [headersState, setHeadersState] = useState(headers);
  const [sortKey, setSortKey] = useState<SortKeys>('availability');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  const sortedData = useCallback(() => {
    return sortData({ tableData: providers, sortKey, reverse: sortOrder === 'desc' });
  }, [filters.availability, providers, sortKey, sortOrder]);
  const changeSort = (key: SortKeys): void => {
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });

    setHeadersState(updatedHeadersState);
  };

  const [selectedProvider, isSelectedProvider] = useState<number>(0);

  const handleSelectedProvider = (target: number): void => {
    if (selectedProvider === target) {
      isSelectedProvider(0);
    } else {
      isSelectedProvider(target);
    }
  };

  useEffect(() => {
    if (selectedProvider && !providers.find((provider) => provider.id === selectedProvider)) {
      // The selected provider is invalid or doesn't exist in the current data.
      // Set the selected provider to the ID of the first provider in the list.
      isSelectedProvider(providers[0]?.id || 0);
    }
  }, [providers]);

  const selectSortByStylesForPatient = {
    ...selectSortByStyles,
    valueContainer: (provided: any) => ({
      ...provided,
      ...selectSortByStyles.valueContainer,
      height: 'auto',
      padding: '0',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      width: '100%',
      paddingTop: '0px',
      paddingBottom: '0px',
      left: '0',
      right: '0',
      top: '50px',
      zIndex: '9999',
      borderColor: getSecondary(),
    }),
  };

  const renderFilters = (): JSX.Element => {
    return (
      <>
        <div className={styles.filterContainer}>
          <span className={styles.title}>Filter</span>
          <div className={styles.itemsContainer}>
            <div className={styles.filterItem}>
              <MultiselectInput
                name="license_states"
                placeholder="State"
                options={stateOptions as { value: string; label: string }[]}
                selectorClass={styles.selector}
                style={multiSelectCommonCustomStyles}
                containerClass={styles.filterItemSelector}
                selectedValue={filters.license_states}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    license_states: value as MultiValue<Option>,
                  });
                }}
              />
            </div>
            <div className={styles.filterItem}>
              <MultiselectInput
                name="specialty"
                placeholder="Specialties"
                style={multiSelectCommonCustomStyles}
                selectorClass={styles.selector}
                containerClass={styles.filterItemSelector}
                options={specialtyOptions as { value: string; label: string }[]}
                selectedValue={filters.specialty}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    specialty: value as MultiValue<Option>,
                  });
                }}
              />
            </div>
            <div className={styles.filterItem}>
              <SelectInput
                name="plan"
                placeholder="Insurance"
                isClearable
                selectorClass={styles.selector}
                containerClass={styles.filterItemSelector}
                style={selectCommonCustomStyles}
                options={insuranceCarrierOptions}
                selectedValue={filters.carrier_id}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    carrier_id: value?.value as number,
                  });
                }}
              />
            </div>
            <div className={styles.filterItem}>
              <MultiselectInput
                name="language"
                placeholder="Languages"
                options={languageOptions}
                selectorClass={styles.selector}
                style={multiSelectCommonCustomStyles}
                containerClass={styles.filterItemSelector}
                selectedValue={filters.language}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    language: value as MultiValue<Option>,
                  });
                }}
              />
            </div>
            <div className={cn(styles.filterItem, styles.availability)}>
              <span className={styles.title}>Availability</span>
              <input
                className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                type="checkbox"
                name="video_call_instant"
                id="video_call_instant"
                value="CaseType.video_call_instant"
                checked={filters.availability.includes('CaseType.video_call_instant')}
                onChange={(e) => {
                  const { value } = e.target;
                  if (e.target.checked) {
                    setFilters({
                      ...filters,
                      availability: [...filters.availability, value as ProviderFilterAvailability],
                      synchronous: true,
                    });
                  } else {
                    setFilters({
                      ...filters,
                      availability: filters.availability.filter((item) => item !== value),
                      synchronous: false,
                    });
                  }
                }}
              />
              <label className={styles.label} htmlFor="video_call_instant">
                Sync
              </label>
              <input
                className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
                type="checkbox"
                name="one_report"
                id="one_report"
                value="CaseType.one_report"
                checked={filters.availability.includes('CaseType.one_report')}
                onChange={(e) => {
                  const { value } = e.target;
                  if (e.target.checked) {
                    setFilters({
                      ...filters,
                      availability: [...filters.availability, value as ProviderFilterAvailability],
                      asynchronous: true,
                    });
                  } else {
                    setFilters({
                      ...filters,
                      availability: filters.availability.filter((item) => item !== value),
                      asynchronous: false,
                    });
                  }
                }}
              />
              <label className={styles.label} htmlFor="one_report">
                Async
              </label>
            </div>
          </div>
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.title}>Insurance Filter</span>
          <div className={styles.itemsContainer}>
            <div className={styles.filterItem} style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
              <span>
                <Radio
                  checked={
                    filters.carrier_id === undefined &&
                    filters.license_states !== undefined &&
                    filters.license_states.length > 0
                  }
                  id="inAndOut"
                  name="InAndOut"
                  onChange={() => {
                    setProvidersNetwork('InAndOut');
                    setFilters({
                      ...filters,
                      license_states: [stateOptions.find((state) => state.value === thisCase.patient_current_state)!],
                      carrier_id: undefined,
                    });
                  }}
                  text="In and Out of Network Providers"
                  value="InAndOut"
                />
              </span>
              <span>
                <Radio
                  checked={
                    providersNetwork === 'InOnly' &&
                    thisCase.insurance?.insurance_membership?.insurance_carrier?.id === filters.carrier_id &&
                    filters.license_states !== undefined &&
                    filters.license_states?.length > 0
                  }
                  id="inOnly"
                  name="InOnly"
                  onChange={() => {
                    setProvidersNetwork('InOnly');
                    setFilters({
                      ...filters,
                      license_states: [stateOptions.find((state) => state.value === thisCase.patient_current_state)!],
                      carrier_id: insuranceCarrierOptions.find(
                        (plan) =>
                          +plan.value === Number(thisCase.insurance?.insurance_membership?.insurance_carrier?.id) || 0,
                      )?.value,
                    });
                  }}
                  text="In Network Only"
                  value="InOnly"
                />
              </span>
              <span>
                <Radio
                  checked={filters.license_states === undefined || filters.license_states?.length === 0}
                  id="allStates"
                  name="AllStates"
                  onChange={() => {
                    setProvidersNetwork('allStates');
                    setFilters({
                      ...filters,
                      license_states: undefined,
                      carrier_id: insuranceCarrierOptions.find(
                        (plan) =>
                          +plan.value === Number(thisCase.insurance?.insurance_membership?.insurance_carrier?.id) || 0,
                      )?.value,
                    });
                  }}
                  text="All States"
                  value="AllStates"
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMobileFilters = (): JSX.Element => {
    const trigger = (
      <div className={styles.trigger}>
        <div className={styles.leftContainer}>
          Filter
          <BsFilter className={styles.triggerIcon} />
        </div>
        <div className={styles.sortBy}>
          <span>Sort by</span>
          <div className={`${styles.wrapInput100}`} data-validate="Choose Sort by">
            <Select
              className="input100 p-0 select"
              styles={selectSortByStylesForPatient}
              placeholder={sortByOptions[0].label}
              onChange={(value) => changeSort(value?.value as SortKeys)}
              menuPortalTarget={document.body}
              options={sortByOptions}
            />
          </div>
          <span className={styles.sortIndicator}>{sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}</span>
        </div>
      </div>
    );
    return (
      <Collapsible
        trigger={trigger}
        triggerOpenedClassName={styles.collapsibleTrigger}
        contentOuterClassName={styles.collapsibleFilter}
      >
        <div className={styles.filterItem}>
          <MultiselectInput
            name="license_states"
            placeholder="State"
            options={stateOptions as { value: string; label: string }[]}
            selectorClass={styles.selector}
            style={multiSelectCommonCustomStyles}
            containerClass={styles.filterItemSelector}
            selectedValue={filters.license_states}
            onChange={(value) => {
              setFilters({
                ...filters,
                license_states: value as MultiValue<Option>,
              });
            }}
          />
        </div>
        <div className={styles.filterItem}>
          <MultiselectInput
            name="specialty"
            placeholder="Specialties"
            style={multiSelectCommonCustomStyles}
            selectorClass={styles.selector}
            containerClass={styles.filterItemSelector}
            options={specialtyOptions as { value: string; label: string }[]}
            selectedValue={filters.specialty}
            onChange={(value) => {
              setFilters({
                ...filters,
                specialty: value as MultiValue<Option>,
              });
            }}
          />
        </div>
        <div className={styles.filterItem}>
          <SelectInput
            name="plan"
            placeholder="Insurance"
            isClearable
            selectorClass={styles.selector}
            containerClass={styles.filterItemSelector}
            style={selectCommonCustomStyles}
            options={insuranceCarrierOptions}
            selectedValue={filters.carrier_id}
            onChange={(value) => {
              setFilters({
                ...filters,
                carrier_id: value?.value as number,
              });
            }}
          />
        </div>
        <div className={styles.filterItem}>
          <MultiselectInput
            name="language"
            placeholder="Languages"
            options={languageOptions}
            selectorClass={styles.selector}
            style={multiSelectCommonCustomStyles}
            containerClass={styles.filterItemSelector}
            selectedValue={filters.language}
            onChange={(value) => {
              setFilters({
                ...filters,
                language: value as MultiValue<Option>,
              });
            }}
          />
        </div>
        <div className={cn(styles.filterItem, styles.availability)}>
          <span className={styles.title}>Availability</span>
          <input
            className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
            type="checkbox"
            name="video_call_instant"
            id="video_call_instant"
            value="CaseType.video_call_instant"
            checked={filters.availability.includes('CaseType.video_call_instant')}
            onChange={(e) => {
              const { value } = e.target;
              if (e.target.checked) {
                setFilters({
                  ...filters,
                  availability: [...filters.availability, value as ProviderFilterAvailability],
                  synchronous: true,
                });
              } else {
                setFilters({
                  ...filters,
                  availability: filters.availability.filter((item) => item !== value),
                  synchronous: false,
                });
              }
            }}
          />
          <label className={styles.label} htmlFor="video_call_instant">
            Sync
          </label>
          <input
            className={`form-check-input ${styles.checkInput} mt-0 ${styles.radioBtn}`}
            type="checkbox"
            name="one_report"
            id="one_report"
            value="CaseType.one_report"
            checked={filters.availability.includes('CaseType.one_report')}
            onChange={(e) => {
              const { value } = e.target;
              if (e.target.checked) {
                setFilters({
                  ...filters,
                  availability: [...filters.availability, value as ProviderFilterAvailability],
                  asynchronous: true,
                });
              } else {
                setFilters({
                  ...filters,
                  availability: filters.availability.filter((item) => item !== value),
                  asynchronous: false,
                });
              }
            }}
          />
          <label className={styles.label} htmlFor="one_report">
            Async
          </label>
        </div>
        <div className={cn(styles.filterItem, styles.insurance)}>
          <span className={styles.title}>Insurance Filter</span>
          <div className={styles.itemsContainer}>
            <div className={styles.filterItem} style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
              <span>
                <Radio
                  checked={
                    filters.carrier_id === undefined &&
                    filters.license_states !== undefined &&
                    filters.license_states.length > 0
                  }
                  id="inAndOut"
                  name="InAndOut"
                  onChange={() => {
                    setProvidersNetwork('InAndOut');
                    setFilters({
                      ...filters,
                      license_states: [stateOptions.find((state) => state.value === thisCase.patient_current_state)!],
                      carrier_id: undefined,
                    });
                  }}
                  text="In and Out of Network Providers"
                  value="InAndOut"
                />
              </span>
              <span>
                <Radio
                  checked={
                    providersNetwork === 'InOnly' &&
                    thisCase.insurance?.insurance_membership?.insurance_carrier?.id === filters.carrier_id &&
                    filters.license_states !== undefined &&
                    filters.license_states?.length > 0
                  }
                  id="inOnly"
                  name="InOnly"
                  onChange={() => {
                    setProvidersNetwork('InOnly');
                    setFilters({
                      ...filters,
                      license_states: [stateOptions.find((state) => state.value === thisCase.patient_current_state)!],
                      carrier_id: insuranceCarrierOptions.find(
                        (plan) =>
                          +plan.value === Number(thisCase.insurance?.insurance_membership?.insurance_carrier?.id) || 0,
                      )?.value,
                    });
                  }}
                  text="In Network Only"
                  value="InOnly"
                />
              </span>
              <span>
                <Radio
                  checked={filters.license_states === undefined || filters.license_states?.length === 0}
                  id="allStates"
                  name="AllStates"
                  onChange={() => {
                    setProvidersNetwork('allStates');
                    setFilters({
                      ...filters,
                      license_states: undefined,
                      carrier_id: insuranceCarrierOptions.find(
                        (plan) =>
                          +plan.value === Number(thisCase.insurance?.insurance_membership?.insurance_carrier?.id) || 0,
                      )?.value,
                    });
                  }}
                  text="All States"
                  value="AllStates"
                />
              </span>
            </div>
          </div>
        </div>
      </Collapsible>
    );
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.providerListContainer}>
          <div>
            <div className={`row ${styles.providerListHeaderPatient}`}>
              <span>Patient Info</span>
              <div className={styles.patientInfo}>
                <span className={styles.container}>
                  <div className={styles.patient}>
                    <span>
                      <img
                        alt="Avatar"
                        onError={(event: any) => {
                          const img = event.target;
                          img.src =
                            'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                          img.onError = null;
                        }}
                        className={styles.patientImage}
                        src={getUserAvatar(Number(thisCase?.patient.id))}
                      />
                    </span>
                    <div className={styles.patientDiv}>
                      <span className={styles.patientName}>{thisCase?.patient.full_name}</span>
                    </div>
                  </div>
                </span>
                <span className={styles.container}>
                  <span>Type: </span>
                  <div className={styles.patientCaseType}>
                    <span className={styles.editCase}>
                      {thisCase?.type === 'messaging' && <TiMessages />}
                      {thisCase?.type === 'video_call_instant' && (
                        <img className={styles.video} src={video} alt="video" />
                      )}
                      {thisCase?.type === 'video_call_scheduled' && (
                        <img className={styles.sVideo} src={sVideo} alt="video" />
                      )}
                      {thisCase.type === CaseType.phone_call_scheduled && (
                        <img className={styles.sVideo} src={phone} alt="Phone" />
                      )}
                      {thisCase?.type === 'one_report' && <img className={styles.cReport} src={report} alt="video" />}
                      <p>{getFriendlyName(thisCase.type)}</p>
                    </span>
                  </div>
                </span>
                <span className={styles.container}>
                  <span>Location:</span>
                  <p>{thisCase?.patient_current_state}</p>
                </span>
                <span className={styles.container}>
                  <span>Insurance:</span>
                  <p>{thisCase?.insurance?.insurance_membership?.insurance_carrier?.name}</p>
                </span>
                <span className={styles.container}>
                  <span>Language(s):</span>
                  <p>
                    {thisCase?.patient?.basic_info?.languages
                      ?.map((language) => typeof language !== 'string' && language.name)
                      .join(', ')}
                  </p>
                </span>
                <span className={styles.container}>
                  <span>Paid With:</span>
                  <p>
                    {thisCase?.organization?.payment_options.length === 0 || thisCase?.pricing?.amount === 0
                      ? 'N/A'
                      : thisCase?.organization?.payment_options.includes('Insurance') && thisCase?.insurance?.id
                      ? 'Insurance'
                      : 'Credit Card'}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.mobileStickyHeader}>
            <div className={`row ${styles.providerListHeader}`}>
              <span>Providers</span>
              <div className={styles.btnGroup}>
                <Button
                  className={styles.btn}
                  onClick={() => {
                    openModal(false);
                    assignProvider(selectedProvider);
                  }}
                  disabled={selectedProvider === 0}
                >
                  Assign Provider
                </Button>
                <button className={styles.cBtn} type="button" onClick={() => openModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {renderFilters()}
          <div className={styles.providerListScroll}>
            <div className={styles.providerListScrollInner}>
              {isMobile && (
                <>
                  <div className={styles.mobileHeader}>
                    <p>Providers</p>
                  </div>
                  {renderMobileFilters()}
                </>
              )}
              <table className={styles.providerTable}>
                <thead className={styles.providerListTableHeader}>
                  <tr>
                    {headersState.map((row) => {
                      return (
                        <th key={row.key} onClick={() => changeSort(row.key)}>
                          {row.label}
                          {row.isSorted && sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                        </th>
                      );
                    })}
                    <th aria-label="Empty field"> </th>
                  </tr>
                </thead>
                <tbody>
                  {!dataLoading ? (
                    sortedData()
                      .filter((data) => data.user?.id !== thisCase.provider_id)
                      .map((p) => {
                        const provider = p.user;
                        return (
                          <tr
                            key={provider.id}
                            className={`${styles.providerListTableBody} ${
                              selectedProvider === provider.id ? styles.selectedClass : styles.notSelectedClass
                            }`}
                            onClick={() => handleSelectedProvider(provider.id)}
                          >
                            <td style={{ cursor: 'pointer' }}>
                              <div
                                className={`col-md-2 ${styles.providerInfo}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTargetProvider(provider);
                                  setProviderProfileCardModal(true);
                                }}
                              >
                                <img
                                  alt="Avatar"
                                  onError={(event: any) => {
                                    event.target.src =
                                      'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                                    event.target.onError = null;
                                  }}
                                  className={styles.providerImage}
                                  src={getUserAvatar(provider.id)}
                                />
                                <div className={styles.mobileInfo}>
                                  <span>
                                    {provider.full_name}
                                    {provider.provider_profile?.credentials ? ', ' : ''}
                                    {provider.provider_profile?.credentials || ''}
                                  </span>
                                  {isMobile && (
                                    <span className={cn(styles.mobileState, 'text-wrap', 'd-flex', 'flex-wrap')}>
                                      {getRenderedStateItems(provider.provider_profile?.licenses || [], provider.id)}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            {!isMobile && (
                              <td className="col-md-1">
                                <span className={styles.mobileState}>
                                  {provider.provider_profile?.licenses &&
                                    getRenderedStateItems(provider.provider_profile?.licenses, provider.id)}
                                  <button
                                    type="button"
                                    className={styles.more}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleState(provider.id);
                                    }}
                                  >
                                    {provider.provider_profile?.licenses &&
                                    getRenderedStateItems(provider.provider_profile?.licenses, provider.id).length >=
                                      MAX_ITEMS ? (
                                      isMoreState && selectedTarget === provider.id ? (
                                        <>
                                          less
                                          <AiFillCaretUp />
                                        </>
                                      ) : (
                                        <>
                                          more
                                          <AiFillCaretDown />
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </button>
                                </span>
                              </td>
                            )}
                            <td className={styles.providerAvailabilityRow}>
                              {isMobile && <p className={styles.mobileHeaderText}>Specialty</p>}
                              <span>
                                {provider.provider_profile?.primary_specialty
                                  ?.map((specialty) => specialty)
                                  .join(', ') || ''}
                              </span>
                            </td>
                            <td className={styles.providerAvailabilityRow}>
                              <div className={`${styles.insurance}`}>
                                {isMobile && <p className={styles.mobileHeaderText}>Insurance</p>}
                                <span className="text-wrap">
                                  {provider.provider_profile?.licenses &&
                                    getRenderedItems(provider.provider_profile?.licenses, provider.id)}
                                  <button
                                    type="button"
                                    className={styles.more}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggle(provider.id);
                                    }}
                                  >
                                    {provider.provider_profile?.licenses &&
                                    getRenderedItems(provider.provider_profile?.licenses, provider.id).length >=
                                      MAX_ITEMS ? (
                                      isMore && selectedTarget === provider.id ? (
                                        <>
                                          less
                                          <AiFillCaretUp />
                                        </>
                                      ) : (
                                        <>
                                          more
                                          <AiFillCaretDown />
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </button>
                                </span>
                              </div>
                            </td>
                            <td className={styles.providerAvailabilityRow}>
                              <div className={styles.availability}>
                                {isMobile && <span className={styles.mobileHeaderText}>Availability </span>}
                                <div className={styles.availabilityWrapper}>
                                  {p.slot_times.map((availability) => (
                                    <div className={styles.availabilityRow}>
                                      <span className={styles.availabilityIconContainer}>
                                        {availability.synchronous && (
                                          <span className={styles.availabilityIcon}>
                                            <HiOutlineVideoCamera />
                                          </span>
                                        )}
                                        {availability.asynchronous && (
                                          <span className={styles.availabilityIcon}>
                                            <CgFileDocument />
                                          </span>
                                        )}
                                      </span>
                                      <span className={styles.availabilitySlot}>
                                        {moment(availability.start_time).format('h:mmA')} -{' '}
                                        {moment(availability.end_time).format('h:mmA')}
                                      </span>
                                      <span className={styles.availabilityTimezone}>
                                        {' '}
                                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <td className={styles.providerAvailabilityRow}>
                              <div>
                                {isMobile && <p className={styles.mobileHeaderText}>Language</p>}
                                <span>
                                  {provider.basic_info?.languages
                                    ?.map((language) => typeof language !== 'string' && language.name)
                                    .join(', ')}
                                </span>
                              </div>
                            </td>
                            <td className={styles.checkMarkTD} aria-label="Select Provider">
                              <div className={`${styles.checkMark}`}>
                                <button
                                  type="button"
                                  onClick={() => handleSelectedProvider(provider.id)}
                                  aria-label="Select Provider Button"
                                >
                                  <FaCheckCircle
                                    className={`${
                                      selectedProvider === provider.id ? styles.primaryColor : styles.defaultColor
                                    }`}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : !dataLoading ? (
                    sortedData()
                      .filter((data) => data.user.id !== thisCase.provider_id)
                      .map((p) => {
                        const provider = p.user;
                        return (
                          <tr
                            key={provider.id}
                            className={`${styles.providerListTableBody} ${
                              selectedProvider === provider.id ? styles.selectedClass : styles.notSelectedClass
                            }`}
                            onClick={() => handleSelectedProvider(provider.id)}
                          >
                            <td style={{ cursor: 'pointer' }}>
                              <div
                                className={`col-md-2 ${styles.providerInfo}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTargetProvider(provider);
                                  setProviderProfileCardModal(true);
                                }}
                              >
                                <img
                                  alt="Avatar"
                                  onError={(event: any) => {
                                    event.target.src =
                                      'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
                                    event.target.onError = null;
                                  }}
                                  className={styles.providerImage}
                                  src={getUserAvatar(provider.id)}
                                />
                                <div className={styles.mobileInfo}>
                                  <span>{provider.full_name}</span>
                                  {isMobile && (
                                    <span className={cn(styles.mobileState, 'text-wrap', 'd-flex', 'flex-wrap')}>
                                      {provider.provider_profile?.licenses?.map((license) => license.state).join(', ')}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            {!isMobile && (
                              <td className="col-md-1">
                                <span className={styles.mobileState}>
                                  {provider.provider_profile?.licenses &&
                                    getRenderedStateItems(provider.provider_profile?.licenses, provider.id)}
                                  <button
                                    type="button"
                                    className={styles.more}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleState(provider.id);
                                    }}
                                  >
                                    {provider.provider_profile?.licenses &&
                                    getRenderedStateItems(provider.provider_profile?.licenses, provider.id).length >=
                                      MAX_ITEMS ? (
                                      isMoreState && selectedTarget === provider.id ? (
                                        <>
                                          less
                                          <AiFillCaretUp />
                                        </>
                                      ) : (
                                        <>
                                          more
                                          <AiFillCaretDown />
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </button>
                                </span>
                              </td>
                            )}
                            <td>
                              {isMobile && <p className={styles.mobileHeaderText}>Specialty</p>}
                              <span>
                                {provider.provider_profile?.primary_specialty
                                  ?.map((specialty) => specialty)
                                  .join(', ') || ''}
                              </span>
                            </td>
                            <td>
                              <div className={`${styles.insurance}`}>
                                {isMobile && <p className={styles.mobileHeaderText}>Insurance</p>}
                                <span className="text-wrap">
                                  {provider.provider_profile?.licenses &&
                                    getRenderedItems(provider.provider_profile?.licenses, provider.id)}
                                  <button
                                    type="button"
                                    className={styles.more}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggle(provider.id);
                                    }}
                                  >
                                    {provider.provider_profile?.licenses &&
                                    getRenderedItems(provider.provider_profile?.licenses, provider.id).length >=
                                      MAX_ITEMS ? (
                                      isMore && selectedTarget === provider.id ? (
                                        <>
                                          less
                                          <AiFillCaretUp />
                                        </>
                                      ) : (
                                        <>
                                          more
                                          <AiFillCaretDown />
                                        </>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </button>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className={styles.availability}>
                                {isMobile && <span className={styles.mobileHeaderText}>Availability </span>}
                                <div className={styles.availabilityWrapper}>
                                  {p.slot_times.map((availability) => (
                                    <div className={styles.availabilityRow}>
                                      (
                                      <span className={styles.availabilitySlot}>
                                        {availability.start_time} - {availability.end_time}
                                      </span>
                                      <span className={styles.availabilityTimezone}> MDT</span>)
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {isMobile && <p className={styles.mobileHeaderText}>Language</p>}
                                <span>
                                  {provider.basic_info?.languages
                                    ?.map((language) => typeof language !== 'string' && language.name)
                                    .join(', ')}
                                </span>
                              </div>
                            </td>
                            <td className={styles.checkMarkTD} aria-label="Select Provider">
                              <div className={`${styles.checkMark}`}>
                                <button
                                  type="button"
                                  onClick={() => handleSelectedProvider(provider.id)}
                                  aria-label="Select Provider Button"
                                >
                                  <FaCheckCircle
                                    className={`${
                                      selectedProvider === provider.id ? styles.primaryColor : styles.defaultColor
                                    }`}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <span>
                      <Loading dotted />
                    </span>
                  )}
                  {!dataLoading && (
                    <tr className={styles.lastItemRef} ref={lastProviderItemRef}>
                      <td>{isLoading && <Loading dotted />}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={targetProvider}
          patientCurrentStateValue={undefined}
          caseId={thisCase.id}
          filters={filters}
          thisCase={thisCase}
          setCase={setCase}
          setLoading={setLoading}
          openModal={openModal}
          isAssignProvider
        />
      )}
    </div>
  );
};

export default ProviderListTable;
