import { client } from '../client';
import { IOneReport } from './types/IOneReport';
import { ITreatmentsOptoinal } from './updateOneReportTreatment';

export const addOneReportTreatment = async (
  one_report_id: string,
  payload: ITreatmentsOptoinal,
): Promise<IOneReport> => {
  const { data } = await client.post<IOneReport>(`/one_reports/${one_report_id}/treatments`, payload);

  return data;
};
