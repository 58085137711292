import { client } from '../client';
import { IOneReport } from './types/IOneReport';

export const deleteOneReportEducation = async (
  one_report_id: string,
  one_report_treatment_id: string,
): Promise<IOneReport> => {
  const { data } = await client.delete<IOneReport>(
    `/one_reports/${one_report_id}/educations/${one_report_treatment_id}`,
  );

  return data;
};
