/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import Button from '../../../Components/Button/Button';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import useCases from '../../../hooks/useCases';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import { calculateCaseDue } from '../../../Utils/calculateCaseDue';
import { formatDate } from '../../../Utils/formatDate';
import { getColorForStatus, getTextColorForStatus } from '../../../Utils/getColorForStatus';
import { getFriendlyNameForPatient } from '../../../Utils/getFriendlyName';
import { getTextForStatus } from '../../../Utils/getTextForStatus';
import { sortData, SortKeys, SortOrder } from '../../../Utils/sortData';
import video from '../../Dashboard/CareAdvisor/assets/liveVideo.svg';
import photos from '../../Dashboard/CareAdvisor/assets/oralHealthReport.svg';
import phone from '../../Dashboard/CareAdvisor/assets/phone.svg';
import sVideo from '../../Dashboard/CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '../../Dashboard/CareAdvisor/assets/secondOpinion.svg';
import ProfileComponent from '../../Dashboard/CareAdvisor/Component/ProfileComponent';
import LinkWithUnderline from '../Components/LinkWithUnderline';
import styles from './appointments.module.scss';

interface ReviewPatientCasesProps {
  patientId: number;
}

const ReviewPatientCases = ({ patientId }: ReviewPatientCasesProps): JSX.Element => {
  const [activeLink, setActiveLink] = useState<number | null>(1);
  const [casesparams, setCasesParams] = useState({
    sort_by: 'created_at' as const,
    sort_direction: 'desc' as const,
    patient_id: Number(patientId),
    status:
      activeLink === 1
        ? [
            'new',
            'accepted_care_advisor',
            'accepted_provider',
            'in_progress_provider',
            'in_progress_care_advisor',
            'completed_by_provider',
          ]
        : ['canceled', 'completed'],
    type:
      activeLink === 1
        ? [CaseType.one_report, CaseType.second_opinion, CaseType.video_call_scheduled, CaseType.phone_call_scheduled]
        : [
            CaseType.one_report,
            CaseType.second_opinion,
            CaseType.video_call_scheduled,
            CaseType.smile_scan,
            CaseType.phone_call_scheduled,
          ],
    limit: 15,
  });

  const { cases, loadNextPage, dataLoading } = useCases(casesparams, true);

  useEffect(() => {
    setCasesParams((prevParams) => ({
      ...prevParams,
      status:
        activeLink === 1
          ? [
              'new',
              'accepted_care_advisor',
              'accepted_provider',
              'in_progress_provider',
              'in_progress_care_advisor',
              'completed_by_provider',
            ]
          : ['canceled', 'completed'],
      type:
        activeLink === 1
          ? [CaseType.one_report, CaseType.second_opinion, CaseType.video_call_scheduled, CaseType.phone_call_scheduled]
          : [
              CaseType.one_report,
              CaseType.second_opinion,
              CaseType.video_call_scheduled,
              CaseType.smile_scan,
              CaseType.phone_call_scheduled,
            ],
    }));
  }, [patientId, activeLink]);

  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useRef<HTMLTableRowElement>(null);
  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastItemRef.current) observer.current.observe(lastItemRef.current);
  }, [cases]);

  const headers: { key: SortKeys; label: string; isSorted: boolean }[] = [
    { key: 'id', label: 'Case ID', isSorted: false },
    { key: 'created_at', label: 'Requested', isSorted: false },
    { key: 'completed_at', label: 'Completed', isSorted: false },
    { key: 'due_date', label: 'Scheduled', isSorted: false },
    { key: 'type', label: 'Type', isSorted: false },
    { key: 'provider', label: 'Provider', isSorted: false },
    { key: 'status', label: 'Status', isSorted: false },
  ];

  const [headersState, setHeadersState] = useState(headers);
  const [sortKey, setSortKey] = useState<SortKeys>('due_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn');

  const { userInfo } = useAppSelector(selectAuth);

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    const headerIndex = headers.findIndex((header) => header.key === key);
    setSortOrder(sortOrder === 'ascn' ? 'desc' : 'ascn');
    setSortKey(key);
    const updatedHeadersState = headersState.map((header, index) => {
      if (index === headerIndex) {
        return {
          ...header,
          isSorted: true,
        };
      }
      return {
        ...header,
        isSorted: false,
      };
    });

    setHeadersState(updatedHeadersState);
  };

  const navigate = useNavigate();

  return (
    <div className={styles.appointments}>
      <div className={styles.container}>
        <div className={styles.filterDiv}>
          <LinkWithUnderline text="Upcoming" id={1} activeLink={activeLink} setActiveLink={setActiveLink} />
          <LinkWithUnderline text="Completed" id={2} activeLink={activeLink} setActiveLink={setActiveLink} />
        </div>
        <div className={styles.innerContainer}>
          {!dataLoading ? (
            cases.filter((appointment) => appointment.type !== 'messaging').length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr className={styles.tableHeaders}>
                      {headersState.map((header) => {
                        if (
                          (activeLink === 2 && header.key !== 'due_date') ||
                          (activeLink === 1 && header.key !== 'completed_at')
                        ) {
                          return (
                            <th key={header.key} onClick={() => changeSort(header.key)}>
                              <span className={`${sortKey === header.key ? styles.bold : ''}`}>
                                {header.label}{' '}
                                {header.isSorted && sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                              </span>
                            </th>
                          );
                        }
                        return null; // Render nothing for other headers
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData()
                      .filter((appointment) => appointment.type !== 'messaging')
                      .map((appointment) => {
                        return (
                          <Fragment key={appointment.id}>
                            <tr key={appointment.id} className={styles.appointment}>
                              <td className={`${styles.consultType} ${styles.onDesktop}`}>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Case ID</div>
                                  {appointment.id}
                                </div>
                              </td>
                              <td className={`${styles.consultType} ${styles.onDesktop}`}>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Requested</div>
                                  {formatDate(appointment.created_at)}
                                </div>
                              </td>
                              {activeLink === 2 && (
                                <td className={`${styles.consultType} ${styles.onDesktop}`}>
                                  <div className={styles.cellContainer}>
                                    <div className={styles.mobileHeader}>Completed</div>
                                    {appointment.status !== 'canceled'
                                      ? formatDate(appointment.completed_at || appointment.updated_at || '')
                                      : formatDate(appointment.updated_at || '')}
                                    {}
                                  </div>
                                </td>
                              )}
                              {activeLink === 1 && (
                                <td className={`${styles.mobileDue} ${styles.duaOnMobile}`}>
                                  <div className={`${styles.due}`}>
                                    <span className={styles.mobileHeader}>Due</span>
                                    <span className={styles.mobileDueDate}>
                                      {calculateCaseDue(
                                        appointment.type,
                                        appointment.started_at,
                                        appointment.video_conference_date || '',
                                      )}
                                    </span>
                                  </div>
                                </td>
                              )}
                              <td className={`${styles.consultType} ${styles.onDesktop}`}>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Consult Type</div>
                                  {appointment.type === 'video_call_instant' && (
                                    <img className={styles.video} src={video} alt="video" />
                                  )}
                                  {appointment.type === 'video_call_scheduled' && (
                                    <img className={styles.sVideo} src={sVideo} alt="video" />
                                  )}
                                  {appointment.type === CaseType.phone_call_scheduled && (
                                    <img className={styles.sVideo} src={phone} alt="Phone" />
                                  )}
                                  {appointment.type === 'one_report' && (
                                    <img className={styles.cReport} src={photos} alt="video" />
                                  )}
                                  {appointment.type === 'second_opinion' && (
                                    <img className={styles.cReport} src={secondOpinion} alt="secondOpinion" />
                                  )}
                                  <span className={styles.typeName}>{getFriendlyNameForPatient(appointment.type)}</span>
                                </div>
                              </td>
                              <td className={`${styles.mobileConsultAndStatus} ${styles.bottomBorder}`}>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Requested</div>
                                  {formatDate(appointment.created_at)}
                                </div>
                                <div className={styles.consultType}>
                                  <div className={styles.cellContainer}>
                                    <div className={styles.mobileHeader}>Consult Type</div>
                                    {appointment.type === 'video_call_instant' && (
                                      <img className={styles.video} src={video} alt="video" />
                                    )}
                                    {appointment.type === 'video_call_scheduled' && (
                                      <img className={styles.sVideo} src={sVideo} alt="video" />
                                    )}
                                    {appointment.type === CaseType.phone_call_scheduled && (
                                      <img className={styles.sVideo} src={phone} alt="Phone" />
                                    )}
                                    {appointment.type === 'one_report' && (
                                      <img className={styles.cReport} src={photos} alt="video" />
                                    )}
                                    {appointment.type === 'second_opinion' && (
                                      <img className={styles.cReport} src={secondOpinion} alt="secondOpinion" />
                                    )}
                                    <span className={styles.typeName}>
                                      {getFriendlyNameForPatient(appointment.type)}
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Completed</div>
                                  {appointment.status !== 'canceled'
                                    ? formatDate(appointment.completed_at || appointment.updated_at || '')
                                    : 'Canceled'}
                                  {}
                                </div>
                              </td>
                              <td className={`${styles.provider} ${styles.bottomBorder}`} aria-label="Provider">
                                <td className={styles.mobilePatient} aria-label="Provider Profile">
                                  <ProfileComponent caseRow={appointment} role="Provider" />
                                </td>
                              </td>
                              <td className={styles.mobileStatus}>
                                <div>
                                  <span className={styles.mobileHeader}>Status</span>
                                  {(() => {
                                    for (const key in appointment) {
                                      if (key === 'status') {
                                        return (
                                          <span
                                            className={styles.statusSpan}
                                            style={{
                                              backgroundColor: getColorForStatus(appointment[key]),
                                              color: getTextColorForStatus(appointment[key]),
                                            }}
                                          >
                                            {getTextForStatus(UserRoleName.Patient, appointment.type, appointment[key])}
                                          </span>
                                        );
                                      }
                                    }
                                  })()}
                                </div>
                              </td>
                              <td colSpan={4}>
                                <div className={`${styles.viewBtn} ${styles.customBtn}`}>
                                  <div className={styles.internalDiv}>
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        appointment.type !== CaseType.smile_scan
                                          ? navigate(`/admin/consultation-page/${appointment.id}`)
                                          : navigate(`/smile-scan-report/${appointment?.id}`);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    <tr className={styles.lastItemRef} ref={lastItemRef} />
                  </tbody>
                </table>
                {isLoading && <Loading dotted />}
              </>
            ) : (
              <span className={styles.noData}>No data to display</span>
            )
          ) : (
            <span>
              <Loading dotted />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewPatientCases;
