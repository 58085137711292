/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import moment from 'moment';

import { IslotTime } from '../../../../../hooks/useAvailableDates';
import { formatDateToDate } from '../../../../../Utils/formatDate';
import { TimeSlot } from '../../utils/ProviderAvailabilityCard/Components/TimeSlotComponent';

const addMinutes = (time: Date, minutes: number): Date => {
  const newTime = new Date(time.getTime());
  newTime.setMinutes(newTime.getMinutes() + minutes);
  return newTime;
};

// add seconds to the time object
const addSeconds = (time: Date, seconds: number): Date => {
  const oTime = new Date(time.getTime());
  oTime.setSeconds(oTime.getSeconds() + seconds);
  return oTime;
};

const formatTime = (time: Date): string => {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

const parseCustomDate = (dateString: string): Date => {
  const dateParts = dateString.split(/[-T:+]/);
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);
  const hour = parseInt(dateParts[3], 10);
  const minute = parseInt(dateParts[4], 10);
  const second = parseInt(dateParts[5], 10);

  const customDate = new Date(Date.UTC(year, month, day, hour, minute, second));
  return customDate;
};

export const generateTimeSlots = (timeIntervals: IslotTime[], currentTimeZone: string): TimeSlot[] => {
  const timeSlots: TimeSlot[] = [];

  const synchronousIntervals = timeIntervals.filter((synIntervals) => synIntervals?.synchronous === true);

  if (synchronousIntervals) {
    synchronousIntervals.forEach((interval) => {
      if (interval.end_time.slice(-5) === '59:59') {
        interval.end_time = addSeconds(parseCustomDate(interval.end_time), 1).toString();
      }

      const startTime = formatDateToDate(parseCustomDate(interval.start_time).toString(), currentTimeZone);
      let endTime = formatDateToDate(parseCustomDate(interval.end_time).toString(), currentTimeZone);
      // If endTime is on the next day, set it to today's date with 11:59 pm
      if (endTime.getDate() !== startTime.getDate()) {
        endTime.setFullYear(startTime.getFullYear());
        endTime.setMonth(startTime.getMonth());
        endTime.setDate(startTime.getDate());
        endTime.setHours(24, 0, 0, 0);
      }

      // Reduce endTime by 30 minutes
      endTime = addMinutes(endTime, -30);

      // Get the current time in the specified time zone
      const currentTime = moment().tz(currentTimeZone);
      const momentStartTime = moment.utc(startTime).local();
      const diffInMinutes = momentStartTime.diff(currentTime, 'minutes');

      // If startTime is today's date, set it to the current time plus 30 minutes
      if (
        (diffInMinutes < 30 || currentTime.isAfter(startTime)) &&
        currentTime.isBefore(endTime) &&
        startTime.getFullYear() === currentTime.year() &&
        startTime.getMonth() === currentTime.month() &&
        startTime.getDate() === currentTime.date()
      ) {
        startTime.setHours(currentTime.hour(), currentTime.minute() + 30, 0, 0);

        // Round to the nearest quarter after adding 30 minutes
        const minutesRemainder = startTime.getMinutes() % 15;
        if (minutesRemainder !== 0) {
          const minutesToAdd = 15 - minutesRemainder;
          startTime.setMinutes(startTime.getMinutes() + minutesToAdd);
        }
      }

      let currentTimeSlot = startTime;

      while (currentTimeSlot <= endTime) {
        const slot: TimeSlot = {
          start_time: formatTime(currentTimeSlot),
          end_time: formatTime(addMinutes(currentTimeSlot, 30)),
          synchronous: interval.synchronous,
          asynchronous: interval.asynchronous,
        };

        timeSlots.push(slot);
        currentTimeSlot = addMinutes(currentTimeSlot, 30);
      }
    });
  }

  return timeSlots;
};
