import { client } from '../client';
import { IOneReport } from './types/IOneReport';

export interface ITreatmentsOptoinal {
  category?: string;
  tooth_area?: string;
  tooth_number?: string;
  description?: string;
  urgency?: string;
  id?: string;
}

export const updateOneReportTreatment = async (
  one_report_id: string,
  payload: ITreatmentsOptoinal,
): Promise<IOneReport> => {
  const { data } = await client.patch<IOneReport>(`/one_reports/${one_report_id}/treatments/${payload.id}`, payload);

  return data;
};
