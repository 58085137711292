import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { Meta } from '../services/communication/types/Meta';
import {
  listAvailableProviders,
  ListProvidersParams,
  ProviderAvailability,
} from '../services/forms/listAvailableProviders';

interface UseProviders {
  providers: ProviderAvailability[];
  loadNextPage: () => Promise<void>;
  loadPreviousPage: () => Promise<void>;
  dataLoading: boolean;
}

export default function useProviders(
  params: Pick<
    ListProvidersParams,
    | 'date'
    | 'tz'
    | 'sort_by'
    | 'sort_direction'
    | 'language'
    | 'state'
    | 'specialty'
    | 'plan'
    | 'license_states'
    | 'carrier_id'
    | 'provider_id'
    | 'synchronous'
    | 'asynchronous'
    | 'case_id'
  >,
): UseProviders {
  const [providers, setProviders] = useState<ProviderAvailability[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const loadNextPage = async (): Promise<void> => {
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    const { meta, nodes } = await listAvailableProviders({
      cursor: lastPageMeta.end_cursor,
      cursor_direction: 'next',
      ...params,
    });
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    setProviders((prevProviders) => uniqBy([...prevProviders, ...nodes], 'id'));
  };

  const loadPreviousPage = async (): Promise<void> => {
    if (!firstPageMeta?.has_previous_page) {
      return;
    }
    const { meta, nodes } = await listAvailableProviders({
      cursor: firstPageMeta.start_cursor,
      cursor_direction: 'previous',
      ...params,
    });
    setFirstPageMeta(meta);
    if (!lastPageMeta) {
      setLastPageMeta(meta);
    }
    setProviders((prevProviders) => uniqBy([...nodes, ...prevProviders], 'id'));
  };

  useEffect(() => {
    if (params) {
      let canceled = false;
      setDataLoading(true);
      listAvailableProviders({
        ...params,
      }).then((providersList) => {
        if (!canceled) {
          setProviders(providersList.nodes);
          setFirstPageMeta(providersList.meta);
          setLastPageMeta(providersList.meta);
          setDataLoading(false);
        }
      });

      return (): void => {
        canceled = true;
      };
    }
    return undefined;
  }, [params]);

  return {
    providers,
    loadNextPage,
    loadPreviousPage,
    dataLoading,
  };
}
