import cn from 'classnames';
import React, { useEffect } from 'react';
import Select, { ActionMeta, GroupBase, MultiValue, OptionProps, SingleValue, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import { selectCustomStyles } from '../../../Utils/customStyles';
import { getHoverColor, getPrimaryColor, getSecondaryColor } from '../../../Utils/getPrimaryColor';
import { Option } from '../../../Utils/selectOptions';
import styles from '../SelectInput/styles.module.scss';

type MultiselectInputProps = {
  containerClass?: string;
  selectorClass?: string;
  name: string;
  placeholder?: string;
  options: { value: string | number; label: string; id?: string }[];
  style?: StylesConfig<Option, any, GroupBase<Option>>;
  errors?: Partial<any>;
  selectedValue?: readonly Option[];
  isDisabled?: boolean;
  onChange?: ((newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => void) | undefined;
  isClearable?: boolean;
  onMenuClose?: (() => void) | undefined;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
};

const getComponents = (): Partial<SelectComponents<Option, true, GroupBase<Option>>> => {
  return {
    MultiValueContainer: ({ selectProps, data }) => {
      const values: MultiValue<Option> = Array.isArray(selectProps.value) ? selectProps.value : [];
      const isLastValue = values.length > 0 && values[values.length - 1].value === data.value;
      const style = {
        fontWeight: '400',
        fontSize: '16px',
      };
      return (
        <span style={style}>
          {data.label}
          {isLastValue ? '' : ',\u00A0'}
        </span>
      );
    },
    Option: (props: OptionProps<Option>) => {
      const { isFocused, innerProps, innerRef, data, isDisabled } = props;
      const style = isFocused
        ? {
            backgroundColor: getHoverColor(),
            color: getSecondaryColor(),
          }
        : {};

      const checkboxStyle = {
        marginRight: '10px',
        marginLeft: '19px',
        width: '16px',
        height: '16px',
        border: `1px solid ${getPrimaryColor()}`,
        borderRadius: '1px',
        opacity: '1',
        backgroundColor: data.isSelected ? getPrimaryColor() : 'transparent',
      };

      return (
        <div {...innerProps} style={style} className={cn({ [styles.disabledOption]: isDisabled })}>
          <input
            type="checkbox"
            {...innerProps}
            checked={!!data.isSelected}
            onChange={() => null}
            ref={innerRef}
            style={checkboxStyle}
            className="form-check-input"
          />
          {data.label}
        </div>
      );
    },
  };
};

const MultiselectInput = React.forwardRef(
  (
    {
      containerClass,
      selectorClass,
      name,
      placeholder,
      options,
      style,
      errors,
      selectedValue,
      isDisabled,
      onChange,
      isClearable,
      onMenuClose,
      onBlur,
    }: MultiselectInputProps,
    ref,
  ): JSX.Element => {
    const [errorMessage, setErrorMessage] = React.useState('');
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0 && name) {
        const nameArray = name.split('.');
        if (nameArray.length > 1) {
          setErrorMessage(errors[nameArray[0]]?.[nameArray[1]]?.message);
        } else {
          setErrorMessage(errors[name]?.message);
        }
      } else {
        setErrorMessage('');
      }
    }, [errors, name, selectedValue]);

    const optionsWithSelection: { value: string | number; label: string; isSelected: boolean }[] = options?.map(
      (option) => ({
        ...option,
        isSelected: selectedValue ? selectedValue.some((selected) => selected?.value === option?.value || '') : false,
      }),
    );

    return (
      <div className={`${styles.inputWrapper} ${containerClass}`} data-validate={`Choose ${name}`}>
        <Select
          isMulti
          isClearable={isClearable}
          className={cn(styles.input100, 'p-0', { 'is-valid': errorMessage }, styles.select, selectorClass)}
          onChange={onChange}
          name={name}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          styles={style}
          hideSelectedOptions={false}
          placeholder={placeholder}
          menuPortalTarget={document.body}
          options={optionsWithSelection}
          value={!isDisabled ? selectedValue : null}
          isDisabled={isDisabled}
          isOptionDisabled={(option) => option.disabled === true}
          components={getComponents() as Partial<SelectComponents<Option, true, GroupBase<Option>>> | undefined}
          ref={ref as any}
          onMenuClose={onMenuClose}
          onBlur={onBlur}
        />
        <span className={styles.focusInput100} />
        {errorMessage && <div className={`${styles.invalidFeedback} invalid-feedback`}>{errorMessage}</div>}
      </div>
    );
  },
);

MultiselectInput.defaultProps = {
  containerClass: '',
  placeholder: '',
  style: selectCustomStyles,
  errors: {},
  isDisabled: false,
  onChange: () => {},
  isClearable: false,
  selectedValue: [],
  selectorClass: '',
  onMenuClose: () => {},
  onBlur: () => {},
};

export default MultiselectInput;
