import { CaseType } from '@brands/services/cases/types/ICase';
import React, { useEffect, useMemo, useState } from 'react';
import { GoTriangleDown } from 'react-icons/go';

import useCases from '../../../../hooks/useCases';
import ConsulationHistory from '../ConsultationHistory/ConsulationHistory';
import styles from './styles.module.scss';

interface Modal {
  patient_id: number;
  isMobile?: boolean;
  currentCaseId: number;
}

const PatientCasesList = ({ patient_id, isMobile, currentCaseId }: Modal): JSX.Element => {
  const [displayedCases, setDisplayedCases] = useState<any[]>([]);
  const [displayCount, setDisplayCount] = useState(5);

  const casesparams = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
        'completed',
      ],
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
      ],
      patient_id,
      limit: 5,
    }),
    [patient_id],
  );

  const casesparamsSmileScan = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      status: ['completed'],
      type: [CaseType.smile_scan],
      patient_id,
      limit: 2,
    }),
    [patient_id],
  );

  const { cases, loadNextPage, lastPageMeta, loading } = useCases(casesparams, true);
  const { cases: smileScanCases } = useCases(casesparamsSmileScan, true);

  useEffect(() => {
    if (cases.length > 0) {
      setDisplayedCases([...smileScanCases, ...cases]);
    }
  }, [cases]);

  useEffect(() => {
    setDisplayedCases((prevDisplayedCases) =>
      [...prevDisplayedCases].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    );
  }, [displayedCases]);

  const handleLoadMore = (): void => {
    const newDisplayCount = displayCount + 5;
    setDisplayCount(newDisplayCount);

    if (displayedCases.length < newDisplayCount && lastPageMeta?.has_next_page) {
      loadNextPage();
    }
  };

  return (
    <div className={styles.patient}>
      <div className={styles.underLinedDiv}>
        <div className={styles.flexCol}>
          {!isMobile && <span>Other Consultations for this Patient</span>}
          <div className={`row ${styles.tableHeader}`}>
            <span className="col-2">Date:</span>
            <span className="col-2">Case ID:</span>
            <span className="col-3">Case Status:</span>
            <span className="col-4">Case Type:</span>
          </div>

          {displayedCases
            .filter((singleCase) => Number(singleCase.id) !== Number(currentCaseId))
            .map((singleCase) => (
              <ConsulationHistory singleCase={singleCase} key={singleCase.id} />
            ))}

          {lastPageMeta?.has_next_page && (
            <div className={`row ${styles.tableHeader}`}>
              <button type="button" onClick={handleLoadMore} disabled={loading}>
                <GoTriangleDown className={`${styles.seeMoreSpan} ${loading ? styles.disabled : ''}`} />
                <span className={`${styles.seeMoreSpan} ${loading ? styles.disabled : ''}`}>See More</span>
                <GoTriangleDown className={`${styles.seeMoreSpan} ${loading ? styles.disabled : ''}`} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PatientCasesList.defaultProps = {
  isMobile: false,
};

export default PatientCasesList;
