/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectDateModalCustomStyles, selectDateModalCustomStylesMobileExtended } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { formatTimeForApi, formatTimeForDisplay } from '@brands/Utils/formatTime';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { BsPlusCircleFill, BsTrash3 } from 'react-icons/bs';
import { HiPlusCircle } from 'react-icons/hi';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import Button from '../../../../../../../Components/Button/Button';
import Calendar from '../../../../../../../Components/Calendar/Calendar';
import { Loading } from '../../../../../../../Components/LoadingSpinner/Loading';
import { timeOptions } from '../../../../../../../Components/TimeInput/assets/options';
import TimeInput from '../../../../../../../Components/TimeInput/TimeInput';
import { useWindowSize } from '../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../hooks/useReduxHook';
import { addUserAvailability } from '../../../../../../../services/availability/addUserAvailability';
import { checkUserAvailability } from '../../../../../../../services/availability/checkUserAvailability';
import { deleteUserAvailability } from '../../../../../../../services/availability/deleteUserAvailability';
import { ProviderAvailability } from '../../../../../../../services/availability/types/ProviderAvailability';
import { ICase } from '../../../../../../../services/cases/types/ICase';
import { getPrimaryColor } from '../../../../../../../Utils/getPrimaryColor';
import { Option } from '../../../../../../../Utils/selectOptions';
import styles from './styles.module.scss';

interface SelectDateModalProps {
  addDateModal: boolean;
  setAddDateModal: (bool: boolean) => void;
  dateOverrides: ProviderAvailability[];
  setDateOverrides: React.Dispatch<React.SetStateAction<ProviderAvailability[]>>;
  weeklyAvailabilities: ProviderAvailability[];
  selectedDates: Date[] | null;
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[] | null>>;
  timezone: string;
  setAlertModal: React.Dispatch<
    React.SetStateAction<{
      cases: ICase[];
      kind: 'override' | 'weekly';
      type: 'delete' | 'update' | 'add';
      selectedDates?: Date[];
      isUnavailable?: boolean;
      slots: ProviderAvailability[];
      overrideSlots?: OverrideHour[];
    } | null>
  >;
  setRequestSending: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface OverrideHour {
  start: string;
  end: string;
  type: string[];
  id: number;
}

interface Hour {
  start: string;
  end: string;
  type: string[];
  id: number;
}

export const addProviderUnavailability = async (
  userId: number,
  date: Date,
  setValues: (value: React.SetStateAction<ProviderAvailability[]>) => void,
): Promise<void> => {
  try {
    await addUserAvailability(userId, {
      type: 'date',
      date: moment(date).format('YYYY-MM-DD'),
      start_time: '00:00:00',
      end_time: '23:59:59',
      synchronous: false,
      asynchronous: false,
    }).then((res) => {
      if (res) {
        setValues((prev) => [
          ...prev,
          {
            ...res,
            start_time: formatTimeForDisplay(res.start_time),
            end_time: res.end_time === '12:00am' ? '23:59:59' : formatTimeForDisplay(res.end_time),
          },
        ]);
      }
    });
  } catch (error: unknown) {
    displayErrorDetails(error);
  }
};

const SelectDate: FC<SelectDateModalProps> = ({
  addDateModal,
  setAddDateModal,
  dateOverrides,
  setDateOverrides,
  weeklyAvailabilities,
  selectedDates,
  setSelectedDates,
  timezone,
  setAlertModal,
  setRequestSending,
}) => {
  const { userInfo } = useAppSelector(selectAuth);
  const screenSize = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [overrideHours, setOverrideHours] = useState<OverrideHour[]>([]);

  const endTimeOptions = (hour: ProviderAvailability): Option[] => {
    let filteredTimeOptions = timeOptions;

    if (hour.start_time) {
      const startTimeId = timeOptions.find((option) => option.value === hour.start_time)?.id;

      if (startTimeId) {
        filteredTimeOptions = timeOptions.filter((option) => Number(option.id) >= Number(startTimeId) + 1);
      }
    }

    return filteredTimeOptions;
  };
  const startTimeOptions = (hour: ProviderAvailability, isToday: boolean): Option[] => {
    let filteredTimeOptions = timeOptions;

    if (hour.end_time) {
      const endTimeId = timeOptions.find((option) => option.value === hour.end_time)?.id;
      if (endTimeId) {
        filteredTimeOptions = filteredTimeOptions.filter((option) => Number(option.id) <= Number(endTimeId) - 1);
      }
    }

    if (isToday) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      const currentMeridiem = currentHour < 12 ? 'am' : 'pm';
      const currentHour12 = currentHour === 0 || currentHour === 12 ? '12' : (currentHour % 12).toString();
      const currentMinute30 = currentMinute < 30 ? '30' : '00';
      const currentTime = `${currentHour12}:${currentMinute30}${currentMeridiem}`;

      const currentTimeIndex = filteredTimeOptions.findIndex((option) => option.value === currentTime);
      filteredTimeOptions = filteredTimeOptions.slice(currentTimeIndex + 1);
    }

    return filteredTimeOptions;
  };

  const todaysTimeOptions = useMemo(() => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    const currentMeridiem = currentHour < 12 ? 'am' : 'pm';
    const currentHour12 = currentHour === 0 || currentHour === 12 ? '12' : (currentHour % 12).toString();
    const currentMinute30 = currentMinute < 30 ? '30' : '00';
    const currentTime = `${currentHour12}:${currentMinute30}${currentMeridiem}`;

    const currentTimeIndex = timeOptions.findIndex((option) => option.value === currentTime);

    return timeOptions.slice(currentTimeIndex + 1).filter((option) => option.value !== '12:00am');
  }, [timeOptions]);

  function addNewRow(): void {
    setOverrideHours((prev) => [
      ...prev,
      {
        start: '',
        end: '',
        type: UserRoleName.Provider === userInfo.role.name ? [] : ['synchronous', 'asynchronous'],
        id: prev.length,
      },
    ]);
  }

  const convertTimeFormat = (time: string): string => {
    const convertedTime = formatTimeForDisplay(time);
    return convertedTime;
  };

  const checkAllFieldsFilledForOverrideHours = (overrides: OverrideHour[]): boolean => {
    const isStartTimeEmpty = overrides.some((hour) => hour.start === '');
    const isEndTimeEmpty = overrides.some((hour) => hour.end === '');
    const isTypeEmpty = overrides.some((hour) => hour.type.length === 0);
    if (isTypeEmpty && userInfo.role.name === UserRoleName.Provider)
      toast.error('At least one of the consultation types must be selected.');
    if (isStartTimeEmpty) toast.error('Start time must be selected.');
    if (isEndTimeEmpty) toast.error('End time must be selected.');
    return isStartTimeEmpty || isEndTimeEmpty || (isTypeEmpty && userInfo.role.name === UserRoleName.Provider);
  };

  const isTypeMatching = (dateOverridedHour: any, types: string[]): boolean => {
    return types.some(
      (type) =>
        (type === 'synchronous' && dateOverridedHour.synchronous) ||
        (type === 'asynchronous' && dateOverridedHour.asynchronous),
    );
  };

  const isTimeOverlapping = (
    newStart: moment.Moment,
    newEnd: moment.Moment,
    oldStart: moment.Moment,
    oldEnd: moment.Moment,
  ): boolean => {
    return (
      newStart.isSame(oldStart) ||
      newStart.isBetween(oldStart, oldEnd) ||
      newEnd.isSame(oldEnd) ||
      newEnd.isBetween(oldStart, oldEnd) ||
      (newStart.isBefore(oldStart) && newEnd.isAfter(oldEnd))
    );
  };

  const checkNewOverridesHaveOverlapsWithValuesOfPreviousOverridesOnSameDate = (
    override: OverrideHour,
  ): ProviderAvailability[] => {
    let overlappedHours: ProviderAvailability[] = [];
    if (selectedDates && selectedDates.length > 0) {
      selectedDates.forEach((date) => {
        const dateOverrided = dateOverrides.filter(
          (dateOverride) => dateOverride.date === moment(date).format('YYYY-MM-DD'),
        );
        if (dateOverrided.length > 0) {
          if (
            dateOverrided.find(
              (dateOverridedHour) =>
                dateOverridedHour.start_time === '12:00am' && dateOverridedHour.end_time === '12:00am',
            )
          ) {
            overlappedHours = [];
          } else {
            dateOverrided.forEach((dateOverridedHour) => {
              if (isTypeMatching(dateOverridedHour, override.type)) {
                const newStartTime = moment(override.start, 'h:mmA');
                const newEndTime = moment(override.end, 'h:mmA');
                const oldStartTime = moment(dateOverridedHour.start_time, 'h:mmA');
                const oldEndTime = moment(dateOverridedHour.end_time, 'h:mmA');

                if (isTimeOverlapping(newStartTime, newEndTime, oldStartTime, oldEndTime)) {
                  overlappedHours = [...overlappedHours, dateOverridedHour];
                }
              }
            });
          }
        }
      });
    }
    return overlappedHours;
  };

  const checkNewOverridesHaveOverlaps = (overrides: OverrideHour[]): boolean => {
    for (let i = 0; i < overrides.length - 1; i += 1) {
      const slotAStart = moment(overrides[i].start, 'h:mmA');
      const slotAEnd = moment(overrides[i].end, 'h:mmA');
      for (let j = i + 1; j < overrides.length; j += 1) {
        const slotBStart = moment(overrides[j].start, 'h:mmA');
        const slotBEnd = moment(overrides[j].end, 'h:mmA');
        if (overrides[i].type.some((type) => overrides[j].type.includes(type))) {
          if (!(slotAEnd < slotBStart || slotAStart > slotBEnd)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const checkIsDeletePossible = async (
    availability: ProviderAvailability,
    unavailable_dates?: string[],
  ): Promise<ICase[]> => {
    if (!availability) return [];
    let cases: ICase[] = [];
    if (UserRoleName.Provider === userInfo.role.name) {
      await checkUserAvailability(userInfo.id, {
        availability_id: availability.id || undefined,
        unavailable_dates,
      })
        .then((res) => {
          if (res && res.length > 0) cases = res;
        })
        .catch(() => {
          setAddDateModal(false);
          setLoading(false);
        });
    }
    return cases;
  };

  const compareProviderAvailability = async (
    availability: ProviderAvailability,
    otherSlotsAtSameRequest: ProviderAvailability[],
  ): Promise<ICase[]> => {
    if (!availability) return [];
    let cases: ICase[] = [];
    if (UserRoleName.Provider === userInfo.role.name) {
      await checkUserAvailability(userInfo.id, {
        type: availability.type as unknown as 'date' | 'weekday' | undefined,
        date: availability.date,
        start_time: moment(availability.start_time, 'h:mmA').format('HH:mm:ss'),
        end_time:
          availability.end_time === '12:00am' ? '23:59:59' : moment(availability.end_time, 'h:mmA').format('HH:mm:ss'),
        synchronous: availability.synchronous,
        asynchronous: availability.asynchronous,
        other_slots_at_same_request: otherSlotsAtSameRequest,
      })
        .then((res) => {
          cases = res;
        })
        .catch(() => {
          setAddDateModal(false);
          setLoading(false);
        });
    }
    return cases;
  };

  const alignWeekdays = (weekday: number): number => {
    if (weekday === 0) return 6;
    return weekday - 1;
  };

  const getAllAvailabilitiesForSelectedDates = (): {
    overridesForSelectedDates: ProviderAvailability[];
    allAvailabilitiesForSelectedDates: ProviderAvailability[];
  } => {
    if (selectedDates && selectedDates.length > 0) {
      const overridesForSelectedDates = dateOverrides.filter((override) =>
        selectedDates.some((date) => override.date === moment(date).format('YYYY-MM-DD')),
      );
      const availabilitiesForSelectedDates: ProviderAvailability[] = [];

      selectedDates.forEach((date) => {
        const weekday = alignWeekdays(moment(date).day());
        weeklyAvailabilities.forEach((availability) => {
          if (availability.weekday === weekday) {
            availabilitiesForSelectedDates.push(availability);
          }
        });
      });
      const allAvailabilitiesForSelectedDates = [...availabilitiesForSelectedDates, ...overridesForSelectedDates];
      return {
        overridesForSelectedDates,
        allAvailabilitiesForSelectedDates,
      };
    }
    return {
      overridesForSelectedDates: [],
      allAvailabilitiesForSelectedDates: [],
    };
  };

  const onSubmit = (): void => {
    (async (): Promise<void> => {
      setLoading(true);
      setRequestSending(true);

      if (checkAllFieldsFilledForOverrideHours(overrideHours)) {
        setLoading(false);
        setSelectedDates([]);
        return;
      }

      let overlappedHours: ProviderAvailability[] = [];
      let isOverlapped = false;

      if (selectedDates && selectedDates.length > 0) {
        await Promise.all(
          selectedDates.map(() => {
            isOverlapped = overrideHours.length > 0 ? checkNewOverridesHaveOverlaps(overrideHours) : false;
            if (isOverlapped) return;
            overrideHours.forEach((hour) => {
              overlappedHours = checkNewOverridesHaveOverlapsWithValuesOfPreviousOverridesOnSameDate(hour);
            });
          }),
        );
      }

      if (isOverlapped || overlappedHours.length > 0) {
        setAddDateModal(false);
        setLoading(false);
        toast.error(
          'You already have an existing time slot that overlaps with this time. Please review your existing times and update this time slot accordingly.',
        );
        setLoading(false);
        setRequestSending(false);
        setSelectedDates([]);
        return;
      }

      if (selectedDates && selectedDates.length > 0) {
        try {
          const { overridesForSelectedDates, allAvailabilitiesForSelectedDates } =
            getAllAvailabilitiesForSelectedDates();
          // handle unavailable slots first
          if (overrideHours.length === 0) {
            const cases: ICase[] = [];
            const overrides: ProviderAvailability[] = [];
            const unavailableDates = selectedDates.map((date) => moment(date).format('YYYY-MM-DD'));
            await Promise.all(
              allAvailabilitiesForSelectedDates.map(async (override) => {
                await checkIsDeletePossible(override, unavailableDates).then((res) => {
                  if (res && res.length > 0) {
                    cases.push(...res);
                  } else if (override.type === 'date') {
                    overrides.push(override);
                  }
                });
              }),
            );

            if (cases && cases.length > 0) {
              setAlertModal({
                cases,
                kind: 'override',
                type: 'delete',
                selectedDates: selectedDates || [],
                isUnavailable: true,
                slots: overridesForSelectedDates,
              });
            } else if (overrides.length > 0) {
              await Promise.all(
                overrides.map(async (override) => {
                  await deleteUserAvailability(userInfo.id, override.id?.toString() || '').then(() => {
                    setDateOverrides((prev) => prev.filter((prevOverride) => prevOverride.id !== override.id));
                  });
                }),
              );
              await Promise.all(
                selectedDates.map(async (date) => {
                  await addProviderUnavailability(userInfo.id, date, setDateOverrides);
                }),
              );
            } else {
              await Promise.all(
                selectedDates.map(async (date) => {
                  await addProviderUnavailability(userInfo.id, date, setDateOverrides);
                }),
              );
            }
            return;
          }

          // handle with previous unavailable slots
          if (overridesForSelectedDates.length > 0) {
            const unavailableSlots = overridesForSelectedDates.filter(
              (override) => override.start_time === '12:00am' && override.end_time === '12:00am',
            );
            if (unavailableSlots.length > 0) {
              await Promise.all(
                unavailableSlots.map(async (override) => {
                  if (override.id) {
                    await deleteUserAvailability(userInfo.id, override.id.toString()).then(() => {
                      setDateOverrides((prev) => prev.filter((prevOverride) => prevOverride.id !== override.id));
                    });
                  }
                }),
              );
            }
          }
          const slots: ProviderAvailability[] = [];
          await selectedDates.forEach((date) => {
            overrideHours.forEach((hour) => {
              slots.push({
                id: null,
                type: 'date',
                date: moment(date).format('YYYY-MM-DD'),
                start_time: formatTimeForApi(hour.start),
                end_time: hour.end === '12:00am' ? '23:59:59' : formatTimeForApi(hour.end),
                synchronous: hour.type.includes('synchronous'),
                asynchronous: hour.type.includes('asynchronous'),
                created_at: '',
                weekday: alignWeekdays(moment(date).day()),
              });
            });
          });

          await Promise.all(
            slots.map(async (slot) => {
              const otherSlotsAtSameRequest = slots.filter(
                (s) => !(s.date === slot.date && s.start_time === slot.start_time),
              );
              if (otherSlotsAtSameRequest.length > 0) {
                otherSlotsAtSameRequest.forEach((otherSlot) => {
                  otherSlot.created_at = null;
                });
              }
              await compareProviderAvailability(slot, otherSlotsAtSameRequest).then(async (res) => {
                if (res && res.length > 0) {
                  setAlertModal({
                    cases: res,
                    kind: 'override',
                    type: 'add',
                    selectedDates: selectedDates || [],
                    slots,
                  });
                } else {
                  await addUserAvailability(userInfo.id, {
                    ...slot,
                    other_slots_at_same_request: otherSlotsAtSameRequest,
                  }).then((r) => {
                    setDateOverrides((prev) => [
                      ...prev,
                      {
                        ...r,
                        start_time: convertTimeFormat(r.start_time),
                        end_time: r.end_time === '12:00am' ? '23:59:59' : convertTimeFormat(r.end_time),
                      },
                    ]);
                  });
                }
              });
            }),
          );
        } catch (error: unknown) {
          setAddDateModal(false);
          setLoading(false);
          displayErrorDetails(error);
        } finally {
          setLoading(false);
          setAddDateModal(false);
          setSelectedDates([]);
          setRequestSending(false);
        }
      }
    })();
  };

  const isApplyButtonDisabled = ((dates: Date[] | null, overrides: OverrideHour[]): boolean => {
    if (!dates || dates.length === 0) return true;
    if (overrides.length > 0) {
      const isStartTimeEmpty = overrides.some((hour) => hour.start === '');
      const isEndTimeEmpty = overrides.some((hour) => hour.end === '');
      const isTypeEmpty = overrides.some((hour) => hour.type.length === 0);
      return isStartTimeEmpty || isEndTimeEmpty || (isTypeEmpty && userInfo.role.name === UserRoleName.Provider);
    }
    return false;
  })(selectedDates, overrideHours);

  const handleTimeChange = (val: Option, hour: OverrideHour, type: 'start' | 'end', index: number): void => {
    if (type === 'start' && hour.end) {
      let filteredTimeOptions = timeOptions;

      const endTimeOption = timeOptions.find((option) => option.value === hour.end);
      const endTimeId = endTimeOption?.id;
      const isEndTimeMidnight = hour.end === '12:00am';

      if (endTimeId) {
        filteredTimeOptions = timeOptions.filter((option) => {
          return isEndTimeMidnight || Number(option.id) <= Number(endTimeId) - 1;
        });
      }

      if (!filteredTimeOptions.find((option) => option.value === val.value)) {
        toast.error('Start time must be at least 30 minutes before end time');
        return;
      }
    }

    setOverrideHours((prev) => {
      const newOverrides = [...prev];
      newOverrides[index][type] = val.value;
      return newOverrides;
    });
  };

  const getOptionFromHour = (hour: string): Option => {
    return {
      value: hour,
      label: hour,
    } as Option;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, type: string, index: number): void => {
    const { checked } = e.target;

    setOverrideHours((prev) =>
      prev.map((override, i) =>
        i === index
          ? {
              ...override,
              type: checked ? [...override.type, type] : override.type.filter((t) => t !== type),
            }
          : override,
      ),
    );
  };

  const renderTimeInputContainer = (hour: Hour, index: number): JSX.Element => {
    const isToday = selectedDates && selectedDates.length === 1 && selectedDates[0].getDate() === new Date().getDate();

    return (
      <div className={styles.selectHoursInputContainer} key={hour.id}>
        <TimeInput
          timeOptions={
            overrideHours[index].end
              ? startTimeOptions({ end_time: overrideHours[index].end } as ProviderAvailability, isToday || false)
              : isToday
              ? todaysTimeOptions
              : timeOptions
          }
          time={getOptionFromHour(hour.start)}
          onChange={(val) => handleTimeChange(val, hour, 'start', index)}
        />

        <span className={styles.selectHoursInputSeparator}>-</span>

        <TimeInput
          timeOptions={
            overrideHours[index].start
              ? endTimeOptions({ start_time: overrideHours[index].start } as ProviderAvailability)
              : isToday
              ? todaysTimeOptions
              : timeOptions
          }
          time={getOptionFromHour(hour.end)}
          onChange={(e) => handleTimeChange(e, hour, 'end', index)}
        />

        <div className={styles.selectHoursInputContainerRemove}>
          <button
            className={styles.selectHoursInputContainerRemoveButton}
            type="button"
            onClick={() => setOverrideHours(overrideHours.filter((_, i) => i !== index))}
            aria-label="Remove time slot"
          >
            <BsTrash3 size={18} color={getPrimaryColor()} />
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {}, [overrideHours]);

  const renderCheckbox = (type: string, hour: Hour, index: number): JSX.Element => (
    <div className={styles.checkInput} key={type}>
      <div className="form-check m-0" style={{ minHeight: 'unset' }}>
        <input
          type="checkbox"
          name={type}
          className="form-check-input"
          checked={hour.type.includes(type)}
          onChange={(e) => handleCheckboxChange(e, type, index)}
        />
        <label className={styles.selectHoursTypeSelectText} htmlFor={type}>
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </label>
      </div>
    </div>
  );

  const renderSelectHoursType = (hour: Hour, index: number): JSX.Element => (
    <div className={styles.selectHoursType} key={hour.id}>
      <span className={styles.selectHoursTitle}>Type:</span>
      <div className={styles.selectHoursTypeSelect}>
        {renderCheckbox('synchronous', hour, index)}
        {renderCheckbox('asynchronous', hour, index)}
      </div>
    </div>
  );

  const renderUnavailabilityOfSelectedDates = (unavailable: boolean): JSX.Element | null => {
    return (
      <div
        className={styles.checkInput}
        style={{
          width: '100%',
          minHeight: 'unset',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ marginLeft: '24px', opacity: !unavailable ? 0.5 : 1 }} className={styles.unavailableCheckbox}>
          <input
            type="checkbox"
            name="unavailable"
            className="form-check-input"
            checked={unavailable}
            onChange={(e) => {
              if (!e.target.checked) {
                setOverrideHours([
                  {
                    start: '',
                    end: '',
                    type: UserRoleName.Provider === userInfo.role.name ? [] : ['synchronous', 'asynchronous'],
                    id: -1,
                  },
                ]);
              } else {
                setOverrideHours([]);
              }
            }}
          />
          <label className={styles.selectHoursTypeSelectText} htmlFor="unavailable" style={{ fontSize: '14px' }}>
            Unavailable all day (12:00am - 11:59pm)
          </label>
        </div>
        <hr className={styles.selectHoursSeparator} />
        {unavailable && (
          <div className={styles.selectHoursInputContainerAdd} style={{ alignSelf: 'flex-end' }}>
            <button
              className={styles.selectHoursInputContainerAddButton}
              type="button"
              onClick={() => setOverrideHours([{ start: '', end: '', type: [], id: 0 }])}
              aria-label="Remove time slot"
            >
              <span className={styles.selectHoursTypeSelectText} style={{ fontSize: '14px' }}>
                Add custom hours
              </span>{' '}
              <BsPlusCircleFill size={18} color={getPrimaryColor()} />
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderSelectHoursContainer = (hour: OverrideHour, index: number): JSX.Element | null => (
    <div className={styles.selectHoursContainer}>
      <div className={styles.selectHoursTitle}>
        <span>What hours are you available?</span>
        <span onClick={() => addNewRow()}>
          <HiPlusCircle size={24} color={getPrimaryColor()} />
        </span>
      </div>
      <div className={styles.selectHoursWrapper}>
        <div className={styles.selectHours} key={hour.id}>
          {renderTimeInputContainer(hour, index)}
          {userInfo.role.name === UserRoleName.Provider && renderSelectHoursType(hour, index)}
          {/* {index !== overrideHours.length - 1 && <hr className={styles.selectHoursSeparator} />} */}
        </div>
      </div>
    </div>
  );

  const renderButtons = (): JSX.Element => {
    const buttonsContainerStyle = screenSize.width >= 1024 ? styles.btnContainer : styles.btnContainerOnMobile;

    return (
      <div className={buttonsContainerStyle}>
        <div
          className={styles.cancelBtn}
          onClick={(): void => {
            setSelectedDates([]);
            setAddDateModal(false);
          }}
        >
          Cancel
        </div>
        <Button
          onClick={(): void => onSubmit()}
          className={styles.customBtn}
          disabled={isApplyButtonDisabled}
          style={{
            backgroundColor: isApplyButtonDisabled ? '#D0D0D0' : '',
            border: isApplyButtonDisabled ? '#D0D0D0' : '',
          }}
        >
          Apply
        </Button>
      </div>
    );
  };

  const renderLoadingContent = (): JSX.Element => (
    <div className={styles.loadingContainer}>
      <Loading dotted />
    </div>
  );

  return (
    <Modal
      isOpen={addDateModal}
      style={screenSize.width > 1024 ? selectDateModalCustomStyles : selectDateModalCustomStylesMobileExtended}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <div className={styles.container}>
        {loading ? (
          renderLoadingContent()
        ) : (
          <>
            <div className={styles.title}>Select the date(s) you want to assign specific hours</div>
            <div className={styles.content}>
              <Calendar selectedDates={selectedDates} setSelectedDates={setSelectedDates} isProvider />
              {selectedDates && selectedDates.length > 0 ? (
                <>
                  {renderUnavailabilityOfSelectedDates(overrideHours.length === 0)}
                  {overrideHours.length > 0 && (
                    <>
                      <div className={styles.timezoneText}>Time Zone: {timezone}</div>
                      {overrideHours.map((hour, index) => renderSelectHoursContainer(hour, index))}
                    </>
                  )}
                </>
              ) : null}
            </div>
            {renderButtons()}
          </>
        )}
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default SelectDate;
