import React, { SetStateAction } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

const painScaleOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

type SubjectiveSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const SubjectiveSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: SubjectiveSectionProps): JSX.Element => {
  const saveCaseAttribute = async (attributeName: string, attributeValue: any): Promise<void> => {
    if (attributeValue) {
      const updateData = {
        [attributeName]: attributeValue,
      };

      const response = await updateOneReport(thisCase?.one_report.id || '', updateData);

      setCase({
        ...thisCase,
        one_report: {
          ...response,
        },
      });
    }
  };

  return (
    <div className={styles.subjectiveDiv}>
      <div className={styles.subjective}>
        <span>Chief Complaint/Problem*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.subjective?.chief_complaint_problem ? '#BA271A' : '' }}
            {...register('subjective.chief_complaint_problem')}
            value={consultationForm.subjective?.chief_complaint_problem || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                subjective: {
                  ...consultationForm.subjective,
                  chief_complaint_problem: e.target.value,
                },
              });

              setValue('subjective.chief_complaint_problem', e.target.value);

              if (errors?.subjective?.chief_complaint_problem) {
                trigger('subjective.chief_complaint_problem');
              }
            }}
            onBlur={() => {
              saveCaseAttribute('chief_complaint_problem', consultationForm.subjective.chief_complaint_problem);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.chief_complaint_problem || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.subjective?.chief_complaint_problem ? '#BA271A' : '' }}
          />
        )}
      </div>
      <div className={styles.painScaleDiv}>
        <span>Pain Scale (1-10):*</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          painScaleOptions &&
          painScaleOptions.map((element) => (
            <div key={element}>
              <label
                className={`${styles.btn} ${
                  consultationForm?.subjective?.pain_scale === element ? styles.selectedClass : ''
                }`}
                htmlFor={element.toString()}
              >
                {element}
                <div
                  className={`${styles.bntIcon} ${
                    consultationForm?.subjective?.pain_scale === element ? styles.primaryColor : ''
                  }`}
                />
              </label>
              <input
                {...register('subjective.pain_scale')}
                type="radio"
                className={`form-check-input ${styles.eduFormCheckInput}`}
                value={element}
                id={element.toString()}
                onChange={(e) => {
                  setConsultationForm({
                    ...consultationForm,
                    subjective: {
                      ...consultationForm.subjective,
                      pain_scale: e.target.value,
                    },
                  });

                  setValue('subjective.pain_scale', e.target.value);
                  saveCaseAttribute('pain_scale', Number(e.target.value));
                  if (errors?.subjective?.pain_scale) {
                    trigger('subjective.pain_scale');
                  }
                }}
              />
            </div>
          ))
        ) : (
          <span>{thisCase?.one_report?.pain_scale || ''}</span>
        )}
      </div>

      <div className={styles.medicalHistoryReview}>
        <span>Medical History Reviewed with the patient/guardian including:*</span>
        <span>Medical Conditions, Medications, Allergies, Prosthetics, Preventative Antibiotic Coverage</span>
        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <textarea
            className={styles.subjectiveInput}
            style={{ color: errors.subjective?.medical_history ? '#BA271A' : '' }}
            {...register('subjective.medical_history')}
            value={consultationForm.subjective?.medical_history || ''}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                subjective: {
                  ...consultationForm.subjective,
                  medical_history: e.target.value,
                },
              });

              setValue('subjective.medical_history', e.target.value);

              if (errors?.subjective?.medical_history) {
                trigger('subjective.medical_history');
              }
            }}
            onBlur={() => {
              saveCaseAttribute('medical_history', consultationForm.subjective.medical_history);
            }}
          />
        ) : (
          <textarea
            disabled
            value={thisCase?.one_report?.medical_history || ''}
            className={styles.subjectiveInput}
            style={{ color: errors.subjective?.medical_history ? '#BA271A' : '' }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SubjectiveSection);
