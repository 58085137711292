import { client } from '../client';

export interface Payload {
  email: string;
  confirmation_code: string;
}

export interface VerifyConfirmationResponse {
  success: boolean;
}
export const verifyConfirmationCode = async (payload: Payload): Promise<VerifyConfirmationResponse> => {
  const { data } = await client.post<VerifyConfirmationResponse>(`/auth/verify_confirmation_code`, payload);

  return data;
};
