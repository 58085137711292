import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HandoffState {
  isFromHandoff: boolean;
}

const initialState: HandoffState = {
  isFromHandoff: false,
};

const isFromHandOffSlice = createSlice({
  name: "isFromHandOff",
  initialState,
  reducers: {
    setIsFromHandoff(state, action: PayloadAction<boolean>) {
      state.isFromHandoff = action.payload;
    },
  },
});

export const { setIsFromHandoff } = isFromHandOffSlice.actions;

export default isFromHandOffSlice.reducer;
