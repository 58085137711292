import React from 'react';

import { useWindowSize } from '../../../../hooks';
import { ICase } from '../../../../services/cases/types/ICase';
import { getPainTypeText } from '../../../../Utils/getFriendlyName';
import { mapPainLevelLabel } from '../../../../Utils/mapPainLevelLabel';
import styles from '../../styles.module.scss';

type ConsultationOHRHeaderProps = {
  thisCase: ICase;
};

const ConsultationOHRHeader = ({ thisCase }: ConsultationOHRHeaderProps): JSX.Element => {
  const screenSize = useWindowSize();

  return (
    <div className={styles.detailCards} style={{ paddingLeft: '0px' }}>
      <div className={styles.detailCard}>
        <div className={styles.detailCardTitle}>Emergency Case</div>
        <div className={styles.detailCardContent}>
          {thisCase?.purpose && thisCase?.purpose[0] === 'dental_emergency' ? 'Yes' : 'No'}
        </div>
      </div>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ borderLeft: screenSize.width >= 992 ? '1px solid #d0d0d0' : '' }}
      >
        <div className={styles.detailCardTitle}>Pain Level</div>
        <div className={`${styles.detailCardContent} d-flex`}>{mapPainLevelLabel(thisCase?.pain_level || '')}</div>
      </div>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ borderLeft: '1px solid #d0d0d0' }}
      >
        <div className={styles.detailCardTitle}>Time in Pain</div>
        <div className={styles.detailCardContent}>{thisCase?.pain_time || '-'}</div>
      </div>
      <div
        className={`${styles.detailCard} ${screenSize.width >= 992 && styles.detailCardRightMargin}`}
        style={{ borderLeft: '1px solid #d0d0d0' }}
      >
        <div className={styles.detailCardTitle}>Pain Type</div>
        <div className={styles.detailCardContent}>
          {thisCase?.pain_description?.map((pain) => getPainTypeText(pain)).join(', ') || '-'}
        </div>
      </div>
    </div>
  );
};
export default ConsultationOHRHeader;
