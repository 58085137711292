import { client } from '../client';

export interface Payload {
  selected_method: string | null;
}

export interface ManageMfaResponse {
  status: string;
  mfa_status: string;
}
export const manageMfa = async (user_id: number, payload: Payload): Promise<ManageMfaResponse> => {
  const { data } = await client.post<ManageMfaResponse>(`/auth/${user_id}/manage_mfa`, payload);

  return data;
};
