import qs from 'qs';

import { client } from '../client';
import { Pricings } from './getPricings';

export const getPricingsByAdmin = async (user_id: number): Promise<Pricings[]> => {
  const { data } = await client.get(`/admin/users/${user_id}/lowest_pricings`);

  return data;
};
