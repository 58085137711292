/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import AuthLogo from '@brands/Auth/assets/loginLogo.svg';
import Logo from '@brands/Auth/assets/logo.svg';
import useCases from '@brands/hooks/useCases';
import { CasePayload } from '@brands/services/cases/createNewCase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectVideoCallCredentials } from '@brands/store/selectors/videoCallCredentials';
import { setNotificationSettings } from '@brands/store/slices/authSlice';
import { setDependentId } from '@brands/store/slices/pageStateSlice';
import { setVCCaseId } from '@brands/store/slices/videoCallCredentialsSlice';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { CgProfile } from 'react-icons/cg';
import { FaRegBell } from 'react-icons/fa';
import { FiUser } from 'react-icons/fi';
import { GoBook } from 'react-icons/go';
import { HiOutlineAcademicCap, HiOutlineLogout } from 'react-icons/hi';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { TbCalendarEvent, TbMedicalCross } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNotifications, useSubscribe } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../hooks/useReduxHook';
import { Subscriber } from '../../hooks/useSubscribe';
import useWindowSize from '../../hooks/useWindowSize';
import { getUserAvatar } from '../../services/identity/getUserAvatar';
import { UserRoleName } from '../../services/identity/types/UserProfile';
import { updateMe } from '../../services/identity/updateMe';
import { INotification, NotificationType } from '../../services/notification/types/INotification';
import { displayErrorDetails } from '../../Utils/displayError';
import NotificationPopUp from './NotificationPopup/NotificationPopUp';
import Notifications from './Notifications';
import CreateNewConversationModal from './SidenavModals/CreateNewConversationModal';
import Logout from './SidenavModals/Logout';
import styles from './styles.module.scss';

type SidenavProps = {
  isVisibleOnMobile: boolean | undefined;
  children?: React.ReactNode;
  isProfileCard?: boolean;
  hideDefaultOptions?: boolean;
  authLoog?: boolean;
};

const Sidenav = ({
  isVisibleOnMobile,
  children,
  isProfileCard,
  hideDefaultOptions,
  authLoog,
}: SidenavProps): JSX.Element => {
  const [showSidenav, setShowSidenav] = useState<string>('');
  const [logoutModal, setLogoutModal] = useState(false);
  const [createNewConv, setCreateNewCon] = useState(false);
  const [notificationToggle, setNotificationToggle] = useState(false);
  const screenSize = useWindowSize();
  const { userInfo: userInfoInContext } = useAppSelector(selectAuth);
  const { is_visible: isVisible } = useAppSelector(selectVideoCallCredentials);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { notifications, unreadCount, loadNextPage, dataLoading, reloadAll, acknowledgeNotification } =
    useNotifications(Boolean(userInfoInContext));
  const { subscribe, unsubscribe } = useSubscribe();

  const userRole = useMemo(() => {
    if (!userInfoInContext) {
      return null;
    }

    return userInfoInContext.role.name;
  }, [userInfoInContext]);

  const handleSidenavContent = (target: string): void => {
    if (showSidenav === target) {
      setShowSidenav('');
    } else {
      setShowSidenav(target);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  function useOutsideAlerter(ref: any): void {
    useEffect(() => {
      function handleClickOutside(event: any): void {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSidenav('');
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [avatarIcon, setAvatarIcon] = useState(false);
  const handleAvatarError = (): void => {
    setAvatarIcon(true);
  };

  const handleNavigate = useCallback(
    (path: string, domain?: string): void => {
      if (domain && domain !== window.location.host) {
        window.location.href = `https://${domain}${path}`;
        return;
      }
      if (location.pathname === path) {
        navigate(0);
      } else {
        navigate(path);
      }
    },
    [location, navigate],
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      if (isVisible) {
        event.preventDefault();
        event.returnValue = '';
        window.close();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isVisible]);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
    }),
    [],
  );

  const { editCase } = useCases(params, userInfoInContext?.role?.name === UserRoleName.Provider);

  const onNotification = useCallback(
    async (notification: INotification) => {
      const { type, payload, domain } = notification;
      const { case_id: caseId, case_type: caseType, case_status: caseStatus } = payload;

      switch (type) {
        case NotificationType.appointment_reminder_hour:
        case NotificationType.appointment_reminder_min:
          if (userRole === UserRoleName.Provider) {
            if (caseStatus === 'accepted_provider') {
              await editCase(caseId, { status: 'in_progress_provider' } as CasePayload);
            }
            handleNavigate(`/consultation-page/${caseId}`);
          }

          if (userRole === UserRoleName.Patient) {
            switch (caseType) {
              case 'smile_scan':
                handleNavigate(`/smile-scan-report/${caseId}`, domain);
                break;
              case 'video_call_scheduled':
                handleNavigate(`/video-room/${caseId}`, domain);
                dispatch(setVCCaseId(caseId.toString()));
                break;
              default:
                // handle default case if needed
                break;
            }
          }
          break;
        case NotificationType.ca_vc_joined:
        case NotificationType.case_updated:
          if (userRole === UserRoleName.Provider) {
            if (caseStatus === 'accepted_provider') {
              await editCase(caseId, { status: 'in_progress_provider' } as CasePayload);
            }
            handleNavigate(`/consultation-page/${caseId}`);
          }

          if (userRole === UserRoleName.CareAdvisor) {
            handleNavigate(`/consultation-page/${caseId}`);
          }

          if (userRole === UserRoleName.Patient) {
            switch (caseType) {
              case 'smile_scan':
                handleNavigate(`/smile-scan-report/${caseId}`, domain);
                break;
              case 'video_call_scheduled':
                handleNavigate(`/video-room/${caseId}`, domain);
                dispatch(setVCCaseId(caseId.toString()));
                break;
              default:
                // handle default case if needed
                break;
            }
          }
          break;
        case NotificationType.case_created:
        case NotificationType.patient_vc_joined:
        case NotificationType.provider_vc_joined:
        case NotificationType.patient_vc_not_joined:
        case NotificationType.provider_vc_not_joined:
        case NotificationType.instant_vc_not_started:
          if (userRole === UserRoleName.Patient) {
            handleNavigate(`/video-room/${caseId}`, domain);
            dispatch(setVCCaseId(caseId.toString()));
          } else {
            handleNavigate(`/consultation-page/${caseId}`);
          }
          break;
        case NotificationType.message:
          if (caseId) {
            handleNavigate(
              `/case-chat/${caseId}/${payload.conversation_sid}`,
              userRole === UserRoleName.Patient ? domain : undefined,
            );
          }
          break;
        case NotificationType.case_completed:
          handleNavigate(`/oral-health-report/${caseId}`, userRole === UserRoleName.Patient ? domain : undefined);
          break;
        case NotificationType.case_canceled:
          if (userRole !== UserRoleName.Patient && userRole !== UserRoleName.Provider) {
            handleNavigate(`/consultation-page/${caseId}`);
          }
          break;
        case NotificationType.cc_reauthorization_failed:
          if (!payload.update_disabled) {
            handleNavigate(`/appointments?edit=${caseId}`, userRole === UserRoleName.Patient ? domain : undefined);
          }
          break;
        default:
          break;
      }
      acknowledgeNotification(notification.id);
    },
    [acknowledgeNotification, dispatch, handleNavigate, userRole],
  );

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: any) => {
        const { notification } = message;
        if (notification) {
          enqueueSnackbar(notification.body, {
            key: notification.id,
            content: (key) => (
              <NotificationPopUp id={key} notification={notification} onNotification={onNotification} />
            ),
            autoHideDuration: 10000,
            disableWindowBlurListener: true,
          });
        }

        reloadAll();
      },
      commands: ['notification_created'],
    }),
    [onNotification, reloadAll, enqueueSnackbar],
  );

  const updateNotificationSettings = async (smsOptIn: boolean, emailOptIn: boolean): Promise<void> => {
    if (!smsOptIn && !emailOptIn) {
      displayErrorDetails('At least one notification method must be enabled');
      return;
    }

    try {
      await dispatch(setNotificationSettings({ sms: smsOptIn, email: emailOptIn }));
      await updateMe(
        {
          email_opt_in: emailOptIn,
          sms_opt_in: smsOptIn,
        },
        Number(userInfoInContext.id),
      );
    } catch (error: unknown) {
      displayErrorDetails(error);
    }
  };

  const openZendesk = (): void => {
    window.open('https://dentistryone.zendesk.com', '_blank', 'noreferrer');
  };

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscriber, subscribe, unsubscribe]);

  return (
    <div
      className={styles.topSidenav}
      style={{ display: (!isVisibleOnMobile && screenSize.width <= 564) || isProfileCard ? 'none' : 'flex' }}
      ref={wrapperRef}
    >
      <div className={styles.sidenav}>
        <div className={styles.sidenavHeader}>
          <button
            type="button"
            onClick={() =>
              hideDefaultOptions
                ? navigate('/')
                : userRole === UserRoleName.Admin ||
                  userRole === UserRoleName.SuperAdmin ||
                  userRole === UserRoleName.OrganizationAdmin
                ? navigate('/admin/dashboard')
                : navigate('/dashboard')
            }
            style={{ cursor: 'pointer' }}
          >
            <img className={styles.logoCorner} src={authLoog ? AuthLogo : Logo} alt="Logo" />
          </button>
          <div className={styles.sidenavIcons}>
            {children}
            {hideDefaultOptions ? null : (
              <>
                <div onClick={() => handleSidenavContent('profile')} id="top-right-menu-profile-button">
                  <div className={`${styles.sidenavToggle} ${showSidenav === 'profile' && styles.sidenavToggleActive}`}>
                    <FiUser />
                  </div>
                </div>
                <div onClick={() => handleSidenavContent('notification')} id="top-right-menu-notifications-button">
                  <div
                    className={`${styles.sidenavBell} ${showSidenav === 'notification' && styles.sidenavBellActive}`}
                  >
                    <FaRegBell />
                    {Boolean(unreadCount) && (
                      <div className={styles.notificationUnreadCount}>{unreadCount >= 0 ? unreadCount : 0}</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {showSidenav === 'notification' && (
          <Notifications
            notifications={notifications}
            onNotification={onNotification}
            loadNextPage={loadNextPage}
            dataLoading={dataLoading}
          />
        )}
        {showSidenav === 'profile' && (
          <div className={styles.sidenavBody}>
            <div className={styles.sidenavProfileInfo}>
              <div>
                {!avatarIcon ? (
                  <img
                    alt="Avatar"
                    onError={handleAvatarError}
                    className={styles.sidenavImage}
                    src={userInfoInContext ? getUserAvatar(userInfoInContext?.id) : ''}
                  />
                ) : (
                  <CgProfile />
                )}
              </div>
              <div className={styles.sidenavProfileName}>
                {userInfoInContext.full_name ? userInfoInContext.full_name : userInfoInContext.userInfo?.email}
              </div>
            </div>
            <hr />
            <div className={styles.sidenavInfo}>
              <ul style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                <li>
                  <div
                    onClick={() => {
                      if (
                        userRole === UserRoleName.Admin ||
                        userRole === UserRoleName.SuperAdmin ||
                        userRole === UserRoleName.OrganizationAdmin
                      ) {
                        navigate('/admin/my-profile');
                      } else if (userRole === UserRoleName.Patient) {
                        dispatch(setDependentId(null));
                        navigate('/my-profile');
                      } else {
                        navigate('/my-profile');
                      }
                    }}
                  >
                    <CgProfile />
                    <span className={styles.infoHeaderText}>My Profile</span>
                  </div>
                </li>
                {userRole === UserRoleName.Patient && (
                  <li>
                    <div
                      onClick={() =>
                        navigate('/medical-information-summary', {
                          state: {
                            fromPatientIntake: false,
                          },
                        })
                      }
                    >
                      <TbMedicalCross />
                      <span className={styles.infoHeaderText}>Medical Information</span>
                    </div>
                  </li>
                )}
                {userRole !== UserRoleName.CareAdvisor && (
                  <li>
                    <div onClick={() => setNotificationToggle(!notificationToggle)}>
                      <FaRegBell />
                      <span className={styles.infoHeaderText}>Notification Settings</span>
                    </div>
                    {notificationToggle && (
                      <div className={styles.notificationOptions}>
                        <label className={styles.checkbox} aria-label="Phone">
                          <input
                            type="checkbox"
                            className={`${styles.formCheckInput} form-check-input opacity-100`}
                            id="phone"
                            checked={userInfoInContext.sms_opt_in}
                            onChange={() => {
                              updateNotificationSettings(!userInfoInContext.sms_opt_in, userInfoInContext.email_opt_in);
                            }}
                          />
                          <span>
                            <label htmlFor="phone" aria-label="Phone">
                              Phone
                            </label>
                          </span>
                        </label>
                        <label className={styles.checkbox} aria-label="Email">
                          <input
                            type="checkbox"
                            className={styles.formCheckInput}
                            id="email"
                            checked={userInfoInContext.email_opt_in}
                            onChange={() => {
                              updateNotificationSettings(userInfoInContext.sms_opt_in, !userInfoInContext.email_opt_in);
                            }}
                          />
                          <span>
                            <label htmlFor="email">Email</label>
                          </span>
                        </label>
                      </div>
                    )}
                  </li>
                )}
              </ul>
              {!(
                userRole === UserRoleName.Provider ||
                userRole === UserRoleName.Admin ||
                userRole === UserRoleName.SuperAdmin ||
                userRole === UserRoleName.OrganizationAdmin
              ) && (
                <>
                  <hr />
                  <div className={styles.infoHeader} onClick={() => navigate(`/appointments`)}>
                    <TbCalendarEvent />
                    {userRole === UserRoleName.Patient ? (
                      <div className={styles.infoHeaderText}>My Record</div>
                    ) : (
                      <div className={styles.infoHeaderText}>My History</div>
                    )}
                  </div>
                </>
              )}
              {userRole === UserRoleName.CareAdvisor && (
                <>
                  <hr />
                  <div className={styles.infoHeader} onClick={() => navigate(`/scheduleAvailability`)}>
                    <MdOutlineEditCalendar />
                    <div className={styles.infoHeaderText}>Schedule/Availability</div>
                  </div>
                </>
              )}
              {(userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor) && (
                <>
                  <hr />
                  <a
                    href="https://patienthealthcenter.com/mgkOQvgp/home"
                    style={{ textDecoration: 'none', color: 'unset' }}
                    target="_blank"
                    rel="noreferrer"
                    className="mb-1"
                  >
                    <HiOutlineAcademicCap />
                    <span className={`${styles.infoHeader} ${styles.infoHeaderText}`}>Education</span>
                  </a>
                  <a
                    href="https://dentistry-one-learning.thinkific.com/"
                    style={{ textDecoration: 'none', color: 'unset' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GoBook />
                    <span className={`${styles.infoHeader} ${styles.infoHeaderText}`}>Training</span>
                  </a>
                  <hr />
                </>
              )}
              {(userRole === UserRoleName.Patient ||
                userRole === UserRoleName.Admin ||
                userRole === UserRoleName.SuperAdmin ||
                userRole === UserRoleName.OrganizationAdmin) && <hr />}
              <div
                className={styles.infoHeader}
                onClick={() => {
                  if (userRole === UserRoleName.Patient) {
                    window.open('https://dentistryone.zendesk.com/hc/en-us', '_blank', 'noreferrer');
                  } else {
                    openZendesk();
                  }
                }}
              >
                <AiOutlineQuestionCircle />
                <div className={styles.infoHeaderText}>Help</div>
              </div>

              <hr />
              <div
                className={styles.infoHeader}
                onClick={() => {
                  setLogoutModal(true);
                }}
              >
                <HiOutlineLogout />
                <div className={styles.infoHeaderText}>Logout</div>
              </div>
              <div />
            </div>
          </div>
        )}
      </div>
      {logoutModal && <Logout setOpenModal={setLogoutModal} />}
      {createNewConv && <CreateNewConversationModal setOpenModal={setCreateNewCon} />}
    </div>
  );
};

Sidenav.defaultProps = {
  children: null,
  isProfileCard: false,
  hideDefaultOptions: false,
  authLoog: false,
};

export default Sidenav;
