/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';

import { ICase } from '../../../services/cases/types/ICase';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import { Treatment } from './types';

export const validationSchema = (userInfo: any, thisCase: ICase): any => {
  const checkAllFieldsFilled = (treatment: Treatment): boolean => {
    if (treatment) {
      const { category, tooth, toothArea, description, urgency } = treatment;
      if (tooth.value && toothArea.value && category.value && description.trim() !== '' && urgency.value) {
        return true;
      }
    }
    return false;
  };

  return Yup.object({
    checkboxes:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.array().test(
            'all-fields-filled',
            'Failed to check one or more check boxes. Please update to submit your Oral Health Report',
            (checkboxes = []) => {
              if (checkboxes.length === 0) {
                return true;
              }
              const isFilled = checkboxes.some((checkbox) => !checkbox.isSelected);
              return !isFilled;
            },
          )
        : Yup.array().of(
            Yup.object({
              value: Yup.string(),
              label: Yup.string(),
              isSelected: Yup.boolean(),
            }),
          ),
    isAdditionalPeople: Yup.boolean(),
    additionalPeople: Yup.string().when('isAdditionalPeople', {
      is: true,
      then: Yup.string().required('This field is required to be filled because you have selected yes'),
    }),
    selectedImages: Yup.array().nullable(),
    cdt:
      (userInfo.role.name === UserRoleName.CareAdvisor ||
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin) &&
      (thisCase?.status === 'accepted_care_advisor' || thisCase?.status === 'in_progress_care_advisor')
        ? Yup.array().nullable()
        : Yup.array()
            .test('at-least-one-service-code', 'Please enter at least one service CDT code', (value) => {
              if (!value || value.length === 0) {
                return false;
              }

              if (value.length === 1 && (value[0] === 'D9995' || value[0] === 'D9996')) {
                return false;
              }

              const hasServiceCode = value.some((code) => code !== 'D9995' && code !== 'D9996');
              return hasServiceCode;
            })
            .required('CDT is required'),
    assessment:
      (userInfo.role.name === UserRoleName.CareAdvisor ||
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin) &&
      (thisCase?.status === 'accepted_care_advisor' || thisCase?.status === 'in_progress_care_advisor')
        ? Yup.array().nullable()
        : Yup.string().trim().required('Please fill the assessment field'),
    subjective:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.object().shape({
            chief_complaint_problem: Yup.string().trim().required('Subjective: Chief Complains/Problems is required'),
            pain_scale: Yup.string().required('Subjective: Pain Scale is required'),
            medical_history: Yup.string()
              .trim()
              .required('Subjective: Medical History reviewed with the patient/guardian is required'),
          })
        : Yup.object().shape({
            chief_complaint_problem: Yup.string().trim(),
            pain_scale: Yup.string(),
            medical_history: Yup.string().trim(),
          }),
    objective:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.object().shape({
            is_media_utilized: Yup.string().required('Objective: Photos, Videos, Radiographs is required'),
            is_intra_oral_utilized: Yup.string().required('Objective: Intra-Oral is required'),
            is_extra_oral_utilized: Yup.string().required('Objective: Extra-Oral is required'),
          })
        : Yup.object().shape({
            is_media_utilized: Yup.string(),
            is_intra_oral_utilized: Yup.string(),
            is_extra_oral_utilized: Yup.string(),
          }),
    plan:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.object().shape({
            disposition: Yup.string()
              .trim()
              .required('Plan: Disposition/Intervention or recommendations provided is required'),
            prescription_in_icore: Yup.mixed().nullable(),
            pharmacy: Yup.mixed().nullable(),
            need_for_oral_health_coaching: Yup.mixed().nullable(),
          })
        : Yup.object().shape({
            disposition: Yup.string(),
            prescription_in_icore: Yup.mixed().nullable(),
            pharmacy: Yup.mixed().nullable(),
            need_for_oral_health_coaching: Yup.mixed().nullable(),
          }),
    treatments:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.array().test('all-fields-filled', 'Please complete all treatment fields', (treatments = []) => {
            if (treatments.length === 0) {
              return true;
            }
            const emptyRow = treatments.some((treatment) => {
              const { category, tooth, urgency, description, toothArea } = treatment;
              return (
                category?.value === '' &&
                tooth?.value === '' &&
                urgency?.value === '' &&
                description?.trim() === '' && // Updated condition for description
                toothArea?.value === ''
              );
            });
            return emptyRow ? false : treatments.every((treatment) => checkAllFieldsFilled(treatment));
          })
        : Yup.array().of(
            Yup.object({
              category: Yup.object(),
              tooth: Yup.object(),
              urgency: Yup.object(),
              description: Yup.string().trim(), // Added trim() for description
              toothArea: Yup.object(),
            }),
          ),
    educations:
      userInfo.role.name === UserRoleName.Provider || userInfo.role.name === UserRoleName.CareAdvisor
        ? Yup.array().test('all-fields-filled', 'Please complete all educations fields', (educations = []) => {
            if (educations.length === 0) {
              return true;
            }

            const isFilled = educations.some((education) => {
              const { url, title } = education;
              return url.trim() === '' || title.trim() === '';
            });

            return !isFilled;
          })
        : Yup.array().of(
            Yup.object({
              url: Yup.string(),
              title: Yup.string(),
            }),
          ),
    caseNotes: Yup.string(),
  });
};
