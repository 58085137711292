import useCareAdvisor from '@brands/hooks/useCareAdvisor';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { Dispatch, FormEventHandler, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import { IAssignCACase } from '../../../../services/cases/assignCareAdvisor';
import { getOneCase } from '../../../../services/cases/getCaseById';
import { ICase } from '../../../../services/cases/types/ICase';
import { EnforceCAAssignModal } from '../../../../services/forms/types/ImageNote';
import { displayErrorDetails } from '../../../../Utils/displayError';
import styles from './styles.module.scss';

interface Modal {
  setOpenModal: React.Dispatch<React.SetStateAction<EnforceCAAssignModal>>;
  caId: number;
  caseId: number;
  setLoading: Dispatch<SetStateAction<boolean>>;
  thisCase?: ICase;
  setCase?: React.Dispatch<React.SetStateAction<ICase>>;
  assignToCareAdvisor: (case_id: number, body: IAssignCACase) => Promise<ICase | null>;
}

const EnforceAssignCAModal = ({
  setOpenModal,
  caId,
  caseId,
  setLoading,
  thisCase,
  setCase,
  assignToCareAdvisor,
}: Modal): JSX.Element => {
  const navigate = useNavigate();
  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      limit: 1000,
    }),
    [],
  );

  const { careAdvisors } = useCareAdvisor(params, true);
  const [loadingUntillAccept, setLoadingUntillAccept] = useState(true);
  const [existCAName, setExistCAName] = useState('');
  const { userInfo } = useAppSelector(selectAuth);

  useEffect(() => {
    setLoadingUntillAccept(true);
    const getCurrentCase = async (): Promise<void> => {
      const currentCase = await getOneCase(caseId);
      setExistCAName(currentCase.care_advisor.full_name);
      setLoadingUntillAccept(false);
    };

    getCurrentCase();
  }, [caseId]);

  const enforceAssignCA = async (): Promise<void> => {
    try {
      setLoadingUntillAccept(true);
      if (setLoading) setLoading(true);
      const updatedCase = await assignToCareAdvisor(caseId, { care_advisor_id: caId, enforce_assignment: true });
      if (updatedCase && setCase && thisCase) {
        setCase({
          ...thisCase,
          status: updatedCase.status,
          care_advisor: updatedCase.care_advisor,
          care_advisor_id: updatedCase.care_advisor_id,
          updated_at: updatedCase.updated_at,
        });
      }
      navigate(`/consultation-page/${caseId}`);
    } catch (error: any) {
      displayErrorDetails(error);
    } finally {
      if (setLoading) setLoading(false);
      setLoadingUntillAccept(false);
    }
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    enforceAssignCA();
    setOpenModal({ isOpen: false, caId: 0 });
  };

  if (loadingUntillAccept) {
    return <Loading fullScreen />;
  }

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.modalCloseIcon}>
          <button type="button" onClick={() => setOpenModal({ isOpen: false, caId: 0 })} aria-label="Close Modal">
            <AiFillCloseCircle />
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>
                {userInfo.id === caId
                  ? `This case is already assigned to ${existCAName}, please confirm that you want to reassign this case
                      to yourself`
                  : `This case is already assigned to ${existCAName}, please confirm that you want to reassign this case
                to ${careAdvisors.find((ca) => ca.id === caId)?.full_name}`}
              </span>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button type="button" onClick={() => setOpenModal({ isOpen: false, caId: 0 })}>
              Cancel
            </button>
            <button type="submit">{userInfo.id === caId ? 'Reassign case to me' : 'Reassign case'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

EnforceAssignCAModal.defaultProps = {
  thisCase: undefined,
  setCase: undefined,
};
export default EnforceAssignCAModal;
