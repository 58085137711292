/* eslint-disable import/no-cycle */
import { Meta } from '@brands/services/communication/types/Meta';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

import {
  IListAvailableProviderParams,
  listAvailableProvidersDate,
} from '../services/identity/listAvailableProvidersDate';
import { UserProfile } from '../services/identity/types/UserProfile';
import { isValidDateFormat } from '../Utils/validation';

interface IUseUsers {
  providers: IListAvailableProvider[];
  dataLoading: boolean;
  loadNextPage: () => Promise<void>;
  refreshProviders: () => void;
}

export interface IslotTime {
  end_time: string;
  start_time: string;
  synchronous: boolean;
  asynchronous: boolean;
}

export function sortSlotTimes(slotTimes: IslotTime[]): IslotTime[] {
  const sortedSlotTimes = slotTimes.sort((a, b) => {
    return new Date(a.start_time).getTime() - new Date(b.start_time).getTime();
  });

  return sortedSlotTimes;
}

interface IListAvailableProvider {
  created_at: string;
  id: number;
  slot_times: IslotTime[];
  updated_at: string;
  user: UserProfile;
}

export default function useAvailableProviders(
  params: Pick<
    IListAvailableProviderParams,
    | 'sort_by'
    | 'sort_direction'
    | 'limit'
    | 'date'
    | 'tz'
    | 'carrier_id'
    | 'language'
    | 'state'
    | 'license_states'
    | 'patient_id'
  >,
): IUseUsers {
  const [providers, setProviders] = useState<IListAvailableProvider[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [firstPageMeta, setFirstPageMeta] = useState<Meta | null>(null);
  const [lastPageMeta, setLastPageMeta] = useState<Meta | null>(null);

  const loadNextPage = async (): Promise<void> => {
    if (!lastPageMeta?.has_next_page) {
      return;
    }
    const { meta, nodes } = await listAvailableProvidersDate({
      cursor: lastPageMeta.end_cursor,
      cursor_direction: 'next',
      ...params,
    });
    setLastPageMeta(meta);
    if (!firstPageMeta) {
      setFirstPageMeta(meta);
    }
    setProviders((prevProviders) => uniqBy([...prevProviders, ...nodes], 'id'));
  };

  useEffect(() => {
    let canceled = false;
    if (params.date !== '' && isValidDateFormat(params.date)) {
      setDataLoading(true);
      listAvailableProvidersDate(params).then((usersList) => {
        if (!canceled) {
          setProviders(usersList.nodes);
          setFirstPageMeta(usersList.meta);
          setLastPageMeta(usersList.meta);
          setDataLoading(false);
        }
      });
    }
    return (): void => {
      canceled = true;
    };
  }, [params]);

  const refreshProviders = (): void => {
    if (params.date !== '') {
      setDataLoading(true);
      listAvailableProvidersDate(params).then((usersList) => {
        setProviders(usersList.nodes);
        setDataLoading(false);
      });
    }
  };

  return {
    providers,
    dataLoading,
    loadNextPage,
    refreshProviders,
  };
}
