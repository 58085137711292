import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useIdentityContext, useLocalStorageState } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { CaseType, ICase } from '../../../services/cases/types/ICase';
import { EnforceCAAssignModal } from '../../../services/forms/types/ImageNote';
import { displayErrorDetails } from '../../../Utils/displayError';
import EnforceAssignCAModal from '../../ConsultationPage/Modals/EnforceAssignCAModal/EnforceAssignCAModal';
import AllCases from './AllCases/AllCases';
import styles from './careAdvisorDashboard.module.scss';
import MyCases from './MyCases/MyCases';
import OpenCases from './OpenCases/OpenCases';

const CareConciergeDashboard = (): JSX.Element => {
  const { userInfo } = useIdentityContext();
  const userId = userInfo?.id;
  const [activeTab, setActiveTab] = useLocalStorageState<string>('careAdvisorDashboardActiveTab', 'openCases');
  const [loadingUntillAccept, setLoadingUntillAccept] = useState(false);
  const [enforceCaAssignmentModal, setEnforceCaAssignmentModal] = useState<EnforceCAAssignModal>({
    isOpen: false,
    caId: 0,
    caseId: 0,
  });
  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      status: ['new', 'accepted_provider', 'in_progress_provider', 'completed_by_provider'],
      limit: 15,
      care_advisor_id: -1,
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
      ],
    }),
    [],
  );

  const { cases, reloadAll, loadNextPage, assignToCareAdvisor, totalNewCases, dataLoading } = useCases(params, true);

  const setTab = (targetTab: string): void => {
    setActiveTab(targetTab);
  };

  const callReloadAllOpenCase = (): void => {
    reloadAll();
  };

  const navigate = useNavigate();

  const acceptThisCase = async (selectedCase: ICase): Promise<void> => {
    if (userId) {
      try {
        const response = await assignToCareAdvisor(selectedCase.id, { care_advisor_id: userId });
        if (response) navigate(`/consultation-page/${selectedCase.id}`);
        else {
          setLoadingUntillAccept(false);
          setEnforceCaAssignmentModal({
            isOpen: true,
            caId: userId,
            caseId: selectedCase.id,
          });
        }
      } catch (error: any) {
        displayErrorDetails(error);
        reloadAll();
      }
    }
  };

  if (loadingUntillAccept) {
    return <Loading fullScreen />;
  }

  return (
    <Container
      isBGEclipse={false}
      childClassName={styles.ccDashboard}
      containerStyle={{ justifyContent: 'start' }}
      scrollableDashboard
    >
      <div className={`p-2 px-5 ${styles.mobileV} ${styles.scrollableDiv}`}>
        <div className={styles.container}>
          <span className={styles.mobileHeader}>Upcoming Appointments</span>
          <div className={styles.dashboardHeader}>
            <div
              className={`${styles.dashboardTab} ${activeTab === 'openCases' ? styles.activeTab : styles.inActiveTab}`}
              onClick={() => setTab('openCases')}
            >
              <div className={styles.notifications}>
                <span>{totalNewCases}</span>
              </div>
              <span>Unassigned Cases</span>
            </div>
            <div
              className={`${styles.dashboardTab} ${activeTab === 'myCases' ? styles.activeTab : styles.inActiveTab}`}
              onClick={() => setTab('myCases')}
            >
              <span>My Cases</span>
            </div>
            <div
              className={`${styles.dashboardTab} ${activeTab === 'allCases' ? styles.activeTab : styles.inActiveTab}`}
              onClick={() => setTab('allCases')}
            >
              <span>All Cases</span>
            </div>
          </div>
          {activeTab === 'openCases' && (
            <OpenCases
              acceptThisCase={acceptThisCase}
              setLoadingUntillAccept={setLoadingUntillAccept}
              cases={cases}
              reloadAll={reloadAll}
              dataLoading={dataLoading}
              loadNextPage={loadNextPage}
            />
          )}
          {activeTab === 'myCases' && <MyCases callReloadAllOpenCase={callReloadAllOpenCase} />}
          {activeTab === 'allCases' && <AllCases callReloadAllOpenCase={callReloadAllOpenCase} />}
        </div>
      </div>
      {enforceCaAssignmentModal.isOpen && (
        <EnforceAssignCAModal
          setOpenModal={setEnforceCaAssignmentModal}
          caId={enforceCaAssignmentModal.caId}
          caseId={enforceCaAssignmentModal.caseId || 0}
          setLoading={setLoadingUntillAccept}
          assignToCareAdvisor={assignToCareAdvisor}
        />
      )}
    </Container>
  );
};

export default CareConciergeDashboard;
