import qs from 'qs';

import { client } from '../client';

export type ProviderFilterAvailability = 'CaseType.video_call_instant' | 'CaseType.one_report';
export interface IListAvailableProviderParams {
  start: string;
  tz: string;
  period: number;
  limit?: number;
  carrier_id?: number;
  language?: string[];
  sort_by?: 'full_name' | 'email' | 'status' | 'type' | 'created_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  state?: string;
  synchronous?: boolean;
  asynchronous?: boolean;
  provider_id?: number;
  license_states?: string[];
  plan?: string;
  specialty?: string[];
  case_id?: number;
}

export interface DaysWithSlots {
  nodes: string[];
}

export const listAvailableProvidersByDate = async (params?: IListAvailableProviderParams): Promise<DaysWithSlots> => {
  const { data } = await client.get<DaysWithSlots>(
    `/providers/available_dates?${qs.stringify(params, { skipNulls: true, arrayFormat: 'repeat' })}`,
  );

  return data;
};
