/* eslint-disable react/require-default-props */
import { useWindowSize } from '@brands/hooks';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { ICase } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { customStylesDashboard } from '@brands/Utils/customStyles';
import { getFormatDate } from '@brands/Utils/formatDate';
import { getFriendlyNameForPatient } from '@brands/Utils/getFriendlyName';
import React, { FC } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import modalClose from '../../../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface OpenCaseListModalProps {
  openCases: ICase[];
  isOpen: string | null;
  toggleModal: (value: string | null) => void;
  startPatientForm?: (getCases?: boolean) => Promise<void>;
  startOneReport?: (getCases?: boolean) => Promise<void>;
  startSecondOpinionCase?: (getCases?: boolean) => Promise<void>;
  handleOptionClick?: (organizationId: string, option: string, getCases?: boolean) => Promise<void>;
  orgId?: string;
}

const mobileCustomStylesDashboard = {
  ...customStylesDashboard,
  overlay: {
    ...customStylesDashboard.overlay,
    overflowY: 'scroll',
  },
  content: {
    ...customStylesDashboard.content,
    transform: `translate(-50%, -40%)`,
    width: '90%',
  },
};

const OpenCaseListModal: FC<OpenCaseListModalProps> = ({
  openCases,
  isOpen,
  toggleModal,
  startPatientForm,
  startOneReport,
  startSecondOpinionCase,
  handleOptionClick,
  orgId,
}) => {
  const navigate = useNavigate();
  const isMobile = useWindowSize().width < 992;
  const { userInfo } = useAppSelector(selectAuth);
  const currentState = useAppSelector(selectCurrentPatientState);

  const closeModal = (): void => {
    toggleModal(null);
  };

  const SYNC_CASES = ['video_call_scheduled', 'video_cal_instant', 'phone_call_scheduled'];

  const continueModal = async (): Promise<void> => {
    if (startPatientForm && startOneReport && startSecondOpinionCase) {
      switch (isOpen) {
        case 'video_call_scheduled':
          await startPatientForm(false);
          break;
        case 'one_report':
          await startOneReport(false);
          break;
        case 'second_opinion':
          await startSecondOpinionCase(false);
          break;
        default:
          break;
      }
    } else if (handleOptionClick) {
      await handleOptionClick(orgId || '', isOpen || '', false);
    } else {
      console.error('No function provided to continue modal');
    }
    toggleModal(null);
  };

  const viewCase = (caseId: number): void => {
    if (userInfo.role.name === 'Patient') {
      navigate('/appointments');
    } else {
      navigate(`/admin/consultation-page/${caseId}`);
    }
    toggleModal(null);
  };

  const renderOpenCases = (cases: ICase[]): JSX.Element => {
    return (
      <div className={styles.openCasesCaseContainer}>
        {cases.map((item) => (
          <div key={item.id} className={styles.openCasesCase}>
            <div className={styles.openCasesCaseBody}>
              <button type="button" className={styles.viewButton} onClick={() => viewCase(item.id)}>
                View/Edit
              </button>{' '}
              {getFriendlyNameForPatient(item.type)} consultation {item?.provider && 'Dr.'}{' '}
              {item?.provider?.full_name?.split(' ').pop()}{' '}
              {`${SYNC_CASES.includes(item.type) ? 'Scheduled:' : 'Submitted:'} ${getFormatDate(
                SYNC_CASES.includes(item.type) ? item.video_conference_date || '' : item.created_at,
                currentState[userInfo.id],
              )}`}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      isOpen={typeof isOpen === 'string'}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <button
        type="button"
        className={styles.modalCloseBtn}
        style={{ backgroundImage: `url(${modalClose})` }}
        aria-label="Close modal"
        onClick={closeModal}
      />
      {openCases && openCases.length > 0 && (
        <div className={styles.openCasesContainer}>
          <div className={styles.openCasesTitle}>It looks like you have an active consultation:</div>
          <div className={styles.openCasesBody}>{renderOpenCases(openCases)}</div>
          <div className={styles.openCasesText}>Would you like to create a new consultation?</div>
          <div className={styles.openCasesButtons}>
            <button className={styles.openCasesButtonBack} type="button" onClick={closeModal}>
              No - Cancel
            </button>
            <button className={styles.openCasesButtonContinue} type="button" onClick={continueModal}>
              Yes - Continue
            </button>
          </div>
          <div className={styles.openCasesText} style={{ marginTop: '20px' }}>
            Need help? Give us a call at 844-240-1883 to speak with a Care Advisor.
          </div>
        </div>
      )}
    </Modal>
  );
};

Modal.setAppElement('#root');

export default OpenCaseListModal;
