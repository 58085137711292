import { UserRoleName } from '@brands/services/identity/types/UserProfile';
import { selectAuth } from '@brands/store/selectors/auth';
import { calculateCaseScheduled, calculateProviderCaseDue } from '@brands/Utils/calculateCaseDue';
import { formatTimeForApi, formatTimeForDisplay } from '@brands/Utils/formatTime';
import { getFriendlyName } from '@brands/Utils/getFriendlyName';
import moment from 'moment';
import React, { FC, useEffect } from 'react';
import Modal from 'react-modal';

import modalClose from '../../../../../../../assets/icons/close.svg';
import { useWindowSize } from '../../../../../../../hooks';
import { useAppSelector } from '../../../../../../../hooks/useReduxHook';
import { addUserAvailability } from '../../../../../../../services/availability/addUserAvailability';
import { deleteUserAvailability } from '../../../../../../../services/availability/deleteUserAvailability';
import { ProviderAvailability } from '../../../../../../../services/availability/types/ProviderAvailability';
import { updateUserAvailability } from '../../../../../../../services/availability/updateUserAvailability';
import { ICase } from '../../../../../../../services/cases/types/ICase';
import { customStylesDashboard } from '../../../../../../../Utils/customStyles';
import { addProviderUnavailability, OverrideHour } from '../SelectDateModal/SelectDate';
import styles from './styles.module.scss';

interface AlertModalProps {
  isOpen: boolean;
  toggleModal: React.Dispatch<
    React.SetStateAction<{
      cases: ICase[];
      kind: 'override' | 'weekly';
      type: 'delete' | 'update' | 'add';
      selectedDates?: Date[];
      isUnavailable?: boolean;
      slots: ProviderAvailability[];
      overrideSlots?: OverrideHour[];
    } | null>
  >;
  overlaps: {
    cases: ICase[];
    kind: 'override' | 'weekly';
    type: string;
    selectedDates?: Date[];
    isUnavailable?: boolean;
    slots: ProviderAvailability[];
    overrideSlots?: OverrideHour[];
  } | null;
  setValues: React.Dispatch<React.SetStateAction<ProviderAvailability[]>>;
}

const mobileCustomStylesDashboard = {
  ...customStylesDashboard,
  overlay: {
    ...customStylesDashboard.overlay,
    overflowY: 'scroll',
  },
  content: {
    ...customStylesDashboard.content,
    transform: `translate(-50%, -40%)`,
    width: '90%',
  },
};

const AlertModal: FC<AlertModalProps> = ({ isOpen, toggleModal, overlaps, setValues }) => {
  const isMobile = useWindowSize().width < 992;
  const { userInfo } = useAppSelector(selectAuth);
  const [overlapForAsynchronous, setOverlapForAsynchronous] = React.useState<ICase[] | null>(null);
  const [overlapsInLessThanTwoHours, setOverlapsInLessThanTwoHours] = React.useState<ICase[] | null>(null);
  const [overlapsInMoreThanTwoHours, setOverlapsInMoreThanTwoHours] = React.useState<ICase[] | null>(null);

  // const getTimeBasedOnProviderTimezone = (time: string): string => {
  //   const timezone = userInfo?.address?.timezone || '';
  //   const timezoneWithUnderscore = timezone.includes(' ') ? timezone.replace(' ', '_') : timezone;
  //   const timeInUTCFormat = moment.utc(time);
  //   const localTime = timeInUTCFormat.tz(timezoneWithUnderscore);
  //   const timeInLocalFormat = localTime.format('MM/DD/YYYY - h:mmA');
  //   return timeInLocalFormat;
  // };

  const convertTimeFormat = (time: string): string => {
    const convertedTime = formatTimeForDisplay(time);
    return convertedTime;
  };

  const closeModal = (): void => {
    toggleModal(null);
  };

  const deleteOverlaps = (): void => {
    if (!overlaps?.slots) return;

    Promise.all(
      overlaps?.slots.map(async (override) => {
        await deleteUserAvailability(userInfo.id, override.id?.toString() || '').then(() => {
          setValues((prevValues) => prevValues.filter((value) => value.id !== override.id));
        });
      }),
    );

    if (overlaps?.isUnavailable) {
      if (overlaps?.selectedDates && overlaps?.selectedDates?.length > 0) {
        Promise.all(
          overlaps.selectedDates?.map(async (date) => {
            await addProviderUnavailability(userInfo.id, date, setValues);
          }),
        );
      }
    } else if (overlaps?.overrideSlots && overlaps?.selectedDates && overlaps?.selectedDates.length > 0) {
      let resArray: ProviderAvailability[] = [];
      Promise.all(
        overlaps?.selectedDates.map(async (date) => {
          if (overlaps?.overrideSlots && overlaps?.overrideSlots.length > 0) {
            return Promise.all(
              await overlaps?.overrideSlots.map(async (hour) => {
                const res = await addUserAvailability(userInfo.id, {
                  type: 'date',
                  date: moment(date).format('YYYY-MM-DD'),
                  start_time: formatTimeForApi(hour.start),
                  end_time: hour.end === '12:00am' ? '23:59:59' : formatTimeForApi(hour.end),
                  synchronous: userInfo.role.name === UserRoleName.Provider ? hour.type.includes('synchronous') : true,
                  asynchronous: userInfo.role.name === UserRoleName.Provider ? hour.type.includes('synchronous') : true,
                });
                resArray = [...resArray, res];
              }),
            );
          }
          return [];
        }),
      ).then(() => {
        setValues((prev) => [
          ...prev,
          ...resArray.map((res) => ({
            ...res,
            start_time: convertTimeFormat(res.start_time),
            end_time: res.end_time === '12:00am' ? '23:59:59' : convertTimeFormat(res.end_time),
          })),
        ]);
      });
    }
  };

  const updateOverlaps = (): void => {
    overlaps?.slots.forEach((slot) => {
      if (slot.id) {
        updateUserAvailability(userInfo.id, slot.id, {
          start_time: formatTimeForApi(slot.start_time),
          end_time: slot.end_time === '12:00am' ? '23:59:59' : formatTimeForApi(slot.end_time),
          synchronous: slot.synchronous,
          asynchronous: slot.asynchronous,
        }).then(() => {
          setValues((prevValues) =>
            prevValues.map((value) => {
              if (value.id === slot.id) {
                return {
                  ...value,
                  start_time: slot.start_time,
                  end_time: slot.end_time === '12:00am' ? '23:59:59' : slot.end_time,
                  synchronous: userInfo.role.name === UserRoleName.Provider ? slot.synchronous : true,
                  asynchronous: userInfo.role.name === UserRoleName.Provider ? slot.asynchronous : true,
                };
              }
              return value;
            }),
          );
        });
      }
    });
  };

  const addOverlaps = (): void => {
    overlaps?.slots.forEach((slot) => {
      addUserAvailability(userInfo.id, {
        type: 'date',
        date: moment(slot.date).format('YYYY-MM-DD'),
        start_time: slot.start_time,
        end_time: slot.end_time === '12:00am' ? '23:59:59' : slot.end_time,
        synchronous: userInfo.role.name === UserRoleName.Provider ? slot.synchronous : true,
        asynchronous: userInfo.role.name === UserRoleName.Provider ? slot.asynchronous : true,
      }).then((r) => {
        setValues((prevValues) => [
          ...prevValues,
          {
            ...r,
            start_time: convertTimeFormat(r.start_time),
            end_time: r.end_time === '12:00am' ? '23:59:59' : convertTimeFormat(r.end_time),
          },
        ]);
      });
    });
  };

  const continueModal = (): void => {
    switch (overlaps?.type) {
      case 'delete':
        deleteOverlaps();
        break;
      case 'update':
        updateOverlaps();
        break;
      case 'add':
        addOverlaps();
        break;
    }

    closeModal();
  };

  useEffect(() => {
    const overlapForAsynchronousTemp: ICase[] = [];
    const overlapsInLessThanTwoHoursTemp: ICase[] = [];
    const overlapsInMoreThanTwoHoursTemp: ICase[] = [];
    overlaps?.cases.forEach((overlap) => {
      const timeInUTCFormat = moment.utc(overlap.video_conference_date);
      const localTime = timeInUTCFormat.tz(moment.tz.guess());
      const timeDifference = localTime.diff(moment(), 'minutes');
      if (overlap.video_conference_date === null) {
        overlapForAsynchronousTemp.push(overlap);
      } else if (timeDifference >= 0 && timeDifference <= 120) {
        overlapsInLessThanTwoHoursTemp.push(overlap);
      } else if (timeDifference > 120) {
        overlapsInMoreThanTwoHoursTemp.push(overlap);
      }
    });
    setOverlapForAsynchronous(overlapForAsynchronousTemp);
    setOverlapsInLessThanTwoHours(overlapsInLessThanTwoHoursTemp);
    setOverlapsInMoreThanTwoHours(overlapsInMoreThanTwoHoursTemp);
    if (
      overlapForAsynchronousTemp.length === 0 &&
      overlapsInLessThanTwoHoursTemp.length === 0 &&
      overlapsInMoreThanTwoHoursTemp.length === 0
    ) {
      continueModal();
    }
  }, [overlaps]);

  const renderOverlapItems = (ovlps: ICase[]): JSX.Element[] =>
    ovlps
      .filter((overlap, index, self) => index === self.findIndex((o) => o.id === overlap.id))
      .map((overlap) => (
        <div className={styles.alertItem} key={overlap.created_at}>
          <div className={styles.alertItemTitle}>
            {calculateCaseScheduled(overlap.type, overlap.video_conference_date || '')}: With{' '}
            {overlap.patient?.full_name}
          </div>
        </div>
      ));

  const renderOverlapItemsForAsynchronous = (ovlps: ICase[]): JSX.Element[] =>
    ovlps
      .filter((overlap, index, self) => index === self.findIndex((o) => o.id === overlap.id))
      .map((overlap) => (
        <div className={styles.alertItem} key={overlap.created_at}>
          <div className={styles.alertItemTitle}>
            {getFriendlyName(overlap.type)} for {overlap.patient?.full_name} - Due on{' '}
            {overlap.video_conference_date
              ? calculateCaseScheduled(overlap.type, overlap.video_conference_date || '')
              : calculateProviderCaseDue(overlap.type, overlap.started_at, overlap.video_conference_date || '')}
          </div>
        </div>
      ));
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={isMobile ? mobileCustomStylesDashboard : customStylesDashboard}
      contentLabel="Modal"
      ariaHideApp={false}
    >
      <button
        type="button"
        className={styles.modalCloseBtn}
        style={{ backgroundImage: `url(${modalClose})` }}
        aria-label="Close modal"
        onClick={closeModal}
      />
      <div className={styles.profileCard}>
        {overlapForAsynchronous && overlapForAsynchronous.length > 0 && (
          <>
            <div className={styles.alertTitle}>
              You are not able to edit your availability as it will impact the below case(s), that are due during this
              time. If this is an emergency and you still need to remove your availability, please message your care
              advisor for the case(s) to let them know.
            </div>
            <div className={styles.alertBody}>{renderOverlapItemsForAsynchronous(overlapForAsynchronous)}</div>
          </>
        )}

        {overlapsInLessThanTwoHours &&
          overlapsInLessThanTwoHours.length > 0 &&
          !(overlapForAsynchronous && overlapForAsynchronous.length > 0) && (
            <>
              <div className={styles.alertTitle}>
                You are not able to edit your availability for this time as it will impact the below appointment(s),
                which is scheduled in less than two hours. If this is an emergency and you still need to remove your
                availability, please message your care advisor for the appointment(s) to let them know.
              </div>
              <div className={styles.alertBody}>{renderOverlapItems(overlapsInLessThanTwoHours)}</div>
            </>
          )}

        {overlapsInMoreThanTwoHours &&
          overlapsInMoreThanTwoHours.length > 0 &&
          !(overlapsInLessThanTwoHours && overlapsInLessThanTwoHours.length > 0) &&
          !(overlapForAsynchronous && overlapForAsynchronous.length > 0) && (
            <>
              <div className={styles.alertTitle}>
                It looks like you have the below appointment(s) scheduled at the time you are trying to update. If you
                still need to update your time, our Care Advisor team will work with the patient to reschedule this
                time.
                {overlapsInLessThanTwoHours?.length === 0
                  ? 'Do you still want to continue?'
                  : 'please message your care advisor for the appointment(s) to let them know'}
              </div>
              <div className={styles.alertBody}>{renderOverlapItems(overlapsInMoreThanTwoHours)}</div>
              <div className={styles.alertButtons}>
                {overlapsInLessThanTwoHours?.length === 0 && (
                  <>
                    <button className={styles.alertButtonBack} type="button" onClick={closeModal}>
                      Back
                    </button>
                    <button className={styles.alertButtonContinue} type="button" onClick={continueModal}>
                      Continue
                    </button>
                  </>
                )}
              </div>
            </>
          )}
      </div>
    </Modal>
  );
};

Modal.setAppElement('#root');

export default AlertModal;
