/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

import { SelectedAppointment } from '../../types';
import styles from './styles.module.scss';

export interface TimeSlot {
  start_time: string;
  end_time: string;
  asynchronous: boolean;
  synchronous: boolean;
  nextAvailable?: boolean;
}

const TimeSlotComponent: React.FC<{
  id: number;
  timeSlots: TimeSlot[];
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedAppointment>>;
  selectedTimeSlot: SelectedAppointment;
  date: string;
  refreshProviders: () => void;
}> = ({ id, timeSlots, selectedTimeSlot, setSelectedTimeSlot, date, refreshProviders }) => {
  const getCurrentTimezone = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone;
  }, []);

  const createTimeSlotDate = (timeSlot: string, timeZone: string): Date => {
    const [hoursStr, minutesStr, period] = timeSlot.split(/:| /);
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    // Use moment with the provided time zone to get the current date
    const currentDate = moment().tz(timeZone);

    // Create a new moment object with the given time, using the current date from the specified time zone
    const timeSlotDate = currentDate.clone().set({ hours, minutes });

    // Adjust hours if it's PM and not 12 PM
    if (period.toLowerCase() === 'pm' && hours !== 12) {
      timeSlotDate.add(12, 'hours');
    }

    // Convert the moment object back to a Date object
    return timeSlotDate.toDate();
  };

  const isCurrentDate = (dateString: string): boolean => {
    const currentDate = new Date();
    const givenDate = new Date(dateString);

    return (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );
  };

  const handleTimeSlotClick = (slot: TimeSlot): void => {
    // Add the current date to the startTime to make sure it's a complete date-time format
    // Get the current time in the specified time zone
    const currentTime = moment(); // Get the current time
    // Create a moment object from the startTime directly
    const startTimeMoment = moment(createTimeSlotDate(slot.start_time, getCurrentTimezone));
    // Check if the current time is at least 30 minutes before the startTime
    if ((startTimeMoment.diff(currentTime, 'minutes') >= 30 && isCurrentDate(date)) || !isCurrentDate(date)) {
      setSelectedTimeSlot({
        selectedTimeSlot: slot.start_time,
        selectedProviderId: id,
      });
    } else {
      toast.error('The time slot you selected is no longer available. Please select a different time slot.');
      refreshProviders();
      setSelectedTimeSlot({
        selectedTimeSlot: '',
        selectedProviderId: undefined,
      });
    }
  };

  return (
    <div className={styles.timeSlotContainer}>
      {timeSlots.map((slot, index) => (
        <div
          key={index}
          className={`${styles.timeSlotDiv} ${
            slot.start_time === selectedTimeSlot.selectedTimeSlot && id === selectedTimeSlot.selectedProviderId
              ? styles.selected
              : ''
          } ${slot.nextAvailable && styles.nextAvailableSlot}`}
          onClick={() => handleTimeSlotClick(slot)}
        >
          {slot.nextAvailable && 'First Available'}
          {slot.nextAvailable && <span className={styles.separator}> | </span>}
          {slot.start_time}
        </div>
      ))}
    </div>
  );
};

export default TimeSlotComponent;
