/* eslint-disable no-nested-ternary */
import { UserRoleName } from '@brands/services/identity/types/UserProfile';

import { ICase } from '../services/cases/types/ICase';
import { calculateCaseDueInMinutes } from './calculateCaseDue';
import { getFriendlyName, getFriendlyNameForPatient } from './getFriendlyName';
import { getTextForStatus } from './getTextForStatus';

export type SortKeys = keyof ICase;
export type SortOrder = 'ascn' | 'desc';
export type Comparator<T> = (a: T, b: T) => number;

export const sortedDataFn = (allCases: ICase[], sortKey: SortKeys): ICase[] => {
  return allCases?.sort((a, b) => {
    return a[sortKey]! > b[sortKey]! ? 1 : -1;
  });
};

export const sortData = <T extends Record<string, any>>({
  tableData,
  sortKey,
  reverse,
  userRole,
}: {
  tableData: T[];
  sortKey: SortKeys;
  reverse: boolean;
  userRole: string;
}): T[] => {
  const sortedData = [...tableData].sort((a, b) => {
    let aValue;
    let bValue;
    switch (sortKey) {
      case 'id':
        aValue = a.id || '';
        bValue = b.id || '';
        break;
      case 'type':
        aValue =
          userRole === UserRoleName.Patient
            ? getFriendlyNameForPatient(a.type)
            : userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor
            ? getFriendlyName(a.type)
            : '';
        bValue =
          userRole === UserRoleName.Patient
            ? getFriendlyNameForPatient(b.type)
            : userRole === UserRoleName.Provider || userRole === UserRoleName.CareAdvisor
            ? getFriendlyName(b.type)
            : '';
        break;
      case 'patient':
        aValue = a.patient?.full_name?.toLowerCase() || '';
        bValue = b.patient?.full_name?.toLowerCase() || '';
        break;
      case 'care_advisor':
        aValue = a.care_advisor?.full_name?.toLowerCase() || '';
        bValue = b.care_advisor?.full_name?.toLowerCase() || '';
        break;
      case 'provider':
        aValue = a.provider?.full_name?.toLowerCase() || '';
        bValue = b.provider?.full_name?.toLowerCase() || '';
        break;
      case 'brandAssoc':
        [aValue] = a.organization.name;
        [bValue] = b.organization.name;
        break;
      case 'markers':
        [aValue] = a.organization.name;
        [bValue] = b.organization.name;
        break;
      case 'status':
        aValue = getTextForStatus(userRole, a.type, a.status);
        bValue = getTextForStatus(userRole, b.type, b.status);
        break;
      case 'created_at':
        aValue = new Date(a.created_at).getTime();
        bValue = new Date(b.created_at).getTime();
        break;
      case 'video_conference_date':
        aValue = new Date(a.video_conference_date).getTime() || '';
        bValue = new Date(b.video_conference_date).getTime() || '';
        break;
      case 'completed_at':
        aValue =
          (a.status === 'completed' || a.status === 'canceled'
            ? new Date(a.completed_at).getTime() || new Date(a.updated_at).getTime()
            : '') || '';
        bValue =
          (b.status === 'completed' || b.status === 'canceled'
            ? new Date(b.completed_at).getTime() || new Date(b.updated_at).getTime()
            : '') || '';
        break;
      case 'due_date':
        aValue = calculateCaseDueInMinutes(a.type, a.created_at, a.video_conference_date || '');
        bValue = calculateCaseDueInMinutes(b.type, b.created_at, b.video_conference_date || '');
        break;
      default:
        aValue = '';
        bValue = '';
    }
    if (sortKey === 'due_date') {
      if (a.type === 'video_call_instant' && b.type !== 'video_call_instant') {
        return reverse ? 1 : -1;
      }
      if (a.type !== 'video_call_instant' && b.type === 'video_call_instant') {
        return reverse ? -1 : 1;
      }
      if (a.type === 'video_call_instant' && b.type === 'video_call_instant') {
        // If both cases are video_call_instant type, sort by waiting time in reverse order
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
      }
    }
    if (aValue < bValue) {
      return reverse ? 1 : -1;
    }
    if (aValue > bValue) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
  return sortedData;
};
