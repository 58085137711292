import moment from 'moment-timezone';

import { convertZoneAbbr, getTimezoneAbbreviation } from './getParsedTimezoneName';

export const formatDate = (dateString: string, isTzSeen = false): string => {
  const date = moment.utc(dateString);
  const timezone = moment.tz.guess();

  const dateWithTimezone = date.tz(timezone);
  const formattedDate = dateWithTimezone.format('MM/DD/YYYY hh:mm A (z)');
  const formattedDateWithoutTimeZone = dateWithTimezone.format('MM/DD/YYYY hh:mm A');
  return isTzSeen ? formattedDate : formattedDateWithoutTimeZone;
};

export const formatDateToDate = (dateString: string, timeZone: string): Date => {
  const localDate = moment.tz(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)', timeZone).toDate();
  return localDate;
};

export const formatDateAppointmentPage = (dateString: string): string => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const formattedDate = moment.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', timeZone).format('dddd, MMMM DD, YYYY');
  return formattedDate;
};

export const formatTimeAppointmentPage = (dateString: string): string => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const formattedTime = moment.tz(dateString, 'YYYY-MM-DDTHH:mm:ss', timeZone).format('h:mm A z');
  return formattedTime;
};

export const getCaseDate = (appointmentDate: string, patientState: string): string => {
  const currentTimezone = getTimezoneAbbreviation(patientState);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const date = moment.utc(appointmentDate)?.tz(timezoneWithUnderscore);

  if (date) {
    return date.format('dddd, MMMM D, YYYY');
  }

  return '';
};

export const getCaseTime = (appointmentDate: string, patientState: string): string => {
  const currentTimezone = getTimezoneAbbreviation(patientState);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const date = moment.utc(appointmentDate).tz(timezoneWithUnderscore);

  if (date) {
    return date.format('h:mm A z');
  }

  return '';
};

export const getFormatDate = (dateString: string, patientState: string): string => {
  const currentTimezone = getTimezoneAbbreviation(patientState);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const date = moment.utc(dateString).tz(timezoneWithUnderscore);

  if (date) {
    return date.format('MM/DD/YYYY hh:mm A');
  }

  return '';
};

export const getFormattedDateSmileScan = (dateString: string, patientState: string): string => {
  const currentTimezone = getTimezoneAbbreviation(patientState);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const date = moment.utc(dateString).tz(timezoneWithUnderscore);

  if (date.isValid()) {
    return date.format('dddd, MMMM D, YYYY');
  }

  return '';
};

export const getFormattedTimeSmileScan = (dateString: string, patientState: string): string => {
  const currentTimezone = getTimezoneAbbreviation(patientState);
  const timezoneWithUnderscore = convertZoneAbbr(currentTimezone as string);
  const date = moment.utc(dateString).tz(timezoneWithUnderscore);

  if (date.isValid()) {
    return date.format('h:mm A z');
  }

  return '';
};

export const formatEmail = (email: string): string => {
  const atIndex = email.lastIndexOf('@');
  if (atIndex === -1 || email.length <= 5) {
    throw new Error('Invalid email format.');
  }
  const maskedLocalPart = 'X'.repeat(atIndex);
  const domain = email.slice(atIndex + 1);
  const maskedFormat = `${maskedLocalPart}@${domain}`;
  return maskedFormat;
};
