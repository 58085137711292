import axios from 'axios';
import axiosRetry from 'axios-retry';

import { config } from '../config/config';
import cleanPayload from '../Utils/cleanPayload';
import { jwtToken } from '../Utils/getToken';

export const client = axios.create({
  baseURL: config.api.baseUrl,
  headers: {
    'x-organization-id': config.organization_id,
    'x-practice-id': config.practice_id,
    'x-division-id': config.division_id,
  },
});

client.interceptors.request.use(
  (request) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${jwtToken()}`;
    }
    if (request.data) {
      request.data = cleanPayload(request.data);
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
      console.error('Network error, retrying request...');
      axiosRetry(client, { retries: 10, retryDelay: () => 500 });
      return Promise.reject(error);
    }
    console.error('Error:', error);
    if (error.response.status === 502 || error.response.status === 422) {
      console.error('Error 502 or 422, retrying request...');
      axiosRetry(client, { retries: 3, retryDelay: () => 500 });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
