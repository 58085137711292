/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-continue */
/* eslint-disable max-len */
import { convertDateFormat } from '@brands/Dashboard/MyProfile/Patient/utils/utilsFunctions';
import { rescheduleCase, RescheduleCasePayload } from '@brands/services/cases/rescheduleCase';
import { ICase } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { selectPatient } from '@brands/store/selectors/selectedPatient';
import { FormValues, setForm } from '@brands/store/slices/patientFormSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../../../Components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useReduxHook';
import { getUserAvatar } from '../../../../../services/identity/getUserAvatar';
import { InsuranceCarrier } from '../../../../../services/identity/listInsuranceCarriers';
import { Language, UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import ProviderProfileCardModal from '../../../../ProfileCards/Dentist/ProfileCards';
import { SelectedAppointment } from '../types';
import TimeSlotComponent, { TimeSlot } from './Components/TimeSlotComponent';
import styles from './styles.module.scss';

interface ProviderCard {
  index: number;
  provider: UserProfile;
  date: string;
  timeSlots: TimeSlot[];
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedAppointment>>;
  selectedTimeSlot: SelectedAppointment;
  selectedCarrierId: number;
  customInsurance: string;
  insuranceCarriers: InsuranceCarrier[];
  patientCurrentState: string;
  refreshProviders: () => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRescheduleSuccessModal: React.Dispatch<
    React.SetStateAction<{
      openModal: boolean;
      updatedCase: ICase | null;
    }>
  >;
}

const ProviderAvailabilityCard: React.FC<ProviderCard> = ({
  index,
  provider,
  date,
  timeSlots,
  selectedTimeSlot,
  setSelectedTimeSlot,
  selectedCarrierId,
  customInsurance,
  insuranceCarriers,
  patientCurrentState,
  refreshProviders,
  loading,
  setLoading,
  setRescheduleSuccessModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const RescheduleCase = state?.RescheduleCase ? state.RescheduleCase : searchParams.get('reschedule') || null;
  const caseId = state?.caseId ? state.caseId : searchParams.get('caseId') || null;
  const providerId =
    state?.providerId || state?.providerId == 0 ? state.providerId : searchParams.get('providerId') || null;

  const { selectedPatient } = useAppSelector(selectPatient);
  const { userInfo } = useAppSelector(selectAuth);
  const { values } = useAppSelector(selectPatientForm);
  const [formValues, setFormValues] = React.useState<FormValues[]>([]);
  const [providerInsuranceCarriers, setProviderInsuranceCarriers] = useState<string[]>(['']);
  const [providerProfileCardModal, setProviderProfileCardModal] = useState(false);
  const currentState: string = useAppSelector(selectCurrentPatientState);

  const checkInNetwork = useCallback((): boolean => {
    const selectedCarrierName = insuranceCarriers.find((carrier) => carrier.id === selectedCarrierId)?.name;
    if (selectedCarrierName) return providerInsuranceCarriers.includes(selectedCarrierName);
    return false;
  }, [insuranceCarriers, providerInsuranceCarriers, selectedCarrierId]);

  const savePatientScheduledDate = async (): Promise<void> => {
    const payload = [...formValues!];
    const filteredPayload = payload.filter((item) => item.form_question_id !== 14);
    filteredPayload.push({
      form_question_id: 14,
      value: {
        selectedTimeSlot: `${date} ${selectedTimeSlot.selectedTimeSlot}`,
        selectedProviderId: index,
        selectedCarrier: selectedCarrierId,
        customInsurance,
      },
    });
    dispatch(setForm(filteredPayload));
    if (RescheduleCase && caseId && (providerId || providerId == 0)) {
      let updatedCase;
      try {
        setLoading(true);

        const payload: RescheduleCasePayload = {
          video_conference_date: convertDateFormat(`${date} ${selectedTimeSlot.selectedTimeSlot}`) as string,
          provider_id: index,
        };
        updatedCase = await rescheduleCase(caseId, payload);

        setRescheduleSuccessModal({
          openModal: true,
          updatedCase: updatedCase as ICase,
        });
        setLoading(false);
      } catch (error: unknown) {
        console.error(error);
      }
    } else {
      navigate('/select-needs', {
        state: {
          caseType: 'video_call_scheduled',
          userOrganization: state?.userOrganization,
          patientId: selectedPatient?.value,
        },
      });
    }
  };

  useEffect(() => {
    if (provider?.provider_profile?.licenses) {
      const labels = provider.provider_profile.licenses
        .slice()
        .find(
          (license) =>
            license.state ===
            (currentState[
              userInfo.role.name === UserRoleName.Admin ||
              userInfo.role.name === UserRoleName.SuperAdmin ||
              userInfo.role.name === UserRoleName.OrganizationAdmin
                ? Number(selectedPatient?.value)
                : userInfo.id
            ] ||
              selectedPatient?.address?.state ||
              ''),
        )
        ?.insurance_carriers.map((insurance_carrier) => insurance_carrier.insurance_carrier?.name)
        .filter((label): label is string => label !== undefined);

      setProviderInsuranceCarriers(labels ?? ['']);
    }
  }, [insuranceCarriers, provider.provider_profile.licenses]);

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, []);

  return (
    <div
      className={`${styles.card} ${
        selectedTimeSlot.selectedTimeSlot !== '' && selectedTimeSlot.selectedProviderId === index
          ? styles.selectedBg
          : styles.whiteBg
      }`}
    >
      <div className={styles.providerInfo}>
        <div
          className={styles.providerPhoto}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setProviderProfileCardModal(true);
          }}
        >
          <img
            alt="Avatar"
            onError={(event: any) => {
              const img = event.target;
              img.src =
                'https://media.istockphoto.com/id/1130884625/tr/vekt%C3%B6r/kullan%C4%B1c%C4%B1-%C3%BCyesi-vekt%C3%B6r-simgesi-ui-kullan%C4%B1c%C4%B1-arabirimi-veya-profil-face-avatar-uygulamas%C4%B1.jpg?s=612x612&w=0&k=20&c=jAf5nq1ebnZo8TJbjaB9dMHMvgff7uOk67NkF5CpgB0=';
              img.onError = null;
            }}
            className={styles.caImage}
            src={getUserAvatar(provider.id)}
          />
        </div>
        <div className={styles.providerDetails}>
          <span
            style={{ cursor: 'pointer' }}
            className={styles.name}
            onClick={() => {
              setProviderProfileCardModal(true);
            }}
          >
            {provider?.full_name}
            {provider.provider_profile?.credentials ? ', ' : ''}
            {provider.provider_profile?.credentials || ''}
            <AiFillInfoCircle />
          </span>
          <span className={styles.language}>
            <span className={styles.title}>Languages</span> -{' '}
            <span>
              {provider.basic_info.languages &&
                provider.basic_info.languages
                  .map((language: Language | string) => {
                    if (typeof language === 'string') {
                      return language;
                    }
                    return language.name;
                  })
                  .join(', ')}
            </span>
          </span>
          {selectedCarrierId?.toString() !== '' && (
            <span className={styles.network}>
              <span className={styles.title}>
                {checkInNetwork() ? (
                  <FaCircle className={styles.inNetwork} />
                ) : (
                  <FaCircle className={styles.outNetwork} />
                )}
                {checkInNetwork() ? 'In Network' : 'Out of Network'}
              </span>{' '}
              <span>
                {checkInNetwork() && provider?.provider_profile?.licenses && (
                  <>
                    {' - '}
                    {provider.provider_profile.licenses
                      .find(
                        (license) =>
                          license.state ===
                          (currentState[
                            userInfo.role.name === UserRoleName.Admin ||
                            userInfo.role.name === UserRoleName.SuperAdmin ||
                            userInfo.role.name === UserRoleName.OrganizationAdmin
                              ? Number(selectedPatient?.value)
                              : userInfo.id
                          ] ||
                            selectedPatient?.address?.state ||
                            ''),
                      )
                      ?.insurance_carriers.map((insurance_carrier) => insurance_carrier.insurance_carrier?.name)
                      .join(', ') || 'No insurance carriers available'}
                  </>
                )}
              </span>
            </span>
          )}
        </div>
      </div>
      <div>
        <TimeSlotComponent
          id={index}
          timeSlots={timeSlots}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          date={date}
          refreshProviders={refreshProviders}
        />
      </div>
      {selectedTimeSlot.selectedTimeSlot !== '' && selectedTimeSlot.selectedProviderId === index && (
        <div className={styles.cardFooter}>
          <span className={styles.timeSlotDetails}>
            <span>{date}</span>
            <span>{selectedTimeSlot.selectedTimeSlot}</span>
          </span>
          <span>
            <Button type="button" onClick={() => savePatientScheduledDate()} disabled={loading}>
              Schedule Now
            </Button>
          </span>
        </div>
      )}
      {providerProfileCardModal && (
        <ProviderProfileCardModal
          isOpen={providerProfileCardModal}
          toggleModal={setProviderProfileCardModal}
          providerIdentity={provider}
          patientCurrentStateValue={patientCurrentState}
        />
      )}
    </div>
  );
};

export default ProviderAvailabilityCard;
