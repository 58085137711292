/* eslint-disable import/no-cycle */
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import authReducer from './slices/authSlice';
import careAdvisorMyProfile from './slices/careAdvisorMyProfile';
import currentPatientState from './slices/currentPatientState';
import dependents from './slices/dependentsSlice';
import formAnswers from './slices/formAnswersSlice';
import formQuestions from './slices/formQuestionsSlice';
import isFromHandOff from './slices/isFromHandOffSlice';
import location from './slices/location';
import modal from './slices/modalSlice';
import oneReport from './slices/oneReportSlice';
import organizationPrices from './slices/organizationPricingSlice';
import organization from './slices/organizationSlice';
import pageState from './slices/pageStateSlice';
import patientForm from './slices/patientFormSlice';
import patientMyProfile from './slices/patientMyProfileSlice';
import providerMyProfile from './slices/providerMyProfileSlice';
import selectedPatient from './slices/selectedPatientSlice';
import smileScanCase from './slices/smileScanCaseSlice';
import videoCallCredentials from './slices/videoCallCredentialsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  selectedPatient,
  pageState,
  patientForm,
  formQuestions,
  formAnswers,
  patientMyProfile,
  providerMyProfile,
  careAdvisorMyProfile,
  oneReport,
  organization,
  organizationPrices,
  location,
  modal,
  videoCallCredentials,
  dependents,
  currentPatientState,
  smileScanCase,
  isFromHandOff,
});

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  thunk,
];

const persistConfig = {
  key: 'root',
  storage,
};

const preloadedState: any = {};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({});

const store = createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
