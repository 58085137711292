import qs from 'qs';

import { client } from '../client';
import { Meta } from '../communication/types/Meta';
import { ICase } from './types/ICase';

export interface IListCases {
  nodes: ICase[];
  total: number;
  meta: Meta;
}

export interface IListCasesParams {
  type?: string[];
  status?: string | string[];
  limit?: number;
  sort_by?: 'created_at' | 'updated_at';
  sort_direction?: 'asc' | 'desc';
  cursor?: string;
  cursor_direction?: 'next' | 'previous';
  care_advisor_id?: number;
  provider_id?: number;
  patient_id?: number;
  dependent_id?: number;
  include_patient_care_advisor_conversation?: boolean;
  include_patient_provider_conversation?: boolean;
  include_provider_care_advisor_conversation?: boolean;
  include_vc_stats?: boolean;
  include_dependents_cases?: boolean;
}

export const listAllCases = async (params?: IListCasesParams | string): Promise<IListCases> => {
  let queryString = '';
  if (params && typeof params === 'object' && params instanceof Object) {
    queryString = `?${qs.stringify(params, { skipNulls: true })}`;
  } else if (params && typeof params === 'string') {
    queryString = `?${params}`;
  }
  const { data } = await client.get(`/cases${queryString}`);
  return data;
};
